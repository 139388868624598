import CenterModal from "../CenterModal";
import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import Button from "../../theme/Button";
import theme from "../../theme";
import loadingIcon from "/loading.svg";

/**
 * This modal is used to confirm that the user wants to proceed with an action.
 * It contains two buttons: the primary button usually completes action
 *  while the seconday button cancels the action.
 * Example of use: confirmation of deleting an item or sending a role
 */

interface ConfirmActionModalProps {
  open: boolean;
  primaryButtonHandler: () => void;
  secondaryButtonHandler: () => void;
  primaryButtonBgColor: string;
  primaryButtonHoverBgColor: string;
  heading: string;
  subHeading: string;
  primaryButtonText: string;
  secondaryButtonText: string;
  onClose?: () => void;
  isLoading?: boolean;
  infoElement?: JSX.Element;
}

export default function ConfirmActionModal({
  open,
  primaryButtonHandler,
  secondaryButtonHandler,
  primaryButtonBgColor,
  primaryButtonHoverBgColor,
  heading,
  subHeading,
  primaryButtonText,
  secondaryButtonText,
  onClose,
  isLoading,
  infoElement,
}: ConfirmActionModalProps) {
  return (
    <CenterModal open={open} onClose={onClose}>
      <Stack
        spacing={2}
        direction="column"
        sx={{
          width: "29rem",
          padding: theme.spacing(3),
          backgroundColor: theme.palette.background.paper,
          borderRadius: theme.spacing(2),
        }}
      >
        <Typography
          fontSize="1.1rem"
          fontWeight={600}
          lineHeight="1.5rem"
          color={theme.palette.grey[800]}
        >
          {heading}
        </Typography>
        {subHeading && (
          <Typography
            fontSize="0.875rem"
            fontWeight={400}
            lineHeight="1.25rem"
            color={theme.palette.grey[500]}
            sx={{ whiteSpace: "pre-line" }}
          >
            {subHeading}
          </Typography>
        )}
        {isLoading ? (
          <Stack sx={{
            width: "100%",
            alignItems: "center"
          }}>
            <CircularProgress
              size={35}
              thickness={6}
              sx={{
                color: theme.palette.primary.dark,
                borderRadius: "50%",
                boxShadow: `inset 0 0 0 5.5px ${theme.palette.grey[100]}`,
                "& .MuiCircularProgress-circle": {
                  strokeLinecap: "round",
                },
              }}
            />
          </Stack>
        ) : (
          infoElement
        )}
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          spacing={1}
        >
          <Grid item>
            <Button
              buttonProps={{
                id: "confirm-action-secondary-button",
                onClick: secondaryButtonHandler,
                disabled: isLoading,
                disableRipple: true,
                sx: {
                  height: "2.5rem",
                  fontSize: "0.875rem",
                  boxShadow: "none",
                  background: theme.palette.background.default,
                  color: theme.palette.grey[600],
                  borderRadius: "0.5rem",
                  border: 1,
                  borderColor: theme.palette.grey[300],
                  "&:hover": {
                    backgroundColor: theme.palette.background.default,
                    boxShadow: "none",
                  },
                  "&:active": {
                    backgroundColor: theme.palette.background.default,
                    boxShadow: "none",
                  },
                  "&:disabled": {
                    borderColor: theme.palette.grey[200],
                    backgroundColor: theme.palette.background.default,
                    boxShadow: "none",
                  },
                },
              }}
            >
              {secondaryButtonText}
            </Button>
          </Grid>
          <Grid item>
            <Button
              buttonProps={{
                id: "confirm-action-primary-buttton",
                onClick: primaryButtonHandler,
                disabled: isLoading,
                disableRipple: true,
                sx: {
                  height: "2.5rem",
                  fontSize: "0.875rem",
                  boxShadow: "none",
                  backgroundColor: primaryButtonBgColor,
                  "&:hover": {
                    backgroundColor: primaryButtonHoverBgColor,
                    boxShadow: "none",
                  },
                  "&:active": {
                    backgroundColor: primaryButtonHoverBgColor,
                    boxShadow: "none",
                  },
                },
              }}
            >
              {isLoading && (
                <Box
                  component="img"
                  sx={{
                    width: "1rem",
                    marginRight: "0.5rem",
                  }}
                  src={loadingIcon}
                />
              )}
              {primaryButtonText}
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </CenterModal>
  );
}
