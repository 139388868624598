import { Typography, Box, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Translation } from "../../lib/constants";
import ThumbsUp from "/thumbs-up.svg";
import ThumbsDown from "/thumbs-down.svg";

const ApprovalCard = ({ type, name }: { type: "approved" | "rejected", name: string }) => {
  const { t } = useTranslation(Translation.common);
  const isApproved = type === "approved";

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      gap="20px"
      sx={{
        width: "1000px",
        padding: "32px",
        borderRadius: "20px",
        border: "1px solid var(--Gray-200, #EAECF0)",
        background: "#FFF",
      }}
    >
      <Stack
        padding="16px"
        justifyContent="center"
        alignItems="center"
        sx={(theme) => ({
          borderRadius: "50%",
          backgroundColor: isApproved
            ? theme.palette.custom.successBackground
            : theme.palette.grey[50]
        })}
      >
        <Box
          component="img"
          alt={t(isApproved ? "approveIconAlt" : "rejectIconAlt")}
          src={isApproved ? ThumbsUp : ThumbsDown}
          width="28px"
          height="28px"
        />
      </Stack>

      <Typography
        variant="h6"
        component="div"
        gutterBottom
        sx={{ fontWeight: "bold" }}
      >
        {isApproved
          ? `${t("matchApproval.approved")} ${name}!`
          : `${t("matchApproval.rejected")} ${name}!`}
      </Typography>

      <Typography variant="body2" color="text.secondary">
        {t("matchApproval.submitted")}
      </Typography>
    </Stack>
  );
};

export default ApprovalCard;
