import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { Divider, RadioGroup, Typography } from "@mui/material";
import HelpCircleIcon from "/help-circle-secondary.svg";
import Tooltip from "../../theme/Tooltip";
import Checkbox, { CheckedState } from "../Checkbox";
import theme from "../../theme";
import {
  CriteriaRuleHeader,
  AnnotatedInput,
  RadioOption,
  CheckboxOption,
  CriteriaInput,
  CriteriaMultiChipInput,
  SelectOption,
} from "../UtilComponents.tsx";
import {
  Format,
  isInteger,
  isNumeric,
  NO_DEPARTMENT_OPTION,
  trackGoogleAnalyticsEvent,
} from "../../lib/utils";
import {
  DepartmentDTO,
  RoleMatchingCriteriaDTO,
  SalesPartnershipsCriteriaDTO,
  TenureMatchingCriteria,
} from "../../models/api/criteria";
import { useTranslation } from "react-i18next";
import { Translation } from "../../lib/constants";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { validateEmail } from "../../lib/utils";
import { FailureEvent } from "../../lib/eventEnums.ts";
import { listJobNamesByAtsDepartment } from "../../api/job.ts";
import SelectInput from "../UtilComponents.tsx";
import MultiSelectComboBox, {
  MultiSelectOption,
} from "../MultiSelectComboBox/index.tsx";
import { UserConfigContext, UserContext } from "../../lib/context.ts";

export const TimeSinceDeparture = ({
  timeSince,
  setTimeSince,
  setInputError,
  defaultValue,
}: {
  timeSince: number;
  setTimeSince: (arg: number) => void;
  setInputError: (arg: boolean) => void;
  defaultValue: number;
}) => {
  const { t } = useTranslation(Translation.settings);
  // we are gonna assume timeSince value is number of days since departure
  const [years, setYears] = useState<string>("");
  const [months, setMonths] = useState<string>("");
  const [error, setError] = useState({ months: false, years: false });

  useEffect(() => {
    setInputError(error.months || error.years);
  }, [error]);

  useEffect(() => {
    setYears(Math.floor(timeSince / 360).toString());
    setMonths(Math.floor((timeSince % 360) / 30).toString());
  }, [timeSince]);

  useEffect(() => {
    const monthsValid = isInteger(months);
    const yearsValid = isInteger(years);
    setError({ months: !monthsValid, years: !yearsValid });
    if (monthsValid && yearsValid) {
      const newVal = parseInt(years) * 360 + parseInt(months) * 30;
      if (newVal != roundTimeSince(timeSince)) {
        setTimeSince(newVal);
      }
    }
  }, [years, months]);

  const roundTimeSince = (timeSince: number) => {
    return timeSince - (timeSince % 30);
  };

  const handleYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setYears(event.target.value);
  };

  const handleMonthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMonths(event.target.value);
  };

  const handleReset = () => {
    setTimeSince(defaultValue);
  };

  return (
    <Stack
      id="time-since-departure"
      direction="column"
      style={{
        borderRadius: "12px",
        border: "1px solid var(--Gray-200, #EAECF0)",
        width: "100%",
        padding: "24px",
        display: "flex",
        gap: "32px",
      }}
    >
      <CriteriaRuleHeader
        headerText={t("editCriteria.fields.time_since_departure.heading")}
        supportingText={t(
          "editCriteria.fields.time_since_departure.subHeading",
        )}
        allowReset={defaultValue !== roundTimeSince(timeSince)}
        onReset={handleReset}
      />

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <Typography
            fontSize="14px"
            fontWeight={500}
            color={theme.palette.grey[800]}
            lineHeight="20px"
          >
            {t("editCriteria.fields.time_since_departure.label")}
          </Typography>
          <Tooltip
            arrow={false}
            title={t("editCriteria.fields.time_since_departure.tooltip")}
            componentsProps={{
              tooltip: {
                sx: {
                  padding: "0.75rem",
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                  borderRadius: "0.5rem",
                  bgcolor: "white",
                  color: "grey.500",
                  fontSize: "0.75rem",
                  fontWeight: "500",
                  lineHeight: "1.125rem",
                  boxShadow: "0px 0.5rem 0.5rem #00000034",
                },
              },
            }}
          >
            <Box component="img" src={HelpCircleIcon} marginLeft="4px" />
          </Tooltip>
        </Stack>

        <Stack direction="row" gap="8px">
          <AnnotatedInput
            id="time-since-departure-years-input"
            annotation={t(
              "editCriteria.fields.time_since_departure.inputLabel.years",
            )}
            value={years}
            error={error.years}
            onChange={handleYearChange}
          />
          <AnnotatedInput
            id="time-since-departure-months-input"
            annotation={t(
              "editCriteria.fields.time_since_departure.inputLabel.months",
            )}
            value={months}
            error={error.months}
            onChange={handleMonthChange}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export const ProximityToRole = ({
  proximity,
  setProximity,
  setInputError,
  defaultValue,
}: {
  proximity: number;
  setProximity: (arg: number) => void;
  setInputError: (arg: boolean) => void;
  defaultValue: number;
}) => {
  const { t } = useTranslation(Translation.settings);
  const [strProximity, setStrProximity] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    setInputError(error);
  }, [error]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let val = event.target.value;
    setStrProximity(val);
    if (!isNumeric(val)) {
      setError(true);
      return;
    }
    setError(false);
    setProximity(parseFloat(val));
  };

  useEffect(() => {
    setStrProximity(proximity.toString());
  }, [proximity]);

  const handleReset = () => {
    setStrProximity(defaultValue.toString());
    setProximity(defaultValue);
  };

  return (
    <Stack
      direction="column"
      style={{
        borderRadius: "12px",
        border: "1px solid var(--Gray-200, #EAECF0)",
        width: "100%",
        padding: "24px",
        display: "flex",
        gap: "32px",
      }}
    >
      <CriteriaRuleHeader
        headerText={t("editCriteria.fields.proximity_to_role.heading")}
        supportingText={t("editCriteria.fields.proximity_to_role.subHeading")}
        allowReset={defaultValue !== proximity}
        onReset={handleReset}
      />

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <Typography
            fontSize="14px"
            fontWeight={500}
            color={theme.palette.grey[800]}
            lineHeight="20px"
          >
            {t("editCriteria.fields.proximity_to_role.label")}
          </Typography>
          <Tooltip
            arrow={false}
            title={t("editCriteria.fields.proximity_to_role.tooltip")}
            componentsProps={{
              tooltip: {
                sx: {
                  padding: "0.75rem",
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                  borderRadius: "0.5rem",
                  bgcolor: "white",
                  color: "grey.500",
                  fontSize: "0.75rem",
                  fontWeight: "500",
                  lineHeight: "1.125rem",
                  boxShadow: "0px 0.5rem 0.5rem #00000034",
                },
              },
            }}
          >
            <Box component="img" src={HelpCircleIcon} marginLeft="4px" />
          </Tooltip>
        </Stack>

        <AnnotatedInput
          error={error}
          annotation={t(
            "editCriteria.fields.proximity_to_role.inputLabel.miles",
          )}
          value={strProximity}
          onChange={handleChange}
        />
      </Stack>
    </Stack>
  );
};

export const TotalTenure = ({
  tenure,
  setTenure,
  defaultValue,
}: {
  tenure: TenureMatchingCriteria;
  setTenure: (arg: any) => void;
  defaultValue: TenureMatchingCriteria;
}) => {
  const { t } = useTranslation(Translation.settings);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTenure(event.target.value);
  };

  return (
    <Stack
      direction="column"
      style={{
        borderRadius: "12px",
        border: "1px solid var(--Gray-200, #EAECF0)",
        width: "100%",
        padding: "24px",
        display: "flex",
        gap: "32px",
      }}
    >
      <CriteriaRuleHeader
        headerText={t("editCriteria.fields.total_tenure.heading")}
        supportingText={t("editCriteria.fields.total_tenure.subHeading")}
        allowReset={defaultValue !== tenure}
        onReset={() => setTenure(defaultValue)}
      />

      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={tenure}
        onChange={handleChange}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <RadioOption
          label={t("editCriteria.fields.total_tenure.higher_tenure.label")}
          value={TenureMatchingCriteria.FAVOR_HIGHER_TENURE}
          tooltipText={t(
            "editCriteria.fields.total_tenure.higher_tenure.tooltip",
          )}
          showTooltip
        />
        <RadioOption
          label={t("editCriteria.fields.total_tenure.lower_tenure.label")}
          value={TenureMatchingCriteria.FAVOR_LOWER_TENURE}
          tooltipText={t(
            "editCriteria.fields.total_tenure.lower_tenure.tooltip",
          )}
          showTooltip
        />
        <RadioOption
          label={t("editCriteria.fields.total_tenure.disregard_tenure.label")}
          value={TenureMatchingCriteria.DISREGARD_TENURE}
          showTooltip={false}
        />
      </RadioGroup>
    </Stack>
  );
};

export const ApplyToAllRoles = ({
  applyToAll,
  setApplyToAll,
}: {
  applyToAll: boolean;
  setApplyToAll: (arg: boolean) => void;
}) => {
  const { t } = useTranslation(Translation.settings);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setApplyToAll(event.target.value === "true");
  };

  return (
    <RadioGroup
      id="apply-to-existing-roles-radio-group"
      aria-labelledby="demo-controlled-radio-buttons-group"
      name="controlled-radio-buttons-group"
      value={applyToAll}
      onChange={handleChange}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <RadioOption
        label={t("editCriteria.fields.apply_to_existing_roles.all_roles.label")}
        value={true}
        tooltipText={t(
          "editCriteria.fields.apply_to_existing_roles.all_roles.tooltip",
        )}
        showTooltip
      />
      <RadioOption
        label={t("editCriteria.fields.apply_to_existing_roles.new_roles.label")}
        value={false}
        tooltipText={t(
          "editCriteria.fields.apply_to_existing_roles.new_roles.tooltip",
        )}
        showTooltip
      />
    </RadioGroup>
  );
};

export const EnableCriteria = ({
  isEnabled,
  setIsEnabled,
}: {
  isEnabled: boolean;
  setIsEnabled: (arg: boolean) => void;
}) => {
  const { t } = useTranslation(Translation.settings);
  return (
    <Stack
      direction="column"
      style={{
        width: "100%",
        display: "flex",
        gap: "32px",
      }}
    >
      <Stack direction="row">
        <Box flexGrow="1">
          <Typography
            id="enable-criteria-heading"
            fontSize="14px"
            fontWeight={600}
            lineHeight="28px"
            color={(theme) => theme.palette.grey[800]}
          >
            {t("editCriteria.fields.enable_criteria.heading")}
          </Typography>
          <Typography
            id="enable-criteria-subheading"
            fontSize="14px"
            fontWeight={400}
            lineHeight="20px"
            color={(theme) => theme.palette.grey[500]}
          >
            {t("editCriteria.fields.enable_criteria.subheading")}
          </Typography>
        </Box>
        <Checkbox
          id="enable-criteria-checkbox"
          checked={isEnabled ? CheckedState.CHECKED : CheckedState.UNCHECKED}
          onCheck={(arg?: boolean) => {
            setIsEnabled(!arg);
          }}
        />
      </Stack>
    </Stack>
  );
};

export const DepartmentAndLocation = ({
  sameDepartment,
  setSameDepartment,
  proximity,
  setProximity,
  setInputError,
  defaultProximityValue,
  defaultSameDepartmentValue,
}: {
  sameDepartment: boolean;
  setSameDepartment: (arg: boolean) => void;
  proximity: number | null;
  setProximity: (arg: number | null) => void;
  setInputError: (arg: boolean) => void;
  defaultProximityValue: number;
  defaultSameDepartmentValue: boolean;
}) => {
  const { t } = useTranslation(Translation.settings);

  const [proximityError, setProximityError] = useState(false);
  const [departmentError, setDepartmentError] = useState(false);
  const [strProximity, setStrProximity] = useState("");

  useEffect(() => {
    setInputError(proximityError || departmentError);
  }, [proximityError, departmentError]);

  useEffect(() => {
    if (proximity) {
      setStrProximity(proximity.toString());
      if (isNumeric(proximity.toString()) && proximity > 0) {
        setProximityError(false);
      }
    }
  }, [proximity]);

  useEffect(() => {
    setDepartmentError(!sameDepartment && !proximity);
  }, [sameDepartment, proximity]);

  const handleSameDepartmentChange = (arg?: boolean) => {
    setSameDepartment(!arg);
  };

  const handleSameLocationChange = (arg?: boolean) => {
    setProximityError(false);

    if (arg) {
      setProximity(null);
    } else {
      setProximity(
        !strProximity ||
          !isNumeric(strProximity) ||
          parseFloat(strProximity) <= 0
          ? defaultProximityValue
          : parseFloat(strProximity),
      );
    }
  };

  const handleProximityChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let val = event.target.value;
    setStrProximity(val);
    if (!isNumeric(val) || parseFloat(val) <= 0) {
      setProximityError(true);
      return;
    }
    setProximityError(false);
    setProximity(parseFloat(val));
  };

  const handleReset = () => {
    setProximity(defaultProximityValue);
    setSameDepartment(defaultSameDepartmentValue);
  };

  return (
    <Stack
      id="department-and-location"
      direction="column"
      style={{
        borderRadius: "12px",
        border: "1px solid var(--Gray-200, #EAECF0)",
        width: "100%",
        padding: "24px",
        display: "flex",
        gap: "32px",
      }}
    >
      <CriteriaRuleHeader
        headerText={t("editCriteria.fields.department_and_location.heading")}
        supportingText={t(
          "editCriteria.fields.department_and_location.subHeading",
        )}
        allowReset={
          defaultProximityValue !== proximity ||
          defaultSameDepartmentValue !== sameDepartment
        }
        onReset={handleReset}
      />

      <Stack direction="column" gap="20px">
        <CheckboxOption
          id="match-department-checkbox"
          label={t(
            "editCriteria.fields.department_and_location.match_department.label",
          )}
          value={sameDepartment}
          showTooltip
          tooltipText={t(
            "editCriteria.fields.department_and_location.match_department.tooltip",
          )}
          onCheck={handleSameDepartmentChange}
          error={departmentError}
        />
        <Divider />
        <CheckboxOption
          id="match-location-checkbox"
          label={t(
            "editCriteria.fields.department_and_location.match_location.label",
          )}
          value={!!proximity}
          showTooltip
          tooltipText={t(
            "editCriteria.fields.department_and_location.match_location.tooltip",
          )}
          onCheck={handleSameLocationChange}
          error={departmentError}
        />
        <Typography
          fontSize="14px"
          fontWeight={400}
          lineHeight="20px"
          color={theme.palette.errorColor[700]}
          display="flex"
          justifyContent="end"
        >
          {departmentError &&
            t(
              "editCriteria.fields.department_and_location.unselectedOptionError",
            )}
        </Typography>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center">
            <Typography
              fontSize="14px"
              fontWeight={500}
              color={theme.palette.grey[800]}
              lineHeight="20px"
            >
              {t(
                "editCriteria.fields.department_and_location.max_distance.label",
              )}
            </Typography>
            <Tooltip
              arrow={false}
              title={t(
                "editCriteria.fields.department_and_location.max_distance.tooltip",
              )}
              componentsProps={{
                tooltip: {
                  sx: {
                    padding: "0.75rem",
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5rem",
                    borderRadius: "0.5rem",
                    bgcolor: "white",
                    color: "grey.500",
                    fontSize: "0.75rem",
                    fontWeight: "500",
                    lineHeight: "1.125rem",
                    boxShadow: "0px 0.5rem 0.5rem #00000034",
                    whiteSpace: "pre-wrap",
                  },
                },
              }}
            >
              <Box component="img" src={HelpCircleIcon} marginLeft="4px" />
            </Tooltip>
          </Stack>

          <AnnotatedInput
            id="proximity-to-role-input"
            error={proximityError}
            annotation="miles"
            value={strProximity}
            onChange={handleProximityChange}
            disabled={!proximity}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export const Name = ({
  criteriaId,
  name,
  setName,
  isSaveButtonClicked,
  setNameExistsError,
  criteriaList,
}: {
  criteriaId: string;
  name: string;
  setName: (arg: string) => void;
  isSaveButtonClicked: boolean;
  setNameExistsError: (arg: boolean) => void;
  criteriaList: RoleMatchingCriteriaDTO[];
}) => {
  const { t } = useTranslation(Translation.settings);
  const [nameError, setNameError] = useState("");
  const [isDirty, setIsDirty] = useState(false);

  const onFocus = () => {
    if (!isDirty) {
      setIsDirty(true);
    }
  };

  const doesNameAlreadyExist = (arg: string) => {
    return criteriaList.some(
      (criteria) => criteria.id != criteriaId && criteria.name === arg,
    );
  };

  const inputName = (name: string) => {
    setName(name);
    if (name.trim() === "") {
      setNameError(t("editCriteria.fields.name.requiredError"));
    } else if (doesNameAlreadyExist(name)) {
      setNameError(t("editCriteria.fields.name.alreadyExistsError"));
    } else {
      setNameError("");
    }
  };

  useEffect(() => {
    // setEmptyError(name.trim() === "");
    setNameExistsError(doesNameAlreadyExist(name));
  }, [name]);

  useEffect(() => {
    if (isSaveButtonClicked) {
      inputName(name);
    }
  }, [isSaveButtonClicked]);

  return (
    <CriteriaInput
      id="criteria-name-field"
      value={name}
      setValue={inputName}
      placeholder={t("editCriteria.fields.name.placeholder")}
      error={nameError}
      label={t("editCriteria.fields.name.label")}
      onFocus={onFocus}
    />
  );
};

export const NameAndInterests = ({
  name,
  setName,
  interests,
  setInterests,
  setNameExistsError,
  id,
  criteriaList,
  isSaveButtonClicked,
  infoToastContainerId,
}: {
  name: string;
  setName: (arg: string) => void;
  interests: {
    roles: string[];
    companies: string[];
  };
  setInterests: (arg: { roles: string[]; companies: string[] }) => void;
  setNameExistsError: (arg: boolean) => void;
  id: string;
  criteriaList: SalesPartnershipsCriteriaDTO[];
  isSaveButtonClicked: boolean;
  infoToastContainerId: string;
}) => {
  const { t } = useTranslation(Translation.settings);
  const [nameError, setNameError] = useState<string>("");
  const [rolesError, setRolesError] = useState<string>("");

  const doesNameAlreadyExist = (name: string) => {
    return criteriaList.some(
      (criteria) =>
        criteria.id !== id &&
        criteria.name.trim().toLowerCase() === name.trim().toLowerCase(),
    );
  };

  const onAlreadyAdded = (infoText: string) => {
    trackGoogleAnalyticsEvent({
      event: FailureEvent.FAILURE,
      name: "edit-criteria-already-added-info",
      type: "Error Toast",
      message: infoText,
    });
    toast.error(infoText, {
      containerId: infoToastContainerId,
      toastId: "edit-criteria-already-added-info",
    });
  };

  const inputName = (name: string) => {
    setName(name);
    if (name.trim() === "") {
      setNameError(t("editCriteria.fields.name_and_interests.name.emptyError"));
    } else if (doesNameAlreadyExist(name)) {
      setNameError(
        t("editCriteria.fields.name_and_interests.name.alreadyExistsError"),
      );
      setNameExistsError(true);
    } else {
      setNameError("");
      setNameExistsError(false);
    }
  };

  const inputCompanies = (companies: string[]) => {
    setInterests({ ...interests, companies: companies });
  };

  const inputRoles = (roles: string[]) => {
    setInterests({ ...interests, roles: roles });

    roles.length === 0
      ? setRolesError(
          t("editCriteria.fields.name_and_interests.roles.emptyError"),
        )
      : setRolesError("");
  };

  useEffect(() => {
    if (isSaveButtonClicked) {
      inputName(name);
      inputCompanies(interests.companies);
      inputRoles(interests.roles);
    }
  }, [isSaveButtonClicked]);

  return (
    <Stack
      direction="column"
      style={{
        width: "100%",
        display: "flex",
      }}
      spacing="1.5rem"
    >
      <CriteriaInput
        id="criteria-name-field"
        value={name}
        setValue={inputName}
        placeholder={t(
          "editCriteria.fields.name_and_interests.name.placeholder",
        )}
        error={nameError}
        label={t("editCriteria.fields.name_and_interests.name.label")}
      />
      <CriteriaMultiChipInput
        id="criteria-company-field"
        required={false}
        placeholder={t(
          "editCriteria.fields.name_and_interests.companies.placeholder",
        )}
        label={t("editCriteria.fields.name_and_interests.companies.label")}
        chipValues={interests.companies}
        setChipValues={inputCompanies}
        tooltipText={t(
          "editCriteria.fields.name_and_interests.companies.tooltip",
        )}
        error={""}
        onAlreadyAdded={() =>
          onAlreadyAdded(
            t(
              "editCriteria.fields.name_and_interests.companies.alreadyAddedInfo",
            ),
          )
        }
      />
      <CriteriaMultiChipInput
        id="criteria-role-field"
        required={true}
        placeholder={t(
          "editCriteria.fields.name_and_interests.roles.placeholder",
        )}
        label={t("editCriteria.fields.name_and_interests.roles.label")}
        chipValues={interests.roles}
        setChipValues={inputRoles}
        tooltipText={t("editCriteria.fields.name_and_interests.roles.tooltip")}
        error={rolesError}
        onAlreadyAdded={() =>
          onAlreadyAdded(
            t("editCriteria.fields.name_and_interests.roles.alreadyAddedInfo"),
          )
        }
      />
    </Stack>
  );
};

export const ReceiverEmail = ({
  email,
  setEmail,
  setFormatError,
  isSaveButtonClicked,
}: {
  email: string;
  setEmail: (arg: string) => void;
  setFormatError: (arg: boolean) => void;
  isSaveButtonClicked: boolean;
}) => {
  const { t } = useTranslation(Translation.settings);
  const [error, setError] = useState<string>("");

  const textProps = (isBolded: boolean) => {
    return {
      display: "inline",
      fontSize: "0.875rem",
      fontWeight: isBolded ? 500 : 400,
    };
  };

  const inputEmail = (email: string) => {
    setEmail(email);
    if (email.trim() === "") {
      setError(t("editCriteria.fields.receiver_email.emptyError"));
    } else if (!validateEmail(email)) {
      setError(t("editCriteria.fields.receiver_email.formatError"));
    } else {
      setError("");
    }
  };

  useEffect(() => {
    setFormatError(!validateEmail(email) && email.trim() !== "");
  }, [email]);

  useEffect(() => {
    if (isSaveButtonClicked) {
      inputEmail(email);
    }
  }, [isSaveButtonClicked]);

  return (
    <Stack spacing={2} marginBottom={4}>
      <CriteriaInput
        id="criteria-receiver-email-field"
        value={email}
        setValue={inputEmail}
        placeholder={t("editCriteria.fields.receiver_email.placeholder")}
        label={t("editCriteria.fields.receiver_email.label")}
        tooltipText={t("editCriteria.fields.receiver_email.tooltip")}
        error={error}
      />
      <Box>
        <Typography {...textProps(true)}>
          {t("editCriteria.fields.receiver_email.initial_notification_bolded")}
          {": "}
        </Typography>
        <Typography {...textProps(false)}>
          {t("editCriteria.fields.receiver_email.initial_notification_body")}
        </Typography>
      </Box>
      <Box>
        <Typography {...textProps(true)}>
          {t("editCriteria.fields.receiver_email.quarterly_digests_bolded")}
          {": "}
        </Typography>
        <Typography {...textProps(false)}>
          {t("editCriteria.fields.receiver_email.quarterly_digests_body")}
        </Typography>
      </Box>
    </Stack>
  );
};

export const DepartmentAndRoles = ({
  criteria,
  department,
  setDepartment,
  roles,
  setRoles,
  applyToAllRolesInDepartment,
  setApplyToAllRolesInDepartment,
  isSaveButtonClicked,
  departments,
  criteriaList,
}: {
  criteria: RoleMatchingCriteriaDTO;
  department: DepartmentDTO | null;
  setDepartment: (arg: DepartmentDTO | null) => void;
  roles: string[];
  setRoles: (arg: string[]) => void;
  applyToAllRolesInDepartment: boolean;
  setApplyToAllRolesInDepartment: (arg: boolean) => void;
  isSaveButtonClicked: boolean;
  departments: DepartmentDTO[];
  criteriaList: RoleMatchingCriteriaDTO[];
}) => {
  const { t } = useTranslation(Translation.settings);
  const [userConfig, _setUserConfig] = useContext(UserConfigContext);
  const [user, _setUser] = useContext(UserContext);
  const orgId = user?.orgId ?? "";

  const [departmentOptions, setDepartmentOptions] = useState<
    SelectOption<DepartmentDTO>[]
  >([]);
  const [selectedDepartmentOption, setSelectedDepartmentOption] =
    useState<SelectOption<DepartmentDTO> | null>(null);

  const [roleOptions, setRoleOptions] = useState<MultiSelectOption[]>([]);

  const [roleError, setRoleError] = useState(false);
  const [departmentError, setDepartmentError] = useState(false);

  const [
    isApplyToAllRolesRadioOptionDisabled,
    setIsApplyToAllRolesRadioOptionDisabled,
  ] = useState(false);

  const noDepartmentOption = {
    id: NO_DEPARTMENT_OPTION,
    value: {
      id: NO_DEPARTMENT_OPTION,
      name: t("noDepartment"),
      has_criteria_applied_to_all_roles: false,
    } as DepartmentDTO,
    label: t(
      "editCriteria.fields.department_and_roles.department.noDepartment",
    ),
    selectable: true,
  } as SelectOption<DepartmentDTO>;

  useEffect(() => {
    fetchRoleOptions();

    const hasCriteriaAppliedToSpecificRoles = !!(
      selectedDepartmentOption &&
      criteriaList.some(
        (c) =>
          c.id != criteria.id &&
          c.department?.id === selectedDepartmentOption?.id,
      )
    );

    setIsApplyToAllRolesRadioOptionDisabled(
      !selectedDepartmentOption || hasCriteriaAppliedToSpecificRoles,
    );

    if (hasCriteriaAppliedToSpecificRoles) {
      setApplyToAllRolesInDepartment(false);
    }
  }, [selectedDepartmentOption]);

  useEffect(() => {
    const occurenceCount: Record<string, number> = {};

    const departmentOptions = departments.map((department) => {
      let label = department.name;

      if (occurenceCount.hasOwnProperty(department.name)) {
        label += ` (${occurenceCount[department.name]})`;
        occurenceCount[department.name]++;
      } else {
        occurenceCount[department.name] = 1;
      }

      const isOptionSelectable =
        !department.has_criteria_applied_to_all_roles ||
        (criteria.department?.id == department.id &&
          criteria.apply_to_all_roles_in_department);

      if (!isOptionSelectable) {
        label += ` ${t(
          "editCriteria.fields.department_and_roles.department.allRolesAlreadyApplied",
        )}`;
      }

      return {
        id: department.id,
        value: department,
        label: label,
        selectable: isOptionSelectable,
      };
    });

    if (userConfig?.hasJobsWithNoDepartment()) {
      departmentOptions.push(noDepartmentOption);
    }

    setDepartmentOptions(departmentOptions);
  }, [departments]);

  useEffect(() => {
    if (departmentOptions.length > 0) {
      if (department?.id === NO_DEPARTMENT_OPTION) {
        setSelectedDepartmentOption(noDepartmentOption);
      } else {
        setSelectedDepartmentOption(
          departmentOptions.find((dep) => dep.value.id === department?.id) ??
            null,
        );
      }
    }
  }, [department, departmentOptions]);

  useEffect(() => {
    if (isSaveButtonClicked) {
      handleDepartmentChange(selectedDepartmentOption);
      handleRolesChange(new Set(roles), applyToAllRolesInDepartment);
    }
  }, [isSaveButtonClicked]);

  const fetchRoleOptions = async () => {
    try {
      if (!selectedDepartmentOption) {
        return [];
      }

      const response = await listJobNamesByAtsDepartment(
        orgId,
        selectedDepartmentOption.id === noDepartmentOption.id
          ? null
          : selectedDepartmentOption.value.id,
      );

      const roleOptions = response.job_titles.map((jobTitle) => {
        const isSelectable =
          jobTitle.role_matching_criteria_id === null ||
          jobTitle.role_matching_criteria_id === criteria.id;
        return {
          value: jobTitle.title,
          label: jobTitle.title,
          selectable: isSelectable,
          dropdownLabelDetails: isSelectable
            ? ""
            : t(
                "editCriteria.fields.department_and_roles.roles.dropdownLabelDetails",
              ),
        };
      });

      setRoleOptions(roleOptions);
    } catch (error) {
      console.error(
        "Error fetching autocomplete suggestions for roles input:",
        error,
      );
      return [];
    }
  };

  const handleDepartmentChange = (arg: SelectOption<DepartmentDTO> | null) => {
    if (
      (arg === null && selectedDepartmentOption != null) ||
      (arg != null && arg.id != selectedDepartmentOption?.id)
    ) {
      handleRolesChange(new Set(), applyToAllRolesInDepartment);
    }

    setSelectedDepartmentOption(arg);
    setDepartmentError(!arg);
    if (!arg) {
      setDepartment(null);
    } else {
      const selectedDepartment =
        departmentOptions.find((department) => department.id === arg.value.id)
          ?.value ?? null;
      setDepartment(selectedDepartment);
    }

    if (arg?.value.has_criteria_applied_to_specific_roles) {
      handleApplyToAllRolesInDepartmentChange(false);
    }
  };

  const handleApplyToAllRolesInDepartmentChange = (arg: boolean) => {
    setApplyToAllRolesInDepartment(arg);
    if (arg) {
      handleRolesChange(new Set(), arg);
    }
  };

  const handleRolesChange = (
    newRoles: Set<string>,
    applyToAllRolesInDepartment: boolean,
  ) => {
    setRoles(Array.from(newRoles));
    setRoleError(newRoles.size === 0 && !applyToAllRolesInDepartment);
  };

  return (
    <>
      <Stack gap="0.38rem">
        <Typography
          fontSize="0.875rem"
          fontWeight={500}
          lineHeight="1.25rem"
          color={theme.palette.grey[800]}
        >
          {t("editCriteria.fields.department_and_roles.department.label")}*
        </Typography>
        <SelectInput
          id="edit-criteria-department-input"
          options={departmentOptions}
          selectedOption={selectedDepartmentOption}
          setSelectedOption={handleDepartmentChange}
          placeholder={t(
            "editCriteria.fields.department_and_roles.department.placeholder",
          )}
          errorMessage={
            departmentError
              ? t(
                  "editCriteria.fields.department_and_roles.department.requiredError",
                )
              : ""
          }
        />
      </Stack>
      <Stack gap="0.38rem">
        <Typography
          fontSize="0.875rem"
          fontWeight={500}
          lineHeight="1.25rem"
          color={theme.palette.grey[800]}
        >
          {t("editCriteria.fields.department_and_roles.roles.label")}*
        </Typography>
        <RadioGroup
          id="apply-to-all-roles-in-department-radio-group"
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={applyToAllRolesInDepartment}
          onChange={(event: React.ChangeEvent) =>
            handleApplyToAllRolesInDepartmentChange(
              (event.target as HTMLInputElement).value === "true",
            )
          }
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "1.5rem",
          }}
        >
          <RadioOption
            label={t("editCriteria.fields.department_and_roles.roles.allRoles")}
            value={true}
            tooltipText={t(
              "editCriteria.fields.department_and_roles.roles.tooltip",
            )}
            showTooltip
            radioButtonOnRight={false}
            disabled={isApplyToAllRolesRadioOptionDisabled}
            hoverText={
              isApplyToAllRolesRadioOptionDisabled && selectedDepartmentOption
                ? t(
                    "editCriteria.fields.department_and_roles.roles.someRolesAlreadyApplied",
                  )
                : ""
            }
          />
          <RadioOption
            label={t(
              "editCriteria.fields.department_and_roles.roles.specificRoles",
            )}
            value={false}
            showTooltip={false}
            radioButtonOnRight={false}
            disabled={!selectedDepartmentOption}
          />
        </RadioGroup>
        <MultiSelectComboBox
          id="edit-criteria-roles-input"
          options={roleOptions}
          selectedValues={new Set(roles)}
          setSelectedValues={(roles) =>
            handleRolesChange(roles, applyToAllRolesInDepartment)
          }
          showChevron
          placeholder={
            roles && roles.length > 0
              ? ""
              : t("editCriteria.fields.department_and_roles.roles.placeholder")
          }
          format={Format.uppercaseFirstLetter}
          disabled={!selectedDepartmentOption || applyToAllRolesInDepartment}
          inputErrorMessage={
            roleError &&
            selectedDepartmentOption &&
            !applyToAllRolesInDepartment
              ? t(
                  "editCriteria.fields.department_and_roles.roles.requiredError",
                )
              : ""
          }
          selectAllLabel={t(
            "editCriteria.fields.department_and_roles.roles.selectAll",
          )}
        />
      </Stack>
    </>
  );
};

export const Automation = ({
  isAutomated,
  setIsAutomated,
}: {
  isAutomated: boolean;
  setIsAutomated: (arg: boolean) => void;
}) => {
  const { t } = useTranslation(Translation.settings);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAutomated(event.target.value === "true");
  };

  return (
    <Stack
      direction="column"
      style={{
        borderRadius: "12px",
        border: "1px solid var(--Gray-200, #EAECF0)",
        width: "100%",
        padding: "24px",
        display: "flex",
        gap: "32px",
      }}
    >
      <CriteriaRuleHeader
        headerText={t("editCriteria.fields.automation.heading")}
        supportingText={t("editCriteria.fields.automation.subHeading")}
        allowReset={false}
        onReset={() => {}}
        hideReset
      />
      <RadioGroup
        id="automation-radio-group"
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={!!isAutomated}
        onChange={handleChange}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <RadioOption
          label={t("editCriteria.fields.automation.off.label")}
          value={false}
          tooltipText={t("editCriteria.fields.automation.off.tooltip")}
          showTooltip
        />
        <RadioOption
          label={t("editCriteria.fields.automation.on.label")}
          value={true}
          tooltipText={t("editCriteria.fields.automation.on.tooltip")}
          showTooltip
        />
      </RadioGroup>
    </Stack>
  );
};
