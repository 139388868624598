import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { Translation } from "../../lib/constants";
import { MessageType } from "../../models/auth";

export const SamlFail = () => {
  const { t } = useTranslation(Translation.login);

  return <Navigate to="/login" state={{ type: MessageType.ERROR, message: t("sso.saml.failure") }} />;
};
