import { Navigate, createBrowserRouter } from "react-router-dom";
import { getAlum } from "./api/alum";
import { getJob } from "./api/job";
import ProtectedRoute from "./components/ProtectedRoute";
import "./i18n";
import { RouteId } from "./lib/constants";
import AlumProfile from "./pages/AlumProfile";
import Authenticator from "./pages/Authenticator";
import Login from "./pages/Login";
import RolesDatabase from "./pages/RolesDatabase";
import RolesView from "./pages/RolesView";
import "./styles.global.css";
import RoleMatching from "./pages/RoleMatching";
import ReferralMatching from "./pages/ReferralMatching";
import SalesAndPartnershipMatching from "./pages/SalesAndPartnershipMatching";
import Unsubscribe from "./pages/Unsubscribe";
import Resubscribe from "./pages/Resubscribe";
import ErrorPage from "./components/Error/ErrorPage";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import AlumniDatabase from "./pages/AlumniDatabase";
import AlumniDBErrorPage from "./components/Error/AlumniDBErrorPage";
import { JobDashboardDTO } from "./models/api/job";
import { AlumDTO } from "./models/api/alum";
import TableView from "./components/TableView";
import { Format, loadUserFromStorage } from "./lib/utils";
import RoleHistory from "./pages/RoleHistory";
import OAuthCallback from "./pages/SSO/OAuthCallback";
import SamlInit from "./pages/SSO/SamlInit";
import { SamlFail } from "./pages/SSO/SamlFail";
import ApprovalPage from "./pages/Approval";

//The type that represents the type of route handle that supports breadcrumb
export type HandleType = {
  crumb?: (param?: any) => [label: string, link: string];
};

export type RolesViewData = {
  job: JobDashboardDTO;
  jobId: string;
  alumId: string | null;
};

export type AlumProfileData = {
  alum: AlumDTO;
  jobId: string | null;
  alumId: string;
};

export const router = createBrowserRouter([
  {
    path: "/",
    element: <ProtectedRoute />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Navigate to="roles-database" />,
      },
      {
        path: "roles-database",
        element: <TableView />,
        handle: {
          crumb: () => ["Roles Database", "/roles-database"],
        },
        children: [
          {
            index: true,
            element: <RolesDatabase />,
          },
          {
            path: ":jobId",
            element: <TableView />,
            id: RouteId.rolesDatabaseRolesView,
            loader: async ({ params }) => {
              const orgId = loadUserFromStorage()?.orgId ?? "";
              return {
                job: await getJob(orgId, params.jobId!),
                jobId: params.jobId,
                alumId: null,
              } as RolesViewData;
            },
            handle: {
              crumb: (data: RolesViewData | null) => {
                if (data)
                  return [
                    Format.uppercaseFirstLetter(data.job.name),
                    `/roles-database/${data.jobId}`,
                  ];

                return null;
              },
            },
            children: [
              {
                index: true,
                element: <RolesView baseRoute={RouteId.rolesDatabase} />,
              },
              {
                id: RouteId.rolesDatabaseAlumProfile,
                path: "alumni/:alumId",
                loader: async ({ params }) => {
                  const orgId = loadUserFromStorage()?.orgId ?? "";
                  return {
                    alum: await getAlum(orgId, params.alumId!),
                    jobId: params.jobId,
                    alumId: params.alumId,
                  } as AlumProfileData;
                },
                handle: {
                  crumb: (data: AlumProfileData | null) => {
                    if (data)
                      return [
                        `${Format.capitalize(
                          data.alum.first_name,
                        )} ${Format.capitalize(data.alum.last_name)}`,
                        `/roles-database/${data.jobId}/alumni/${data.alumId}`,
                      ];

                    return null;
                  },
                },
                children: [
                  {
                    index: true,
                    element: <AlumProfile baseRoute={RouteId.rolesDatabase} />,
                  },
                  {
                    path: "role-history",
                    element: <RoleHistory baseRoute={RouteId.rolesDatabase} />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "alumni-database",
        element: <TableView />,
        errorElement: <AlumniDBErrorPage />,
        handle: {
          crumb: () => ["Alumni Database", "/alumni-database"],
        },
        children: [
          {
            index: true,
            element: <AlumniDatabase />,
          },
          {
            id: RouteId.alumDatabaseAlumProfile,
            path: ":alumId",
            loader: async ({ params }) => {
              const orgId = loadUserFromStorage()?.orgId ?? "";
              return {
                alum: await getAlum(orgId, params.alumId!),
                jobId: null,
                alumId: params.alumId,
              } as AlumProfileData;
            },
            handle: {
              crumb: (data: AlumProfileData | null) => {
                if (data)
                  return [
                    `${Format.capitalize(
                      data.alum.first_name,
                    )} ${Format.capitalize(data.alum.last_name)}`,
                    `/alumni-database/${data.alumId}`,
                  ];

                return null;
              },
            },
            children: [
              {
                index: true,
                element: <AlumProfile baseRoute={RouteId.alumDatabase} />,
              },
              {
                path: "role-history",
                element: <RoleHistory baseRoute={RouteId.alumDatabase} />,
              },
              {
                id: RouteId.alumDatabaseRolesView,
                element: <TableView />,
                path: "job/:jobId",
                loader: async ({ params }) => {
                  const orgId = loadUserFromStorage()?.orgId ?? "";
                  return {
                    job: await getJob(orgId, params.jobId!),
                    alumId: params.alumId,
                    jobId: params.jobId,
                  } as RolesViewData;
                },
                handle: {
                  crumb: (data: RolesViewData | null) => {
                    if (data)
                      return [
                        Format.uppercaseFirstLetter(data.job.name),
                        `/alumni-database/${data.alumId}/job/${data.jobId}`,
                      ];

                    return null;
                  },
                },
                children: [
                  {
                    index: true,
                    element: <RolesView baseRoute={RouteId.alumDatabase} />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "settings",
        children: [
          {
            index: true,
            element: <Navigate to="role-matching" />,
          },
          {
            path: "role-matching",
            element: <RoleMatching />,
            handle: {
              crumb: () => ["Role Matching", "/settings/role-matching"],
            },
          },
          {
            path: "referral-matching",
            element: <ReferralMatching />,
            handle: {
              crumb: () => ["Referral Matching", "/settings/referral-matching"],
            },
          },
          {
            path: "sales-and-partnership-matching",
            element: <SalesAndPartnershipMatching />,
            handle: {
              crumb: () => [
                "Sales and Partnership Matching",
                "/settings/sales-and-partnership-matching",
              ],
            },
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password/:token",
    element: <ResetPassword />,
  },
  {
    path: "/authenticator/:qrCodeUri",
    element: <Authenticator />,
  },
  {
    path: "/unsubscribe/:employeeId",
    element: <Unsubscribe />,
  },
  {
    path: "/resubscribe/:employeeId",
    element: <Resubscribe />,
  },
  {
    path: "/oauth2/callback",
    element: <OAuthCallback />,
  },
  {
    path: "/match",
    children: [
      {
        "path": "approved/:approvalCode",
        element: <ApprovalPage type="approved" />
      },
      {
        "path": "rejected/:approvalCode",
        element: <ApprovalPage type="rejected" />
      }
    ]
  },
  {
    path: "/saml",
    children: [
      {
        path: "init",
        element: <SamlInit />,
      },
      {
        path: "fail",
        element: <SamlFail />,
      },
    ],
  },
]);
