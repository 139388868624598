import { http } from ".";
import {
  DepartmentAutocompleteResponse,
  JobLocationsResponse,
  ListRecruitersResponse,
  LocationAutocompleteResponse,
} from "../models/api/filter";
import { HttpError, HttpResponse, ResponseStatus } from "../models/api/http";

export const autocompleteLocation = async (
  orgId: string,
  searchTerm: string,
): Promise<LocationAutocompleteResponse> => {
  const response = await http.get<HttpResponse<LocationAutocompleteResponse>>(
    `/org/${orgId}/location/alumni-last-known?search=${encodeURIComponent(
      searchTerm,
    )}`,
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return response.data.data;
};

export const autocompleteDepartment = async (
  orgId: string,
  searchTerm: string,
): Promise<DepartmentAutocompleteResponse> => {
  const response = await http.get<HttpResponse<DepartmentAutocompleteResponse>>(
    `/org/${orgId}/group/org-alumni-former-departments?search=${encodeURIComponent(
      searchTerm,
    )}`,
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return response.data.data;
};

export const listRecruiters = async (
  orgId: string,
): Promise<ListRecruitersResponse> => {
  const response = await http.get<HttpResponse<ListRecruitersResponse>>(
    `/org/${orgId}/recruiters/list`,
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return response.data.data;
};

export const listLocations = async (
  orgId: string,
): Promise<JobLocationsResponse> => {
  const response = await http.get<HttpResponse<JobLocationsResponse>>(
    `/org/${orgId}/location/org-job-locations`,
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return response.data.data;
};
