import { http } from ".";
import { HttpError, HttpResponse, ResponseStatus } from "../models/api/http";

export const getEnabledFlags = async (orgId: string): Promise<string[]> => {
  const response = await http.get<HttpResponse<string[]>>(
    `/org/${orgId}/flag`,
    false
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return response.data.data;
};
