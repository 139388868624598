import { http } from ".";
import {
  kilometersToMiles,
  milesToKilometers,
  NO_DEPARTMENT_OPTION,
} from "../lib/utils";
import {
  ListCriteriaResponse,
  RoleMatchingCriteriaDTO,
  ReferralMatchingCriteriaDTO,
  SalesPartnershipsCriteriaDTO,
  ListDepartmentsFromAtsResponse,
} from "../models/api/criteria";
import { HttpError, HttpResponse, ResponseStatus } from "../models/api/http";

enum CriteriaType {
  Roles = "role-matching-criteria",
  Referrals = "referral-matching-criteria",
  SnP = "sales-partnerships-criteria",
}

async function listCriteria<T>(orgId: string, type: CriteriaType): Promise<T> {
  let url = `/org/${orgId}/settings/${type}/list`;

  const response = await http.get<HttpResponse<T>>(url);
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }

  return response.data.data;
}

export const listRoleMatchingCriteria = async (
  orgId: string,
): Promise<ListCriteriaResponse<RoleMatchingCriteriaDTO>> => {
  const response = await listCriteria<
    ListCriteriaResponse<RoleMatchingCriteriaDTO>
  >(orgId, CriteriaType.Roles);
  response.criteria.forEach((criteria: RoleMatchingCriteriaDTO) => {
    criteria.proximity_to_role = kilometersToMiles(criteria.proximity_to_role);
  });
  return response;
};

export const listReferralMatchingCriteria = async (
  orgId: string,
): Promise<ListCriteriaResponse<ReferralMatchingCriteriaDTO>> => {
  const response = await listCriteria<
    ListCriteriaResponse<ReferralMatchingCriteriaDTO>
  >(orgId, CriteriaType.Referrals);
  response.criteria.forEach((criteria: ReferralMatchingCriteriaDTO) => {
    criteria.proximity_to_role = kilometersToMiles(criteria.proximity_to_role);
  });
  return response;
};

export const listSnPMatchingCriteria = async (
  orgId: string,
): Promise<ListCriteriaResponse<SalesPartnershipsCriteriaDTO>> => {
  return await listCriteria<ListCriteriaResponse<SalesPartnershipsCriteriaDTO>>(
    orgId,
    CriteriaType.SnP,
  );
};

export async function createRoleMatchingCriteria(
  orgId: string,
  criteria: RoleMatchingCriteriaDTO,
): Promise<RoleMatchingCriteriaDTO> {
  const response = await http.post<HttpResponse<RoleMatchingCriteriaDTO>>(
    `/org/${orgId}/settings/role-matching-criteria/`,
    {
      name: criteria.name,
      is_enabled: criteria.is_enabled,
      time_since_departure: criteria.time_since_departure,
      proximity_to_role: milesToKilometers(criteria.proximity_to_role),
      apply_to_existing_roles: criteria.apply_to_existing_roles,
      total_tenure: criteria.total_tenure,
      is_automated: criteria.is_automated,
      is_baseline: criteria.is_baseline,
      department_id:
        criteria.department?.id != NO_DEPARTMENT_OPTION
          ? criteria.department?.id
          : null,
      job_titles: criteria.job_titles,
      apply_to_all_roles_in_department:
        criteria.apply_to_all_roles_in_department,
    },
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return response.data.data;
}

export async function editRoleMatchingCriteria(
  orgId: string,
  criteria: RoleMatchingCriteriaDTO,
): Promise<RoleMatchingCriteriaDTO> {
  const response = await http.patch<HttpResponse<RoleMatchingCriteriaDTO>>(
    `/org/${orgId}/settings/role-matching-criteria/${criteria.id}`,
    {
      name: criteria.name,
      is_enabled: criteria.is_enabled,
      time_since_departure: criteria.time_since_departure,
      proximity_to_role: milesToKilometers(criteria.proximity_to_role),
      apply_to_existing_roles: criteria.apply_to_existing_roles,
      total_tenure: criteria.total_tenure,
      is_automated: criteria.is_automated,
      department_id:
        criteria.department?.id != NO_DEPARTMENT_OPTION
          ? criteria.department?.id
          : null,
      job_titles: criteria.job_titles,
      apply_to_all_roles_in_department:
        criteria.apply_to_all_roles_in_department,
    },
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return response.data.data;
}

export async function deleteRoleMatchingCriteria(
  orgId: string,
  criteriaId: string,
): Promise<string> {
  const response = await http.delete<HttpResponse<string>>(
    `/org/${orgId}/settings/role-matching-criteria/${criteriaId}`,
  );
  if (response.data.status === ResponseStatus.ERROR) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return "success";
}

export async function editReferralMatchingCriteria(
  orgId: string,
  criteria: ReferralMatchingCriteriaDTO,
): Promise<ReferralMatchingCriteriaDTO> {
  const response = await http.patch<HttpResponse<ReferralMatchingCriteriaDTO>>(
    `/org/${orgId}/settings/referral-matching-criteria/${criteria.id}`,
    {
      name: criteria.name,
      is_enabled: criteria.is_enabled,
      time_since_departure: criteria.time_since_departure,
      proximity_to_role: milesToKilometers(criteria.proximity_to_role),
      match_to_same_department: criteria.match_to_same_department,
    },
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return response.data.data;
}

export async function editSalesPartnershipsCriteria(
  orgId: string,
  criteria: SalesPartnershipsCriteriaDTO,
): Promise<SalesPartnershipsCriteriaDTO> {
  const response = await http.patch<HttpResponse<SalesPartnershipsCriteriaDTO>>(
    `/org/${orgId}/settings/sales-partnerships-criteria/${criteria.id}`,
    {
      name: criteria.name,
      is_enabled: criteria.is_enabled,
      receiver_email: criteria.receiver_email,
      interests: criteria.interests,
    },
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return response.data.data;
}

export async function createSalesPartnershipsCriteria(
  orgId: string,
  criteria: SalesPartnershipsCriteriaDTO,
): Promise<SalesPartnershipsCriteriaDTO> {
  const response = await http.post<HttpResponse<SalesPartnershipsCriteriaDTO>>(
    `/org/${orgId}/settings/sales-partnerships-criteria/`,
    {
      name: criteria.name,
      is_enabled: criteria.is_enabled,
      receiver_email: criteria.receiver_email,
      interests: criteria.interests,
    },
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return response.data.data;
}

export async function deleteSalesPartnershipsCriteria(
  orgId: string,
  criteria: SalesPartnershipsCriteriaDTO,
): Promise<string> {
  const response = await http.delete<HttpResponse<string>>(
    `/org/${orgId}/settings/sales-partnerships-criteria/${criteria.id}`,
  );

  if (response.data.status === ResponseStatus.ERROR) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return "success";
}

export async function listDepartmentsFromAts(
  orgId: string,
): Promise<ListDepartmentsFromAtsResponse> {
  const response = await http.get<HttpResponse<ListDepartmentsFromAtsResponse>>(
    `/org/${orgId}/ats-department/list`,
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return response.data.data;
}
