import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Typography, Stack, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Translation } from "../../lib/constants";
import ApprovalCard from "./ApprovalCard";
import textLogo from "/text-logo.svg";
import { approveMatch, rejectMatch } from "../../api/alum.ts";

const ApprovalPage = ({ type }: { type: "approved" | "rejected" }) => {
  const { t } = useTranslation(Translation.common);
  const { approvalCode } = useParams();
  const [name, setName] = useState("");

  if (!approvalCode) {
    return (<></>);
  }

  const handleApproval = async () => {
    let response;
    if (type === "approved") {
      response = await approveMatch(approvalCode);
    } else if (type === "rejected") {
      response = await rejectMatch(approvalCode);
    }
    if (response && response.first_name !== "" && response.last_name !== "") {
      setName(response.first_name + " " + response.last_name);
    }
  };

  useEffect(() => {
    handleApproval();
  }, [type, approvalCode]);

  return (
    <Stack height="100vh" width="100vw" padding="48px 80px">
      <Stack flex="1" justifyContent="center" alignItems="center">
        <ApprovalCard type={type} name={name}/>
      </Stack>

      <Stack
        width="100%"
        borderTop="1px solid var(--Gray-200, #EAECF0)"
        paddingTop="32px"
        justifyContent="space-between"
        direction="row"
      >
        <Box
          component="img"
          sx={{ width: "9rem" }}
          alt={t("footer.boomerangLogoAlt")}
          src={textLogo}
        />

        <Typography
          color="var(--Gray-500, #667085)"
          fontFamily="Inter"
          fontSize="16px"
          fontStyle="normal"
          fontWeight="400"
          lineHeight="24px"
        >
          {t("footer.rights")}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ApprovalPage;
