import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  createRoleMatchingCriteria,
  deleteRoleMatchingCriteria,
  editRoleMatchingCriteria,
  listDepartmentsFromAts,
  listRoleMatchingCriteria,
} from "../../api/criteria";
import {
  EmailSettingsType,
  getEmailFrequency,
  updateEmailFrequency,
} from "../../api/email";
import { listJobsImpactedByRoleMatchingCriteria } from "../../api/job.ts";
import ConfirmActionModal from "../../components/ConfirmActionModal/index.tsx";
import EditCriteria, { NotificationInfo } from "../../components/EditCriteria";
import EmailSettings from "../../components/EmailSettings";
import EmptyResult from "../../components/Error/EmptyResult.tsx";
import Loading from "../../components/Loading";
import ErrorToastContainer from "../../components/Toastify/ErrorToastContainer.tsx";
import InfoToastContainer from "../../components/Toastify/InfoToastContainer.tsx";
import SuccessToastContainer from "../../components/Toastify/SuccessToastContainer.tsx";
import { Translation } from "../../lib/constants";
import { UserContext } from "../../lib/context.ts";
import {
  FailureEvent,
  RoleMatchingCriteriaEvent,
  RoleMatchingEmailSettingsEvent,
} from "../../lib/eventEnums.ts";
import {
  areArraysEqual,
  isInteger,
  NO_DEPARTMENT_OPTION,
  trackGoogleAnalyticsEvent,
} from "../../lib/utils";
import {
  DepartmentDTO,
  RoleMatchingCriteriaDTO,
  TenureMatchingCriteria,
} from "../../models/api/criteria";
import { ROLE_MATCHING_CRITERIA_DEFAULT_VALUES } from "../../models/criteria";
import Header from "../../theme/Header";
import RoleMatchingCriteriaList from "./RoleMatchingCriteriaList.tsx";

export default function RoleMatching() {
  const theme = useTheme();
  const { t } = useTranslation(Translation.settings);
  const { state } = useLocation();

  const [tabValue, setTabValue] = useState(0);
  const [outgoingTabValue, setOutgoingTabValue] = useState(0);
  const [criteriaToEdit, setCriteriaToEdit] =
    useState<RoleMatchingCriteriaDTO | null>(null);
  const [newCriteria, setNewCriteria] =
    useState<RoleMatchingCriteriaDTO | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [criteriaList, setCriteriaList] = useState<RoleMatchingCriteriaDTO[]>(
    [],
  );
  const [globalCriteriaList, setGlobalCriteriaList] = useState<
    RoleMatchingCriteriaDTO[]
  >([]);
  const [emailFrequencyAmount, setEmailFrequencyAmount] = useState(0);
  const [emailFrequencyStr, setEmailFrequencyStr] = useState("0");
  const [emailFrequencyType, setEmailFrequencyType] = useState("WEEKS");
  const [isEmailFrequencyError, setIsEmailFrequencyError] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [discardModalOpen, setDiscardModalOpen] = useState(false);
  const [criteriaToDelete, setCriteriaToDelete] =
    useState<RoleMatchingCriteriaDTO | null>(null);
  const [departments, setDepartments] = useState<DepartmentDTO[]>([]);
  const [updatingOrCreatingCriteria, setUpdatingOrCreatingCriteria] =
    useState(false);
  const [user, _setUser] = useContext(UserContext);
  const orgId = user?.orgId ?? "";

  const newCriteriaIdentifier = "new";

  const toastContainerIds = {
    info: "role-matching-info",
    success: "role-matching-success",
    error: "role-matching-error",
  };

  useEffect(() => {
    if (criteriaList.length > 0) {
      if (state?.criteriaId) {
        const criteria = criteriaList.find((c) => c.id === state.criteriaId);
        if (criteria) {
          setCriteriaToEdit(criteria);
        }
        state.criteriaId = null;
      }
    }

    fetchDepartments();
  }, [criteriaList]);

  const fetchCriteria = async () => {
    setIsLoading(true);
    try {
      const data = await listRoleMatchingCriteria(orgId);
      data.criteria.forEach((c) => {
        if (!c.department) {
          c.department = {
            id: NO_DEPARTMENT_OPTION,
            name: t("noDepartment"),
            has_criteria_applied_to_all_roles: false,
            has_criteria_applied_to_specific_roles: false,
          } as DepartmentDTO;
        }
      });
      setGlobalCriteriaList(data.criteria.filter((c) => c.is_baseline));
      setCriteriaList(data.criteria.filter((c) => !c.is_baseline));
    } catch {
      console.log("fetching criterias failed");
      setIsError(true);
    }
    setIsLoading(false);
  };

  const fetchEmailFrequency = async () => {
    try {
      const data = await getEmailFrequency(orgId, EmailSettingsType.Roles);
      setEmailFrequencyAmount(data.frequency);
      setEmailFrequencyType(data.frequency_unit);
      setEmailFrequencyStr(data.frequency?.toString() ?? "0");
    } catch {
      console.log("fetching email frequency failed");
    }
  };

  const updateCriteria = async (
    oldCriteria: RoleMatchingCriteriaDTO | null,
    newCriteria: RoleMatchingCriteriaDTO | null,
  ) => {
    try {
      setUpdatingOrCreatingCriteria(true);
      if (newCriteria && newCriteria.id === newCriteriaIdentifier) {
        await createRoleMatchingCriteria(orgId, newCriteria);
      } else {
        await editRoleMatchingCriteria(orgId, newCriteria!);
      }
      // fetchCriteria can stay async because we return to close modal first and in the meantime the list will handle its own loading state
      void fetchCriteria();

      if (newCriteria && newCriteria.id === newCriteriaIdentifier) {
        handleCriteriaAnalytics(
          RoleMatchingCriteriaEvent.NEW_CRITERIA_SAVE,
          null,
          newCriteria,
        );
      } else {
        handleCriteriaAnalytics(
          RoleMatchingCriteriaEvent.EDIT_CRITERIA_SAVE,
          oldCriteria,
          newCriteria,
        );
      }

      return true;
    } catch (error) {
      console.log("update criteria failed", error);
      return false;
    } finally {
      setUpdatingOrCreatingCriteria(false);
    }
  };

  const deleteCriteria = async (arg: RoleMatchingCriteriaDTO | null) => {
    if (arg) {
      try {
        setUpdatingOrCreatingCriteria(true);
        await deleteRoleMatchingCriteria(orgId, arg.id);
        void fetchCriteria();
        return true;
      } catch (error) {
        console.log("delete criteria failed", error);
        return false;
      } finally {
        setUpdatingOrCreatingCriteria(false);
      }
    } else {
      return false;
    }
  };

  const onCreateNewClick = () => {
    const newCriteria = {
      ...ROLE_MATCHING_CRITERIA_DEFAULT_VALUES,
    } as unknown as RoleMatchingCriteriaDTO;
    newCriteria.id = newCriteriaIdentifier;

    trackGoogleAnalyticsEvent({
      event: RoleMatchingCriteriaEvent.NEW_CRITERIA_CLICK,
      org: user?.orgName,
    });
    setNewCriteria(newCriteria);
  };

  const onEditClick = (criteria: RoleMatchingCriteriaDTO) => {
    trackGoogleAnalyticsEvent({
      event: RoleMatchingCriteriaEvent.EDIT_CRITERIA_CLICK,
      org: user?.orgName,
      customParameters: {
        id: criteria.id,
      },
    });
    setCriteriaToEdit(criteria);
  };

  const setCriteriaEnabled = async (
    criteria: RoleMatchingCriteriaDTO | null,
    newValue: boolean,
  ) => {
    if (criteria) {
      try {
        setUpdatingOrCreatingCriteria(true);
        criteria.is_enabled = newValue;
        await editRoleMatchingCriteria(orgId, criteria).then(() => {
          void fetchCriteria();
        });
        trackGoogleAnalyticsEvent({
          event: newValue
            ? RoleMatchingCriteriaEvent.IS_ENABLED_FROM_TOOGLE
            : RoleMatchingCriteriaEvent.IS_DISABLED_FROM_TOGGLE,
          org: user?.orgName,
        });
      } catch (error) {
        console.log("update criteria failed", error);
      } finally {
        setUpdatingOrCreatingCriteria(false);
      }
    }
  };

  const saveMatchingFrequency = async () => {
    setDiscardModalOpen(false);
    if (isEmailFrequencyError) {
      trackGoogleAnalyticsEvent({
        event: FailureEvent.FAILURE,
        name: "email-settings-error",
        type: "Error Toast",
        message: t("emailSettings.saveError"),
      });
      toast.error(t("emailSettings.saveError"), {
        containerId: toastContainerIds.error,
        toastId: "email-settings-error",
      });
      return;
    }
    try {
      await updateEmailFrequency(
        orgId,
        EmailSettingsType.Roles,
        emailFrequencyAmount,
        emailFrequencyType,
      );

      trackGoogleAnalyticsEvent({
        event: RoleMatchingEmailSettingsEvent.EMAIL_CADENCE_CHANGES_SAVED,
        org: user?.orgName,
      });
      toast.success(t("emailSettings.saveSuccess"), {
        containerId: toastContainerIds.success,
        toastId: "email-settings-success",
      });
      setHasUnsavedChanges(false);
    } catch (error) {
      console.log("update email frequency failed", error);
      trackGoogleAnalyticsEvent({
        event: FailureEvent.FAILURE,
        name: "email-frequency-error",
        type: "Error Toast",
        message: t("emailSettings.saveError"),
      });
      toast.error(t("emailSettings.saveError"), {
        containerId: toastContainerIds.error,
        toastId: "email-frequency-error",
      });
    }
    if (outgoingTabValue !== tabValue) {
      setTabValue(outgoingTabValue);
    }
  };

  const handleFrequencyTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setHasUnsavedChanges(true);
    const value = (event.target as HTMLInputElement).value;

    trackGoogleAnalyticsEvent({
      event:
        value === "WEEKS"
          ? RoleMatchingEmailSettingsEvent.EMAIL_CADENCE_CHANGED_TO_WEEKLY
          : RoleMatchingEmailSettingsEvent.EMAIL_CADENCE_CHANGED_TO_MONTHLY,
      org: user?.orgName,
    });
    setEmailFrequencyType(value);
  };

  const updateFrequencyAmount = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setHasUnsavedChanges(true);
    const val = event.target.value;
    setEmailFrequencyStr(val);
    if (!isInteger(val)) {
      setIsEmailFrequencyError(true);
      return;
    }
    setIsEmailFrequencyError(false);
    setEmailFrequencyAmount(parseInt(val));
  };

  const discardChanges = () => {
    void fetchEmailFrequency();
    setDiscardModalOpen(false);
    setHasUnsavedChanges(false);
    toast.info(t("emailSettings.discardChangesMessage"), {
      containerId: toastContainerIds.info,
      toastId: "discard-changes-info",
    });
    setTabValue(outgoingTabValue);
  };

  useEffect(() => {
    if (tabValue === 0) {
      void fetchCriteria();
    }
    if (tabValue === 1) {
      void fetchEmailFrequency();
    }
  }, [tabValue]);

  const handleTabChange = (
    _: React.SyntheticEvent<Element, Event>,
    newValue: number,
  ) => {
    setOutgoingTabValue(newValue);
    if (hasUnsavedChanges) {
      setDiscardModalOpen(hasUnsavedChanges);
    } else {
      setTabValue(newValue);
    }
  };

  const closeEditWindow = (notification?: NotificationInfo) => {
    setCriteriaToEdit(null);
    setNewCriteria(null);
    if (notification) {
      toast.info(notification.message, {
        containerId: toastContainerIds.info,
        toastId: "edit-criteria-info",
      });
    }
  };

  const fetchImpactedRolesElement = async (
    criteria: RoleMatchingCriteriaDTO | null,
  ) => {
    if (criteria) {
      try {
        const jobs = criteria.apply_to_existing_roles
          ? await listJobsImpactedByRoleMatchingCriteria(
              orgId,
              criteria.job_titles,
              criteria.department?.id &&
                criteria.department?.id != NO_DEPARTMENT_OPTION
                ? criteria.department.id
                : null,
              criteria.apply_to_all_roles_in_department,
            )
          : [];

        return (
          <Stack gap="0.5rem">
            {jobs.length > 0 ? (
              <>
                <Typography
                  fontSize="0.875rem"
                  lineHeight="1.25rem"
                  fontWeight={400}
                  color={theme.palette.grey[500]}
                >
                  {t("editCriteria.modals.save.wouldLikeToSaveAndAffectRoles")}
                </Typography>
                <Stack
                  id="impacted-roles"
                  sx={{
                    marginTop: "0.5rem",
                    gap: "0.5rem",
                    maxHeight: "5rem",
                    overflow: jobs.length > 3 ? "scroll" : "none",
                    scrollbarColor:
                      theme.palette.grey[300] +
                      " " +
                      theme.palette.background.default,
                    scrollPadding: 0,
                  }}
                >
                  {jobs.map((job, index) => (
                    <Typography
                      fontSize="0.875rem"
                      lineHeight="1rem"
                      fontWeight={400}
                      color={theme.palette.grey[500]}
                    >
                      {index + 1}. {job.name}{" "}
                      {job.requisition_id && `(${job.requisition_id})`}
                    </Typography>
                  ))}
                </Stack>
              </>
            ) : (
              <>
                <Typography
                  fontSize="0.875rem"
                  lineHeight="1.25rem"
                  fontWeight={400}
                  color={theme.palette.grey[500]}
                >
                  {t("editCriteria.modals.save.wouldLikeToSave")}
                </Typography>
                <Typography
                  fontSize="0.875rem"
                  lineHeight="1.25rem"
                  fontWeight={400}
                  color={theme.palette.grey[500]}
                >
                  {t("editCriteria.modals.save.noOpenRolesAffected")}
                </Typography>
              </>
            )}
          </Stack>
        );
      } catch (e) {
        return <></>;
      }
    }
    return <></>;
  };

  const fetchDepartments = async () => {
    try {
      const response = await listDepartmentsFromAts(orgId);
      setDepartments(response.departments);
    } catch (error) {
      console.log("fetching departments failed", error);
    }
  };

  const handleCriteriaAnalytics = (
    event: RoleMatchingCriteriaEvent,
    oldCriteria: RoleMatchingCriteriaDTO | null,
    newCriteria: RoleMatchingCriteriaDTO | null,
  ) => {
    trackGoogleAnalyticsEvent({
      event: event,
      org: user?.orgName,
    });

    if (!newCriteria) return; // Delete criteria nothing more to track

    var isApplyToExistingRoles: boolean | null = null;
    var isAutomated: boolean | null = null;
    var isEnabled: boolean | null = null;
    var isApplyToAllRolesInDepartment: boolean | null = null;
    var totalTenure: TenureMatchingCriteria | null = null;

    if (oldCriteria) {
      // Edit criteria
      if (
        oldCriteria.apply_to_existing_roles !=
        newCriteria.apply_to_existing_roles
      ) {
        isApplyToExistingRoles = newCriteria.apply_to_existing_roles;
      }

      if (oldCriteria.is_automated != newCriteria.is_automated) {
        isAutomated = newCriteria.is_automated;
      }

      if (oldCriteria.is_enabled != newCriteria.is_enabled) {
        isEnabled = newCriteria.is_enabled;
      }

      if (
        oldCriteria.apply_to_all_roles_in_department !=
        newCriteria.apply_to_all_roles_in_department
      ) {
        isApplyToAllRolesInDepartment =
          newCriteria.apply_to_all_roles_in_department;
      }

      if (oldCriteria.total_tenure != newCriteria.total_tenure) {
        totalTenure = newCriteria.total_tenure;
      }

      if (oldCriteria.name != newCriteria.name) {
        trackGoogleAnalyticsEvent({
          event: RoleMatchingCriteriaEvent.NAME_CHANGED,
          org: user?.orgName,
        });
      }

      if (oldCriteria.department?.id != newCriteria.department?.id) {
        trackGoogleAnalyticsEvent({
          event: RoleMatchingCriteriaEvent.DEPARTMENT_CHANGED,
          org: user?.orgName,
        });
      }

      if (areArraysEqual(oldCriteria.job_titles, newCriteria.job_titles)) {
        trackGoogleAnalyticsEvent({
          event: RoleMatchingCriteriaEvent.ROLE_CHANGED,
          org: user?.orgName,
        });
      }
    } else {
      // New criteria
      isApplyToExistingRoles = newCriteria.apply_to_existing_roles;
      isAutomated = newCriteria.is_automated;
      isEnabled = newCriteria.is_enabled;
      isApplyToAllRolesInDepartment =
        newCriteria.apply_to_all_roles_in_department;
      totalTenure = newCriteria.total_tenure;
    }

    if (isApplyToExistingRoles != null) {
      trackGoogleAnalyticsEvent({
        event: newCriteria.apply_to_existing_roles
          ? RoleMatchingCriteriaEvent.APPLY_TO_NEW_AND_EXISTING_ROLES
          : RoleMatchingCriteriaEvent.APPLY_TO_ONLY_NEW_ROLES,
        org: user?.orgName,
      });
    }

    if (isAutomated != null) {
      trackGoogleAnalyticsEvent({
        event: newCriteria.is_automated
          ? RoleMatchingCriteriaEvent.IS_AUTOMATED
          : RoleMatchingCriteriaEvent.IS_MANUAL,
        org: user?.orgName,
      });
    }

    if (isEnabled != null) {
      trackGoogleAnalyticsEvent({
        event: newCriteria.is_enabled
          ? RoleMatchingCriteriaEvent.IS_ENABLED
          : RoleMatchingCriteriaEvent.IS_DISABLED,
        org: user?.orgName,
      });
    }

    if (isApplyToAllRolesInDepartment != null) {
      trackGoogleAnalyticsEvent({
        event: newCriteria.apply_to_all_roles_in_department
          ? RoleMatchingCriteriaEvent.USE_ALL_ROLES
          : RoleMatchingCriteriaEvent.USE_SPECIFIC_ROLES,
        org: user?.orgName,
      });
    }

    if (totalTenure != null) {
      trackGoogleAnalyticsEvent({
        event:
          newCriteria.total_tenure == TenureMatchingCriteria.FAVOR_HIGHER_TENURE
            ? RoleMatchingCriteriaEvent.FAVOR_HIGHER_TENURE
            : newCriteria.total_tenure ==
              TenureMatchingCriteria.FAVOR_LOWER_TENURE
            ? RoleMatchingCriteriaEvent.FAVOR_LOWER_TENURE
            : RoleMatchingCriteriaEvent.DISREGARD_TENURE,
        org: user?.orgName,
      });
    }
  };

  return (
    <Stack>
      <Stack padding="2rem">
        <ErrorToastContainer containerId={toastContainerIds.error} />
        <SuccessToastContainer containerId={toastContainerIds.success} />
        <InfoToastContainer containerId={toastContainerIds.info} />
        <Header title={t("roleMatching.title")} showBorder={false} />
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="role matching tabs"
            TabIndicatorProps={{
              style: {
                backgroundColor: theme.palette.primary.main,
              },
            }}
          >
            <Tab
              label={t("matchingCriteria")}
              className="matching-criteria-tab"
              disableRipple
              sx={(theme) => ({
                fontSize: "14px",
                fontWeight: 500,
                color: theme.palette.grey[500],
                "&.Mui-selected": {
                  color: theme.palette.grey[800],
                },
              })}
            />
            <Tab
              label={t("emailSettings.title")}
              className="email-settings-tab"
              disableRipple
              sx={(theme) => ({
                fontSize: "14px",
                fontWeight: 500,
                color: theme.palette.grey[500],
                "&.Mui-selected": {
                  color: theme.palette.grey[800],
                },
              })}
            />
          </Tabs>
        </Box>

        {tabValue === 0 && (
          <Stack paddingTop="1.5rem">
            <Typography
              fontSize="1.125rem"
              lineHeight="1.75rem"
              fontWeight={600}
              paddingBottom=".25rem"
            >
              {t("roleMatching.heading")}
            </Typography>
            <Typography
              color={theme.palette.grey[500]}
              fontSize="0.875rem"
              fontWeight={400}
              lineHeight="1.25rem"
              paddingBottom="1.25rem"
            >
              {t("roleMatching.subHeading")}
            </Typography>
            {isLoading && <Loading />}
            {isError && <EmptyResult text={t("loadingError")} />}
            {!isLoading && !isError && (
              <RoleMatchingCriteriaList
                criteriaList={criteriaList}
                globalCriteriaList={globalCriteriaList}
                onEdit={onEditClick}
                onDelete={(criteria) => {
                  trackGoogleAnalyticsEvent({
                    event: RoleMatchingCriteriaEvent.DELETE_CRITERIA_CLICK,
                    org: user?.orgName,
                  });
                  setCriteriaToDelete(criteria);
                }}
                onToggle={(criteria, newValue) => {
                  void setCriteriaEnabled(criteria, newValue);
                }}
                onCreateNew={onCreateNewClick}
              />
            )}
          </Stack>
        )}
        {tabValue === 1 && (
          <EmailSettings
            emailFrequencyStr={emailFrequencyStr}
            emailFrequencyType={emailFrequencyType}
            isEmailFrequencyError={isEmailFrequencyError}
            handleFrequencyTypeChange={handleFrequencyTypeChange}
            updateFrequencyAmount={updateFrequencyAmount}
            saveFrequencyChanges={saveMatchingFrequency}
            discardModalOpen={discardModalOpen}
            discardChanges={discardChanges}
            copy={{
              explanation: t("roleMatching.emailSettings.explanation"),
              recieverEmailDescription: t(
                "roleMatching.emailSettings.recieverEmailDescription",
              ),
              emailCadenceExplanation: t(
                "roleMatching.emailSettings.emailCadenceExplanation",
              ),
            }}
          />
        )}

        <EditCriteria
          heading={t("roleMatching.editHeading")}
          criteriaToEdit={criteriaToEdit}
          setCriteriaToEdit={setCriteriaToEdit}
          closeEditWindow={closeEditWindow}
          onSubmit={updateCriteria}
          criteriaList={criteriaList}
          toastContainerIds={toastContainerIds}
          getModalInfo={() => fetchImpactedRolesElement(criteriaToEdit)}
          onDelete={deleteCriteria}
          departments={departments}
        />

        <EditCriteria
          heading={t("roleMatching.newCriteriaHeading")}
          drawerId="new-criteria-drawer"
          criteriaToEdit={newCriteria}
          setCriteriaToEdit={setNewCriteria}
          closeEditWindow={closeEditWindow}
          onSubmit={updateCriteria}
          criteriaList={criteriaList}
          toastContainerIds={toastContainerIds}
          getModalInfo={() => fetchImpactedRolesElement(newCriteria)}
          departments={departments}
        />

        <ConfirmActionModal
          open={criteriaToDelete !== null}
          primaryButtonHandler={() => {
            handleCriteriaAnalytics(
              RoleMatchingCriteriaEvent.DELETE_CRITERIA_SAVE,
              criteriaToDelete,
              null,
            );
            void deleteCriteria(criteriaToDelete);
            setCriteriaToDelete(null);
          }}
          secondaryButtonHandler={() => {
            setCriteriaToDelete(null);
          }}
          primaryButtonBgColor={theme.palette.error.main}
          primaryButtonHoverBgColor={theme.palette.error.dark}
          heading={t(`editCriteria.modals.delete.heading`)}
          subHeading={t(`editCriteria.modals.delete.subHeading`)}
          primaryButtonText={t(`editCriteria.modals.delete.primaryButton`)}
          secondaryButtonText={t(`editCriteria.modals.delete.secondaryButton`)}
        />
      </Stack>
      {updatingOrCreatingCriteria && (
        <div
          style={{
            background: "rgba(0, 0, 0, 0.2)",
            position: "absolute",
            width: "100%",
            height: "100%",
            alignContent: "center",
            top: 0,
            left: 0,
            zIndex: "1501", //the highest z-index is 1500 for mui components https://mui.com/material-ui/customization/z-index/
          }}
        >
          <Loading />
        </div>
      )}
    </Stack>
  );
}
