import { useTranslation } from "react-i18next";
import {
  ChangeEvent,
  SyntheticEvent,
  useState,
  useEffect,
  useContext,
  useMemo,
} from "react";
import { Box, Typography, Stack, Tab, Tabs } from "@mui/material";
import HelpCircleIcon from "/help-circle-secondary.svg";
import theme from "../../theme";
import Tooltip from "../../theme/Tooltip";
import Input from "../../theme/Input";
import MultiSelectComboBox, {
  MultiSelectOption,
} from "../../components/MultiSelectComboBox";
import { getEmailTemplate } from "../../api/resource";
import { WarningBox } from "../WarningBox";
import { Translation } from "../../lib/constants";
import { sendApprovalEmail, renderApprovalEmail, ApprovalEmailContent } from "../../api/email";
import parse from "html-react-parser";
import { UserContext } from "../../lib/context";
import Loading from "../../components/Loading";
import Switch from "../../theme/Switch";
import { JobMatchStatus } from "../../models/job";
import ErrorToastContainer from "../../components/Toastify/ErrorToastContainer";
import { toast } from "react-toastify";
import ModalTemplate from "./ModalTemplate";
import ConfirmActionModal from "../ConfirmActionModal";
import { EmailTemplateTypeEnum } from "../../models/api/resource";
import { hasEmptyStringValues, validateEmail } from "../../lib/utils";
import { JobDashboardDTO } from "../../models/api/job";

export interface Receiver {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  status?: JobMatchStatus;
}

interface Props {
  onSubmit: () => void;
  onClose: () => void;
  receivers: Receiver[];
  selectedIds: Set<string>;
  job: JobDashboardDTO;
}

export default function RequestFeedbackModal({
  onSubmit,
  onClose,
  receivers,
  selectedIds,
  job,
}: Props) {
  const { t } = useTranslation(Translation.sendEmailModal);
  const [tab, setTab] = useState(0);
  const [user] = useContext(UserContext);
  const orgId = user?.orgId ?? "";
  const [sendModalOpen, setSendModalOpen] = useState(false);
  const [emailPreview, setEmailPreview] = useState("");
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [loadingDraft, setLoadingDraft] = useState(false);
  const [loadingSendApproval, setLoadingSendApproval] = useState(false);
  const [emailContent, setEmailContent] = useState<ApprovalEmailContent>({
    body: "",
    subject: "",
    emails: "",
    includeSignature: false,
  });
  const [defaultContent, setDefaultContent] = useState<{
    subject: string;
    body: string;
  }>({
    body: "",
    subject: "",
  });
  const [error, setError] = useState({
    subject: "",
    body: "",
    emails: "",
  });


  const translateReceiverToMultiSelectOption = (receiver: Receiver) => {
    const hasEmail = receiver.email.length > 0;
    const isHired = receiver.status === JobMatchStatus.HIRED;

    let label = `${receiver.firstName} ${receiver.lastName}`;
    if (!hasEmail) {
      label += ` ${t("noEmailAddress")}`;
    } else if (isHired) {
      label += ` ${t("hired")}`;
    }

    return {
      value: receiver.id,
      label: label,
      selectable: hasEmail && !isHired,
      dropdownLabelDetails: hasEmail ? receiver.email : undefined,
    };
  };

  const [multiSelectOptions, _] = useState<MultiSelectOption[]>(
    receivers.map(translateReceiverToMultiSelectOption),
  );

  const { validReceivers, invalidReceivers } = receivers.reduce(
    (acc, receiver) => {
      if (selectedIds.has(receiver.id)) {
        receiver.email.length > 0
          ? acc.validReceivers.push(receiver)
          : acc.invalidReceivers.push(receiver);
      }
      return acc;
    },
    { validReceivers: [] as Receiver[], invalidReceivers: [] as Receiver[] },
  );

  const validSelectedIds = useMemo(
    () => new Set(validReceivers.map((receiver) => receiver.id)),
    [validReceivers],
  );

  const [newSelectedIds, setNewSelectedIds] =
    useState<Set<string>>(validSelectedIds);

  const attemptSend = async () => {
    if (
      newSelectedIds.size === 0 ||
      hasEmptyStringValues(emailContent) ||
      error.emails !== ""
    ) {
      toast.error(t("toast.emptyFields"), {
        containerId: "request-feedback-error",
        toastId: "empty-fields",
      });
      return
    }

    setSendModalOpen(true);
  };

  const submitApprovalRequest = async () => {
    try {
      setLoadingSendApproval(true);

      await sendApprovalEmail(
        orgId,
        emailContent,
        job.id,
        Array.from(newSelectedIds),
      );

      setLoadingSendApproval(false);
      setSendModalOpen(false);
      onSubmit();
    } catch (error) {
      setLoadingSendApproval(false);
      setSendModalOpen(false);
      toast.error(t("toast.sendError"), {
        containerId: "request-feedback-error",
        toastId: "send-error",
      });

    }
  };

  const handleFormChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let errorText = value.length == 0 ? t(`error.${name}`) : "";

    if (name === "emails" && value.length > 0) {
      let allEmailsValid = emailContent.emails.split(",").map((it) => it.trim()).every(validateEmail);
      if (!allEmailsValid) {
        errorText = t("error.emailFormat");
      }
    }
    
    setError({
      ...error,
      [name]: errorText,
    });
    setEmailContent({
      ...emailContent,
      [name]: value,
    });
  };

  useEffect(() => {
    const fetchEmailTemplate = async () => {
      setLoadingDraft(true);
      try {
        const response = await getEmailTemplate(
          orgId,
          EmailTemplateTypeEnum.JOB_MATCH_APPROVAL,
        );
        setDefaultContent({
          body: response.body,
          subject: response.subject,
        });
      } catch (error) {
        setDefaultContent({
          body: t("backupContent.requestFeedback.body"),
          subject: t("backupContent.requestFeedback.subject"),
        });
      } finally {
        setLoadingDraft(false);
      }
    };

    void fetchEmailTemplate();
  }, [orgId, t]);

  useEffect(() => {
    const fetchEmailPreview = async () => {
      setLoadingPreview(true);
      try {
        const response = await renderApprovalEmail(
          orgId,
          emailContent,
          job.id,
        );
        setEmailPreview(response);
      } catch (error) {
        // TODO: BOO-1036
      } finally {
        setLoadingPreview(false);
      }
    };

    if (tab == 1) {
      void fetchEmailPreview();
    }
  }, [orgId, tab]);

  useEffect(() => {
    setEmailContent({
      body: defaultContent.body,
      subject: defaultContent.subject,
      emails: "",
      includeSignature: true,
    });
  }, [defaultContent]);

  return (
    <>
      <ErrorToastContainer
        containerId="request-feedback-error"
        style={{ width: "652px" }}
      />
      <ModalTemplate
        heading={t("heading.approval")}
        onClose={() => onClose()}
        primaryButtonOnClick={attemptSend}
        primaryButtonText={t("send")}
        subheading={t("subHeading.approval")}
      >
        <>
          <Stack
            direction="column"
            justifyContent="space-between"
            padding="0rem 1.5rem"
          >
            <Box
              sx={(theme) => ({
                borderBottom: `1px solid ${theme.palette.grey[200]}`,
              })}
            >
              <Tabs
                textColor="inherit"
                value={tab}
                onChange={(_event: SyntheticEvent, newValue: number) =>
                  setTab(newValue)
                }
                TabIndicatorProps={{
                  style: {
                    backgroundColor: theme.palette.primary.main,
                  },
                }}
              >
                <Tab
                  id="send-email-draft"
                  disableRipple
                  sx={{
                    minWidth: "2.5rem",
                    maxWidth: "2.5rem",
                    fontSize: "0.9rem",
                    fontWeight: "500",
                  }}
                  label={t("draft")}
                />
                <Tab
                  id="send-email-preview"
                  disableRipple
                  sx={{
                    marginLeft: "1rem",
                    minWidth: "3.5rem",
                    maxWidth: "3.5rem",
                    fontSize: "0.9rem",
                    fontWeight: "500",
                  }}
                  label={t("preview")}
                />
              </Tabs>
            </Box>
          </Stack>
          <Box
            sx={(theme) => ({
              borderBottom: `1px solid ${theme.palette.grey[200]}`,
              overflow: "auto",
            })}
          >
            {tab == 0 && (
              <Stack id="feedback-email-form" direction="column" padding="1.5rem 2rem 2rem 2rem">
                {loadingDraft ? (
                  <Loading />
                ) : (
                  <>
                    {invalidReceivers.length > 0 && (
                      <WarningBox
                        alumsWithoutEmail={invalidReceivers}
                        warningTextUnderline={t(
                          "missingEmailWarningUnderlined",
                        )}
                        warningTextRemaining={t("missingEmailWarning")}
                      />
                    )}

                    <div>
                    <Input
                      id="to-section"
                      label={t("toTooltip.label")}
                      tooltipText={t("toTooltip.content")}
                      placeholder=""
                      required={true}
                      error={error.emails}
                      onChange={handleFormChange}
                      stackProps={{
                        paddingTop: "1.125rem",
                      }}
                      inputProps={{
                        id: "to-section-text",
                        name: "emails",
                        value: emailContent.emails,
                        sx: {
                          borderRadius: "0.5rem",
                          "& .MuiOutlinedInput-input": {
                            fontSize: "0.875rem",
                            padding: "10px 14px",
                            lineHeight: "1.5rem",
                            fontWeight: "400",
                            color: "grey.800",
                          },
                          fieldset: {
                            borderColor:
                              theme.palette.grey[300] + " !important",
                          },
                          "&.Mui-focused": {
                            fieldset: {
                              borderColor:
                                theme.palette.primary.dark + " !important",
                            },
                          },
                          "&.Mui-error": {
                            fieldset: {
                              borderColor:
                                theme.palette.custom.errorBorder +
                                " !important",
                            },
                          },
                        },
                      }}
                    />

                    <Typography
                      fontSize="14px"
                      fontWeight={400}
                      lineHeight="20px"
                      sx={(theme) => ({
                        color: theme.palette.grey[500],
                        paddingTop: "6px",
                      })}
                    >
                      {t("toTooltip.hint")}
                    </Typography>
                    </div>

                    <ModalInputLabel
                      labelText={t("matchesTooltip.label")}
                      tooltipContent={t("matchesTooltip.content")}
                    />

                    <MultiSelectComboBox
                      options={multiSelectOptions}
                      selectedValues={newSelectedIds}
                      setSelectedValues={setNewSelectedIds}
                      selectAllLabel={t("selectAll")}
                      inputErrorMessage={t("error.recipients")}
                      showChevron={true}
                      readOnly={false}
                      matchFromStartFilter
                    />

                    <Input
                      id="subject-section"
                      label={t("subject")}
                      placeholder=""
                      required={true}
                      error={error.subject}
                      onChange={handleFormChange}
                      stackProps={{
                        paddingTop: "1.125rem",
                      }}
                      inputProps={{
                        id: "subject-section-text",
                        name: "subject",
                        value: emailContent.subject,
                        sx: {
                          borderRadius: "0.5rem",
                          "& .MuiOutlinedInput-input": {
                            fontSize: "0.875rem",
                            padding: "10px 14px",
                            lineHeight: "1.5rem",
                            fontWeight: "400",
                            color: "grey.800",
                          },
                          fieldset: {
                            borderColor:
                              theme.palette.grey[300] + " !important",
                          },
                          "&.Mui-focused": {
                            fieldset: {
                              borderColor:
                                theme.palette.primary.dark + " !important",
                            },
                          },
                          "&.Mui-error": {
                            fieldset: {
                              borderColor:
                                theme.palette.custom.errorBorder +
                                " !important",
                            },
                          },
                        },
                      }}
                    />

                    <Input
                      id="body-section"
                      label={t("body")}
                      required={true}
                      placeholder=""
                      onChange={handleFormChange}
                      error={error.body}
                      stackProps={{ paddingTop: "1.125rem" }}
                      inputProps={{
                        id: "body-section-text",
                        name: "body",
                        multiline: true,
                        value: emailContent.body,
                        rows: 10.75,
                        sx: {
                          borderRadius: "0.5rem",
                          "& .MuiOutlinedInput-input": {
                            fontSize: "0.875rem",
                            lineHeight: "1.5rem",
                            fontWeight: "400",
                            color: "grey.800",
                          },
                          fieldset: {
                            borderColor:
                              theme.palette.grey[300] + " !important",
                          },
                          "&.Mui-focused": {
                            fieldset: {
                              borderColor:
                                theme.palette.primary.dark + " !important",
                            },
                          },
                          "&.Mui-error": {
                            fieldset: {
                              borderColor:
                                theme.palette.custom.errorBorder +
                                " !important",
                            },
                          },
                        },
                      }}
                    />

                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      paddingTop="1.125rem"
                    >
                      <Switch
                        className="include-sig-switch"
                        switchsize="large"
                        checked={emailContent.includeSignature}
                        onChange={() =>
                          setEmailContent({
                            ...emailContent,
                            includeSignature: !emailContent.includeSignature,
                          })
                        }
                      />
                      <Typography
                        fontSize="0.875rem"
                        fontWeight={600}
                        lineHeight="1.25rem"
                        paddingLeft="12px"
                      >
                        {t("includeSignature")}
                      </Typography>
                    </Stack>
                  </>
                )}
              </Stack>
            )}
            {tab == 1 && (
              <Stack
                id="email-preview"
                sx={{
                  minHeight: "500px",
                  padding: "0px 2px",
                  "& .alumni-email-body": {
                    maxWidth: "100% !important",
                  },
                }}
              >
                {loadingPreview ? <Loading /> : parse(emailPreview)}
              </Stack>
            )}
          </Box>
        </>
      </ModalTemplate>
      <ConfirmActionModal
        open={sendModalOpen}
        primaryButtonHandler={() => submitApprovalRequest()}
        secondaryButtonHandler={() => setSendModalOpen(false)}
        primaryButtonBgColor={theme.palette.primary.main}
        primaryButtonHoverBgColor={theme.palette.custom.purpleHover}
        heading={t("readyToSend.header")}
        subHeading={t("readyToSend.warning")}
        primaryButtonText={t("readyToSend.button")}
        secondaryButtonText={t("cancel")}
        isLoading={loadingSendApproval}
      />
    </>
  );
}

const ModalInputLabel = ({
  labelText,
  tooltipContent,
}: {
  labelText: string;
  tooltipContent: string;
}) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      paddingBottom="0.375rem"
      paddingTop="1.125rem"
    >
      <Stack direction="row" alignItems="center" className="to-section">
        <Typography
          fontSize="0.875rem"
          fontWeight={500}
          color={theme.palette.grey[800]}
          lineHeight="1.25rem"
        >
          {labelText}
        </Typography>

        <Tooltip
          arrow={false}
          title={tooltipContent}
          className="tooltip"
          componentsProps={{
            tooltip: {
              sx: {
                padding: "0.75rem",
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
                borderRadius: "0.5rem",
                bgcolor: "white",
                color: "grey.500",
                fontSize: "0.75rem",
                fontWeight: "500",
                lineHeight: "1.125rem",
                boxShadow: "0px 0.5rem 0.5rem #00000034",
              },
            },
          }}
        >
          <Box component="img" src={HelpCircleIcon} paddingLeft="0.25rem" />
        </Tooltip>
      </Stack>
    </Stack>
  );
};
