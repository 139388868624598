export type ListCriteriaResponse<T> = {
  criteria: T[];
  total: number;
};

export type MatchingCriteriaI = {
  id: string;
  name: string;
  is_enabled: boolean;
  last_updated: string;
};

export type RoleMatchingCriteriaDTO = {
  time_since_departure: number;
  proximity_to_role: number;
  apply_to_existing_roles: boolean;
  total_tenure: TenureMatchingCriteria;
  is_automated: boolean;
  is_baseline: boolean;
  department: DepartmentDTO | null;
  job_titles: string[];
  apply_to_all_roles_in_department: boolean;
} & MatchingCriteriaI;

export type CriteriaEmailResponseDTO = {
  frequency: number;
  frequency_unit: string;
  sender_email: string;
  reciever_email: string | null;
};

export enum TenureMatchingCriteria {
  FAVOR_HIGHER_TENURE = "FAVOR_HIGHER_TENURE",
  FAVOR_LOWER_TENURE = "FAVOR_LOWER_TENURE",
  DISREGARD_TENURE = "DISREGARD_TENURE",
}

export type ReferralMatchingCriteriaDTO = {
  time_since_departure: number;
  proximity_to_role: number | null;
  match_to_same_department: boolean;
} & MatchingCriteriaI;

export type SalesPartnershipsCriteriaDTO = {
  receiver_email: string;
  interests: {
    roles: string[];
    companies: string[];
  };
} & MatchingCriteriaI;

export type MatchingCriteria =
  | RoleMatchingCriteriaDTO
  | ReferralMatchingCriteriaDTO
  | SalesPartnershipsCriteriaDTO;

export type AllCriteriaFields = RoleMatchingCriteriaDTO &
  ReferralMatchingCriteriaDTO &
  SalesPartnershipsCriteriaDTO;

export type DepartmentDTO = {
  id: string;
  name: string;
  has_criteria_applied_to_all_roles: boolean;
  has_criteria_applied_to_specific_roles: boolean;
};

export type ListDepartmentsFromAtsResponse = {
  departments: DepartmentDTO[];
};
