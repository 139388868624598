import {
  ReferralMatchingCriteriaDTO,
  RoleMatchingCriteriaDTO,
  SalesPartnershipsCriteriaDTO,
  TenureMatchingCriteria,
} from "./api/criteria";

// @ts-ignore
export const REFERRAL_MATCHING_CRITERIA_DEFAULT_VALUES: Record<string, any> = {
  match_to_same_department: true,
  time_since_departure: 0,
  proximity_to_role: 50.0,
};

// @ts-ignore
export const ROLE_MATCHING_CRITERIA_DEFAULT_VALUES: Record<string, any> = {
  name: "",
  is_enabled: true,
  last_updated: "",
  time_since_departure: 0,
  proximity_to_role: 10.0,
  apply_to_existing_roles: true,
  total_tenure: TenureMatchingCriteria.FAVOR_HIGHER_TENURE,
  is_automated: false,
  is_baseline: false,
  department: null,
  job_titles: [],
  apply_to_all_roles_in_department: true,
};

export function resetToDefault<T>(criteria: any): T {
  const res = { ...criteria };
  if (isRoleMatching(res)) {
    res.apply_to_existing_roles =
      ROLE_MATCHING_CRITERIA_DEFAULT_VALUES.apply_to_existing_roles;
    res.time_since_departure =
      ROLE_MATCHING_CRITERIA_DEFAULT_VALUES.time_since_departure;
    res.proximity_to_role =
      ROLE_MATCHING_CRITERIA_DEFAULT_VALUES.proximity_to_role;
    res.total_tenure = ROLE_MATCHING_CRITERIA_DEFAULT_VALUES.total_tenure;
    res.is_automated = ROLE_MATCHING_CRITERIA_DEFAULT_VALUES.is_automated;
    res.department = ROLE_MATCHING_CRITERIA_DEFAULT_VALUES.department;
    res.job_titles = ROLE_MATCHING_CRITERIA_DEFAULT_VALUES.job_titles;
    res.name = ROLE_MATCHING_CRITERIA_DEFAULT_VALUES.name;
  } else if (isReferralMatching(res)) {
    res.match_to_same_department =
      REFERRAL_MATCHING_CRITERIA_DEFAULT_VALUES.match_to_same_department;
    res.time_since_departure =
      REFERRAL_MATCHING_CRITERIA_DEFAULT_VALUES.time_since_departure;
    res.proximity_to_role =
      REFERRAL_MATCHING_CRITERIA_DEFAULT_VALUES.proximity_to_role;
  } else if (isSnPMatching(res)) {
    res.interests = { companies: [], roles: [] };
  }
  return res;
}

export function isRoleMatching(
  criteria: any,
): criteria is RoleMatchingCriteriaDTO {
  return (
    !(criteria == null) &&
    (criteria as RoleMatchingCriteriaDTO).total_tenure !== undefined
  );
}

export function isReferralMatching(
  criteria: any,
): criteria is ReferralMatchingCriteriaDTO {
  return (
    !(criteria == null) &&
    (criteria as ReferralMatchingCriteriaDTO).match_to_same_department !==
      undefined
  );
}

export function isSnPMatching(
  criteria: any,
): criteria is SalesPartnershipsCriteriaDTO {
  return (
    !(criteria == null) &&
    (criteria as SalesPartnershipsCriteriaDTO).interests !== undefined
  );
}

export function isSnPMatchingList(
  criteriaList: any,
): criteriaList is SalesPartnershipsCriteriaDTO[] {
  return !(criteriaList == null);
}

export function isRoleMatchingList(
  criteriaList: any,
): criteriaList is RoleMatchingCriteriaDTO[] {
  return !(criteriaList == null);
}
