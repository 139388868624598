import { useTranslation } from "react-i18next";
import theme from "../../theme";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import briefcaseIcon from "/briefcase-light.svg";
import hcmCheck from "/check-verified-01.svg";
import {
  getEmploymentTypeDisplay,
  JobHistoryDTO,
  JobHistorySourceEnum,
} from "../../models/api/alum";
import { Translation } from "../../lib/constants";
import { formatUTCMonthAndYear } from "../../lib/time";
import { FormattedText, FormattedTextType } from "../../theme/FormattedText";
import { Format } from "../../lib/utils";
import { TooltipWhite } from "../../theme/Tooltip";

interface RoleHistoryRowProps {
  job: JobHistoryDTO;
  showOrgLogo: boolean;
  showTimeline: boolean;
  isLastKnownRole: boolean;
}

export default function RoleHistoryRowV2({
  job,
  showOrgLogo,
  showTimeline,
}: RoleHistoryRowProps) {
  const { t, i18n } = useTranslation(Translation.alumProfile);
  const common = useTranslation(Translation.common)[0];

  const startDate =
    job.start_date && formatUTCMonthAndYear(job.start_date, i18n);
  const endDate = job.end_date && formatUTCMonthAndYear(job.end_date, i18n);

  let dateString = "";
  if (startDate && endDate) {
    dateString = `${startDate} - ${endDate}`;
  } else if (startDate && !endDate) {
    dateString = `${startDate} - ${t("roleHistory.present")}`;
  } else if (!startDate && endDate) {
    dateString = `${t("roleHistory.unspecified")} - ${endDate}`;
  } else {
    dateString = t("roleHistory.unspecified");
  }

  const managerName = job.manager
    ? `${job.manager.first_name} ${job.manager.last_name}`
    : null;

  const companyAndEmploymentType = [
    job.org_name,
    getEmploymentTypeDisplay(job.employment_type, common),
  ].filter((name) => name != null && name.trim() !== "");

  const showVerifiedIcon =
    job.source === JobHistorySourceEnum.HRIS && job.is_from_requesting_org;

  return (
    <Stack direction="row" gap="1rem" width="100%">
      <Stack width="2rem" gap="4px" alignItems="center">
        {showOrgLogo ? (
          <Box
            width="2rem"
            height="2rem"
            borderRadius="50%"
            overflow="hidden"
            display="grid"
            sx={{
              placeItems: "center",
              backgroundColor: theme.palette.grey[100],
            }}
          >
            <Box
              component="img"
              alt={t("roleHistory.orgLogoAlt")}
              src={job.org_logo_url ?? briefcaseIcon}
              width="1.25rem"
              height="1.25rem"
            />
          </Box>
        ) : (
          <Box padding="0.5rem">
            <Box
              width="0.5rem"
              height="0.5rem"
              borderRadius="50%"
              sx={{
                background: theme.palette.grey[300],
              }}
            />
          </Box>
        )}
        {showTimeline && (
          <Box
            margin="0.25rem auto"
            width="0.125rem"
            height="calc(100% - 2rem)"
            borderRadius="0.125rem"
            sx={{
              background: theme.palette.grey[200],
            }}
          />
        )}
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        flexGrow={1}
        marginBottom="20px"
      >
        <Stack gap="0.25rem" width="60%">
          <FormattedText
            props={{
              value: job.title,
              format: Format.uppercaseFirstLetter,
              ellipsis: false,
            }}
            style={{ style: { overflowWrap: "break-word" }, fontWeight: 600 }}
          />

          {companyAndEmploymentType && (
            <FormattedText
              props={{
                value: companyAndEmploymentType.join(" • "),
                format: Format.uppercaseFirstLetter,
                type: FormattedTextType.PRIMARY,
              }}
            />
          )}
          {job.location && (
            <FormattedText
              props={{
                value: job.location,
                format: Format.location,
                type: FormattedTextType.SECONDARY,
              }}
            />
          )}
          {job.department && (
            <FormattedText
              props={{
                value: `${t("roleHistory.departmentPrefix")} ${job.department}`,
                format: Format.uppercaseFirstLetter,
                type: FormattedTextType.SECONDARY,
              }}
            />
          )}
          {managerName && (
            <FormattedText
              props={{
                value: `${t("roleHistory.managerPrefix")} ${managerName}`,
                format: Format.uppercaseFirstLetter,
                type: FormattedTextType.SECONDARY,
              }}
            />
          )}
        </Stack>

        <Stack
          justifyContent="space-between"
          alignItems="end"
          height="100%"
          width="40%"
        >
          <FormattedText
            props={{
              value: dateString,
              type: FormattedTextType.SECONDARY,
            }}
          />

          {showVerifiedIcon ? (
            <TooltipWhite
              className=""
              arrow={false}
              title={t("roleHistory.hcmTooltip")}
            >
              <Stack
                direction="row"
                gap="4px"
                alignItems="center"
                paddingX="6px"
                sx={{
                  backgroundColor: theme.palette.primaryColor[50],
                  borderRadius: "6px",
                  border: `1px solid ${theme.palette.primaryColor[200]}`,
                  padding: "0.125rem 0.375rem",
                  width: "max-content",
                  marginTop: "0.25rem",
                }}
              >
                <Typography
                  color={theme.palette.primaryColor[600]}
                  fontSize="12px"
                  fontWeight="400"
                  lineHeight="18px"
                >
                  {t("roleHistory.hcm")}
                </Typography>
                <Box
                  component="img"
                  src={hcmCheck}
                  alt={t("roleHistory.verifiedIconAlt")}
                  width="12px"
                  height="12px"
                  color={theme.palette.primaryColor[600]}
                />
              </Stack>
            </TooltipWhite>
          ) : (
            <TooltipWhite
              className=""
              arrow={false}
              title={t("roleHistory.externalTooltip")}
            >
              <Stack
                direction="row"
                gap="4px"
                alignItems="center"
                paddingX="6px"
                sx={{
                  backgroundColor: theme.palette.grey[50],
                  borderRadius: "6px",
                  border: `1px solid ${theme.palette.grey[200]}`,
                  padding: "0.125rem 0.375rem",
                  width: "max-content",
                  marginTop: "0.25rem",
                }}
              >
                <Typography
                  color={theme.palette.grey[800]}
                  fontSize="0.75rem"
                  lineHeight="1.125rem"
                >
                  {job.source === JobHistorySourceEnum.ALUMNI_CLOUD
                    ? t("roleHistory.alumniCloud")
                    : t("roleHistory.external")}
                </Typography>
              </Stack>
            </TooltipWhite>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
