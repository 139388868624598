export type AuthUser = {
  email: string;
  firstName: string | null;
  lastName: string | null;
  orgId: string;
  orgName: string; 
};

export type OidcClientConfig = {
  authority: string;
  client_id: string;
  scope: string;
  redirect_uri: string;
}

export enum MessageType {
  ERROR = "error",
  SUCCESS = "success",
}