import { http } from ".";
import { capitalizeFirstLetter, titleCase } from "../lib/utils";
import {
  AlumProfileAutocompleteResponse,
  AlumProfileAutocompleteByNameResponse,
  AlumDTO,
  ListAlumniResponse,
  JobMatchApprovalResponse,
} from "../models/api/alum";
import { HttpError, HttpResponse, ResponseStatus } from "../models/api/http";
import { AlumniDbFilterOptions } from "../models/filter";

export const getAlum = async (
  orgId: string,
  alumId: string,
): Promise<AlumDTO> => {
  const response = await http.get<HttpResponse<AlumDTO>>(
    `/api/org/${orgId}/profile/alumni/${alumId}?federate_employments=true`,
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }

  return captializeNameInAlumDTO(response.data.data);
};

export const listAlumni = async (
  orgId: string,
  page: number,
  pageSize: number,
  sortOption: string,
  sortDesc: boolean,
  searchTerm: string,
  filters: AlumniDbFilterOptions,
): Promise<ListAlumniResponse> => {
  const response = await http.post<HttpResponse<ListAlumniResponse>>(
    `/api/org/${orgId}/profile/alumni/list?page=${page}&page_size=${pageSize}&sort_option=${sortOption}&sort_desc=${sortDesc}&search_term=${encodeURIComponent(
      searchTerm,
    )}`,
    transformFiltersForBackend(filters),
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  
  response.data.data.alumni.forEach(captializeNameInAlumDTO);

  return response.data.data;
};

export const autocompleteAlumniDatabaseSearch = async (
  orgId: string,
  searchTerm: string,
  limit: number,
): Promise<AlumProfileAutocompleteResponse> => {
  const response = await http.get<
    HttpResponse<AlumProfileAutocompleteResponse>
  >(
    `/api/org/${orgId}/profile/alumni/autocomplete?search_term=${encodeURIComponent(
      searchTerm,
    )}&limit=${limit}`,
  );

  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }

  response.data.data.suggestions = response.data.data.suggestions.map((suggestion) => {
    return titleCase(suggestion);
  });
  return response.data.data;
};

export const autocompleteAlumniDatabaseSearchByName = async (
  orgId: string,
  searchTerm: string,
  limit: number,
): Promise<AlumProfileAutocompleteByNameResponse> => {
  const response = await http.get<
    HttpResponse<AlumProfileAutocompleteByNameResponse>
  >(
    `/api/org/${orgId}/profile/alumni/name/autocomplete?search_term=${encodeURIComponent(
      searchTerm,
    )}&limit=${limit}`,
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }

  response.data.data.alumni.forEach((alum) => {
    alum.first_name = capitalizeFirstLetter(alum.first_name);
    alum.last_name = capitalizeFirstLetter(alum.last_name);
  });
  return response.data.data;
};

const transformFiltersForBackend = (filters: AlumniDbFilterOptions) => {
  let rehireDTO = Array.from(filters.would_rehire).reduce(
    (acc: (boolean | null)[], [key, value]) => {
      if (key === "yes" && value) {
        return acc.concat(true);
      } else if (key === "no" && value) {
        return acc.concat(false);
      } else if (key === "notApplicable" && value) {
        return acc.concat(null);
      }
      return acc;
    },
    [],
  )
  return {
    last_known_locations: Array.from(filters.last_known_locations),
    former_departments: Array.from(filters.former_departments),
    former_employment_types: Array.from(filters.former_employment_types).reduce(
      (acc: string[], [key, value]) => (value ? acc.concat(key) : acc),
      [],
    ),
    former_salary: {
      min: filters.former_salary.min == "" ? null : +filters.former_salary.min,
      max: filters.former_salary.max == "" ? null : +filters.former_salary.max,
    },
    tenure: {
      min: filters.tenure.min == "" ? null : +filters.tenure.min,
      max: filters.tenure.max == "" ? null : +filters.tenure.max,
    },
    time_since_departure: filters.time_since_departure,
    state: filters.state,
    would_rehire: rehireDTO
  };
};
export const archive = async (
  orgId: string,
  employeeIds: string[],
): Promise<void> => {
  const response = await http.patch<HttpResponse<void>>(
    `/api/org/${orgId}/alumni-matches/archive`,
    { employee_ids: employeeIds },
  );
  if (response.data.status === ResponseStatus.ERROR) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
};

export const restore = async (
  orgId: string,
  employeeIds: string[],
): Promise<void> => {
  const response = await http.patch<HttpResponse<void>>(
    `/api/org/${orgId}/alumni-matches/restore-alum`,
    { employee_ids: employeeIds },
  );
  if (response.data.status === ResponseStatus.ERROR) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
};

export const favorite = async (
  orgId: string,
  employeeIds: string[],
): Promise<void> => {
  const response = await http.patch<HttpResponse<void>>(
    `/api/org/${orgId}/alumni-matches/favorite`,
    { employee_ids: employeeIds },
  );
  if (response.data.status === ResponseStatus.ERROR) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
};

export const removeFavorite = async (
  orgId: string,
  employeeIds: string[],
): Promise<void> => {
  const response = await http.patch<HttpResponse<void>>(
    `/api/org/${orgId}/alumni-matches/remove-favorite`,
    { employee_ids: employeeIds },
  );
  if (response.data.status === ResponseStatus.ERROR) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
};

export const approveMatch = async (
  approvalCode: string,
): Promise<JobMatchApprovalResponse> => {
  const response = await http.patch<HttpResponse<JobMatchApprovalResponse>>(
    `/alumni-matches/approve/${approvalCode}`,
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  response.data.data.first_name = capitalizeFirstLetter(response.data.data.first_name);
  response.data.data.last_name = capitalizeFirstLetter(response.data.data.last_name);
  return response.data.data;
}

export const rejectMatch = async (
  approvalCode: string,
): Promise<JobMatchApprovalResponse> => {
  const response = await http.patch<HttpResponse<JobMatchApprovalResponse>>(
    `/alumni-matches/reject/${approvalCode}`,
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  response.data.data.first_name = capitalizeFirstLetter(response.data.data.first_name);
  response.data.data.last_name = capitalizeFirstLetter(response.data.data.last_name);
  return response.data.data;
}

const captializeNameInAlumDTO = (alum: AlumDTO) => {
  alum.first_name = capitalizeFirstLetter(alum.first_name);
  alum.last_name = capitalizeFirstLetter(alum.last_name);

  if (alum.company_contacts) {
    alum.company_contacts.forEach((contact) => {
      if (contact.first_name) {
        contact.first_name = capitalizeFirstLetter(contact.first_name);
      }
  
      if (contact.last_name) {
        contact.last_name = capitalizeFirstLetter(contact.last_name);
      }
    });
  }

  if (alum.notes) {
    alum.notes.forEach((note) => {
      note.created_by.first_name = capitalizeFirstLetter(note.created_by.first_name);
      note.created_by.last_name = capitalizeFirstLetter(note.created_by.last_name);
    });
  }

  return alum;
}