import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Switch from "../../theme/Switch";
import Menu from "../../components/OptionsMenu";
import { getDaysSinceDate, displayDate } from "../../lib/time";
import {
  MatchingCriteriaI,
  RoleMatchingCriteriaDTO,
} from "../../models/api/criteria";
import { useTranslation } from "react-i18next";
import { Translation } from "../../lib/constants";
import Tooltip from "../../theme/Tooltip";
import theme from "../../theme";
import { useContext } from "react";
import { UserConfigContext } from "../../lib/context.ts";
import { isRoleMatching } from "../../models/criteria";

type CriteriaListItemProps = {
  menuOptions: {
    className: string;
    label: string;
    handler: () => void;
    hasDividerAbove: boolean;
    color?: string;
  }[];
  criteria: MatchingCriteriaI;
  enableToggle: boolean;
  onToggle?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onEdit: () => void;
  toggleDisabledTooltip?: string;
};

export default function CriteriaListItem({
  menuOptions,
  criteria,
  enableToggle,
  onToggle,
  onEdit,
  toggleDisabledTooltip,
}: CriteriaListItemProps) {
  const [userConfig, _setUserConfig] = useContext(UserConfigContext);
  const { t } = useTranslation(Translation.settings);
  const roleMatchingCriteria: RoleMatchingCriteriaDTO | null = isRoleMatching(
    criteria,
  )
    ? criteria
    : null;
  const isAutomationEnabled = () => {
    return userConfig?.hasAutomatedJobMatchingEnabled() ?? false;
  };

  const timeFrameDisplay = () => {
    const daysSinceLastUpdate = getDaysSinceDate(criteria.last_updated);
    if (daysSinceLastUpdate == 0) {
      return t("lastUpdated.today");
    } else if (daysSinceLastUpdate == 1) {
      return t("lastUpdated.yesterday");
    } else {
      return displayDate(criteria.last_updated);
    }
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      display="flex"
      style={{
        borderRadius: "12px",
        border: "1px solid var(--Gray-200, #EAECF0)",
        width: "100%",
        padding: "16px 8px 16px 24px",
      }}
    >
      <Box width="33%" onClick={onEdit} style={{ cursor: "pointer" }}>
        <Typography
          fontSize="14px"
          fontWeight={500}
          lineHeight="20px"
          color={theme.palette.grey[800]}
          className="criteria-list-item-name"
        >
          {criteria.name}
        </Typography>
        <Typography
          fontSize="14px"
          fontWeight={400}
          lineHeight="20px"
          color={theme.palette.grey[500]}
          className="criteria-list-item-label"
        >
          {t("lastUpdated.label")}: {timeFrameDisplay()}
        </Typography>
      </Box>
      {roleMatchingCriteria && isAutomationEnabled() && (
        <Box width="25%" onClick={onEdit} style={{ cursor: "pointer" }}>
          <Box>
            <Typography
              fontSize="0.875rem"
              fontWeight={500}
              lineHeight="1.25rem"
              color={theme.palette.grey[800]}
            >
              {roleMatchingCriteria.is_baseline
                ? t("allDepartments")
                : roleMatchingCriteria.department?.name ?? t("noDepartment")}
            </Typography>
            <Typography
              fontSize="0.875rem"
              fontWeight={400}
              lineHeight="1.25rem"
              color={theme.palette.grey[500]}
            >
              {roleMatchingCriteria.apply_to_all_roles_in_department
                ? "All roles"
                : `${roleMatchingCriteria.job_titles.length} roles`}
            </Typography>
          </Box>
        </Box>
      )}
      {roleMatchingCriteria && isAutomationEnabled() && (
        <Box width="25%" onClick={onEdit} style={{ cursor: "pointer" }}>
          <Typography
            width="max-content"
            padding="0.125rem 0.5rem"
            borderRadius="0.375rem"
            border="1px solid"
            fontSize="0.875rem"
            fontWeight={400}
            lineHeight="1.25rem"
            borderColor={
              roleMatchingCriteria.is_automated
                ? theme.palette.custom.automatedBorder
                : theme.palette.custom.manualBorder
            }
            color={
              roleMatchingCriteria.is_automated
                ? theme.palette.custom.automatedColor
                : theme.palette.custom.manualColor
            }
            sx={{
              backgroundColor: roleMatchingCriteria.is_automated
                ? theme.palette.custom.successBackground
                : theme.palette.custom.manualBackground,
            }}
          >
            {roleMatchingCriteria.is_automated ? "Automated" : "Manual"}
          </Typography>
        </Box>
      )}
      <Box flexGrow="1" />
      {enableToggle ? (
        <Switch
          switchsize="large"
          checked={criteria.is_enabled}
          onChange={onToggle}
          disabled={!enableToggle}
          className="switch-enabled"
        />
      ) : (
        <Tooltip
          arrow={false}
          title={toggleDisabledTooltip}
          className="tooltip"
          componentsProps={{
            tooltip: {
              sx: {
                padding: "0.75rem",
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
                borderRadius: "0.5rem",
                bgcolor: "white",
                color: "grey.500",
                fontSize: "0.75rem",
                fontWeight: "500",
                lineHeight: "1.125rem",
                boxShadow: "0px 0.5rem 0.5rem #00000034",
                marginRight: "16px",
              },
            },
          }}
        >
          <div>
            <Switch
              switchsize="large"
              checked={criteria.is_enabled}
              onChange={onToggle}
              disabled={!enableToggle}
              className="switch-disabled"
            />
          </div>
        </Tooltip>
      )}

      <Menu
        className="menu"
        style={{ paddingLeft: "16px" }}
        items={menuOptions}
      />
    </Stack>
  );
}
