import Box from "@mui/material/Box";
import Stack, { StackProps } from "@mui/material/Stack";
import EllipsisText from "./EllipsisText";
import { useEffect, useState, useContext, ReactNode } from "react";
import InitialsIcon from "./InitialsIcon";
import GoldStarIcon from "/gold-star.svg";
import linkedinIcon from "/linkedin.svg";
import notesIndicatorSvg from "/notes-indicator.svg";
import { AlumProfileEvent } from "../lib/eventEnums";
import { buildUrl, trackGoogleAnalyticsEvent, Format } from "../lib/utils";
import { UserContext } from "../lib/context";
import { useTranslation } from "react-i18next";
import { Translation } from "../lib/constants";
import Typography from "@mui/material/Typography";
import { FormattedText, FormattedTextType } from "./FormattedText";
import Tooltip from "./Tooltip.tsx";
import theme from "./index.ts";
import { EmployeeOrgState } from "../models/filter.ts";
import { AlumDTO, NoteDTO } from "../models/api/alum.ts";
import Notes from "../components/Notes/Notes.tsx";

interface AvatarProps {
  firstName: string;
  lastName: string;
  email: string;
  location?: string | null;
  size: AvatarSize;
  numOfNotes?: number | null;
  notes?: NoteDTO[] | null;
  photoUrl?: string | null;
  linkedInUrl?: string | null;
  tag?: ReactNode;
  stackProps?: StackProps;
  isSubscribed?: boolean;
  isDisabled?: boolean;
  callToAction?: ReactNode;
  alumState?: EmployeeOrgState | null;
  showAlumState?: boolean | null;
  onAvatarClick?: () => void;
}

export default function Avatar(props: AvatarProps) {
  const { t } = useTranslation(Translation.common);
  const {
    firstName,
    lastName,
    email,
    location,
    size,
    numOfNotes,
    notes,
    photoUrl,
    linkedInUrl,
    isSubscribed,
    alumState,
    showAlumState,
    tag,
    isDisabled,
    stackProps,
    callToAction,
    onAvatarClick
  } = props;
  const [imageError, setImageError] = useState(false);
  const [showAllNotesModal, setShowAllNotesModal] = useState(false);
  const [user] = useContext(UserContext);

  useEffect(() => {
    setImageError(false);
  }, [photoUrl]);

  const handleLinkedInClick = () => {
    if (user?.orgId) {
      trackGoogleAnalyticsEvent({
        event: AlumProfileEvent.ALUM_PROFILE_LINKEDIN,
        type: "linkedin_click",
        org: user?.orgName,
      });
    }
  };

  const getAlumDTO = (): AlumDTO => {
    return {
      id: "",
      first_name: firstName,
      last_name: lastName,
      email: email,
      linkedin_profile_url: linkedInUrl ?? null,
      profile_photo_url: photoUrl ?? null,
      org_name: "",
      end_date_at_org: "",
      tenure_at_org: null,
      job_matches: [],
      job_history: [],
      former_employment_type: null,
      last_job_title_at_org: null,
      last_job_department_at_org: null,
      former_salary: 0,
      former_salary_pay_period: null,
      current_job_title: null,
      current_company: null,
      last_known_location: "",
      last_time_contacted: null,
      would_rehire: null,
      reason_for_dismissal: null,
      is_subscribed: false,
      company_contacts: [],
      education_history: [],
      notes: notes ?? [],
      state: null
    };
  }

  let nameFontSize = "";
  let emailFontSize = "";
  let locationFontSize = "";
  let profileIconFontSize = "";
  let iconSize = "";
  let imageNameGap = "";
  let optedOutTextStyle = {};

  switch (size) {
    case AvatarSize.SMALL:
      nameFontSize = "0.75rem";
      emailFontSize = "0.75rem";
      locationFontSize = "0.75rem";
      profileIconFontSize = "0.875rem";
      iconSize = "2.25rem";
      imageNameGap = "0.75rem";
      break;
    case AvatarSize.MEDIUM:
      nameFontSize = "0.875rem";
      emailFontSize = "0.875rem";
      locationFontSize = "0.875rem";
      profileIconFontSize = "0.875rem";
      iconSize = "2.5rem";
      imageNameGap = "0.75rem";
      optedOutTextStyle = {
        display: "block",
        fontSize: emailFontSize,
      };
      break;
    case AvatarSize.LARGE:
      nameFontSize = "1.5rem";
      emailFontSize = "1rem";
      locationFontSize = "1rem";
      profileIconFontSize = "1.5rem";
      iconSize = "5rem";
      imageNameGap = "1rem";
      optedOutTextStyle = {
        display: "inline",
        marginLeft: "1rem",
        fontSize: emailFontSize,
      };
      break;
  }

  return (
    <>
      <Stack
        onClick={() => onAvatarClick && onAvatarClick()}
        id="avatar"
        flexDirection="row"
        gap={imageNameGap}
        alignItems="center"
        {...stackProps}
      >
        <Box flexShrink={0} flexGrow={0}>
          {photoUrl && !imageError ? (
            <Box
              width={iconSize}
              height={iconSize}
              borderRadius="50%"
              overflow="hidden"
            >
              <Box
                component="img"
                alt="profile-photo"
                src={photoUrl}
                width="100%"
                height="100%"
                onError={() => setImageError(true)}
              />
            </Box>
          ) : (
            <InitialsIcon
              firstName={firstName}
              lastName={lastName}
              size={iconSize}
              fontSize={profileIconFontSize}
            />
          )}
        </Box>
        <Stack
          width={`calc(100% - ${iconSize} - ${imageNameGap})`}
          flexShrink={0}
          flexGrow={0}
          gap="1.5rem"
        >
          <Stack gap="0.25rem">
            <Box>
              <Stack
                direction="row"
                alignItems="center"
                gap="0.25rem"
                color={(theme) => {
                  if (isDisabled) {
                    return theme.palette.grey[400];
                  }
                  return theme.palette.grey[800];
                }}
              >
                <EllipsisText
                  fontSize={nameFontSize}
                  fontWeight={size === AvatarSize.LARGE ? 600 : 400}
                  color="inherit"
                  alignItems="center"
                  gap="0.5rem"
                >
                  {firstName} {lastName}
                </EllipsisText>
                {isSubscribed === false && size === AvatarSize.LARGE && (
                  <>
                    <Typography fontSize="2rem">&middot;</Typography>
                    <EllipsisText
                      component="span"
                      fontSize="1rem"
                      fontWeight={400}
                      color={(theme) => theme.palette.grey[400]}
                    >
                      {t("optedOut")}
                    </EllipsisText>
                  </>
                )}
                {showAlumState && alumState === EmployeeOrgState.FAVORITE && (
                  <Tooltip title={t("favorited")}>
                    <Box
                      className="favoriteIcon"
                      component="img"
                      src={GoldStarIcon}
                      alt="favorite"
                      width="1rem"
                      height="1rem"
                    />
                  </Tooltip>
                )}
                {showAlumState && alumState === EmployeeOrgState.ARCHIVE && (
                  <Box bgcolor={theme.palette.grey[500]} borderRadius="0.375rem">
                    <FormattedText
                      props={{
                        value: t("archived"),
                        type: FormattedTextType.PRIMARY,
                      }}
                      style={{
                        padding: "0.25rem 0.5rem",
                        color: theme.palette.common.white,
                      }}
                    />
                  </Box>
                )}
                {tag && <Box paddingRight="1.5rem">{tag}</Box>}
                {callToAction && <Box marginLeft="auto">{callToAction}</Box>}
              </Stack>
              <FormattedText
                props={{
                  value: email,
                  type: FormattedTextType.SECONDARY,
                }}
                style={{
                  fontSize: emailFontSize,
                  paddingRight: "1.5rem",
                  color: (theme) => {
                    if (isDisabled) {
                      return theme.palette.grey[400];
                    } else if (size === AvatarSize.LARGE) {
                      return theme.palette.grey[800];
                    }
                    return theme.palette.grey[500];
                  },
                }}
              />
            </Box>
            {(numOfNotes != null && numOfNotes > 0) && (
              <Box
                id="notesIndicator"
                bgcolor={theme.palette.grey[50]}
                borderRadius="1rem"
                marginTop="0.125rem"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: 'fit-content',
                  height: 'auto'
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  setShowAllNotesModal(true)
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  gap="0.25rem"
                  sx={{ padding: "0.125rem 0.5rem 0.125rem 0.375rem;" }}
                >
                  <Box
                    component="img"
                    alt="notes-indicator"
                    src={notesIndicatorSvg}
                    width="12px"
                    height="12px"
                  />
                  <Typography
                    style={{
                      textAlign: "center",
                      paddingBottom: "0rem",
                      color: theme.palette.grey[800],
                      fontSize: "0.75rem",
                      fontWeight: 400,
                      paddingTop: "0rem",
                    }}
                  >
                    {numOfNotes} {numOfNotes > 1 ? t("notes") : t("note")}
                  </Typography>
                </Stack>
              </Box>
            )}
            {location && (
              <FormattedText
                props={{
                  value: Format.location(location),
                  type: FormattedTextType.SECONDARY,
                }}
                style={{
                  fontSize: locationFontSize,
                  paddingRight: "1.5rem",
                  color: (theme) => {
                    return theme.palette.grey[500];
                  },
                }}
              />
            )}
            <></>
            {size !== AvatarSize.LARGE && isSubscribed === false && (
              <EllipsisText
                fontSize={emailFontSize}
                fontWeight={400}
                color={(theme) => theme.palette.grey[400]}
                style={optedOutTextStyle}
              >
                {t("optedOut")}
              </EllipsisText>
            )}
          </Stack>
          {linkedInUrl ? (
            <Box
              component="a"
              href={buildUrl(linkedInUrl)}
              target="_blank"
              onClick={handleLinkedInClick}
              width="2rem"
              height="2rem"
            >
              <Box
                component="img"
                src={linkedinIcon}
                width="2rem"
                height="2rem"
              />
            </Box>
          ) : null}
        </Stack>
      </Stack>
      <Notes
        alum={getAlumDTO()}
        showBaseComponent={false}
        showAllNotesModal={showAllNotesModal}
        setShowAllNotesModal={setShowAllNotesModal}
      />
    </>
  );
}

export enum AvatarSize {
  SMALL = "sm",
  MEDIUM = "md",
  LARGE = "lg",
}
