import { http } from ".";
import { setCsrfTokenInStorage } from "../lib/utils";
import { HttpError, HttpResponse, ResponseStatus } from "../models/api/http";

export const getCsrfToken = async (): Promise<CsrfTokenResponse> => {
  const response = await http.get<CsrfTokenResponse>("/csrf/token", false);
  setCsrfTokenInStorage(response.data.token);
  return response.data;
};

export const signIn = async (data: SignInRequest): Promise<SignInResponse> => {
  const response = await http.post<HttpResponse<SignInResponse>>(
    "/auth/sign-in",
    {
      email: data.email,
      password: data.password,
      mfa_code: data.mfaCode,
    },
    false,
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return response.data.data;
};

export const signInWithToken = async (
  issuer: string,
  token: string,
): Promise<SignInResponse> => {
  const response = await http.post<HttpResponse<SignInResponse>>(
    "/auth/token-sign-in",
    {
      issuer: issuer,
      token: token,
    },
    false,
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return response.data.data;
};

export const getSignInMethod = async (email: string): Promise<SignInMethod> => {
  const response = await http.post<HttpResponse<SignInMethod>>(
    "/auth/sign-in-method",
    {
      email: email,
    },
    false,
  );
  if (response.data.status === ResponseStatus.ERROR || !response.data.data) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return response.data.data;
};

export const signOut = async () => {
  const response = await http.post<HttpResponse<null>>("/auth/sign-out");
  if (response.data.status === ResponseStatus.ERROR) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  // Signing out invalidates the previous session and CSRF token
  // So we need to get a new CSRF token for the next session
  getCsrfToken();
};

export const forgotPassword = async (email: string): Promise<boolean> => {
  const response = await http.post<HttpResponse<boolean>>(
    "/auth/forgot-password",
    {
      email: email,
    },
    false,
  );
  if (response.data.status === ResponseStatus.ERROR) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return true;
};

export const resetPassword = async (
  token: string,
  password: string,
): Promise<boolean> => {
  const response = await http.post<HttpResponse<boolean>>(
    `/auth/reset-password/${token}`,
    {
      password: password,
    },
    false,
  );
  if (response.data.status === ResponseStatus.ERROR) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return true;
};

export const validateResetToken = async (token: string): Promise<boolean> => {
  const response = await http.post<HttpResponse<boolean>>(
    `/auth/reset-password/${token}/validate`,
    {},
    false,
  );
  if (response.data.status === ResponseStatus.ERROR) {
    throw new HttpError(response.status, response.data.message ?? "");
  }
  return true;
};

type CsrfTokenResponse = {
  token: string;
  headerName: string;
  parameterName: string;
};

export type SignInRequest = {
  email: string;
  password: string;
  mfaCode: string | null;
};

type SignInResponse = {
  user: {
    email: string;
    first_name: string;
    last_name: string;
    orgs: { org_id: string; org_name: string }[];
  } | null;
  is_mfa_required: boolean;
};

export type SignInMethod = {
  type: "EMAIL_PASSWORD" | "OIDC_SSO" | "SAML_SSO";
  redirect_url?: string;
  authority?: string;
  client_id?: string;
  scopes?: string;
};
