import { useState, ReactElement } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Stack, Typography, IconButton, Grid } from "@mui/material";
import Button from "../../theme/Button";
import theme from "../../theme";
import { useTranslation } from "react-i18next";
import { Translation } from "../../lib/constants";
import ConfirmActionModal from "../ConfirmActionModal";
import ArrowLeft from "@mui/icons-material/ArrowBackRounded";

interface ModalTemplateProps {
  children: ReactElement;
  heading: string;
  primaryButtonDisabled?: boolean;
  primaryButtonText: string;
  onClose: () => void;
  primaryButtonOnClick: () => void;
  backButtonOnClick?: () => void;
  subheading?: string;
}

export default function ModalTemplate({
  children,
  heading,
  primaryButtonDisabled,
  primaryButtonText,
  onClose,
  primaryButtonOnClick,
  backButtonOnClick,
  subheading,
}: ModalTemplateProps) {
  const { t } = useTranslation(Translation.sendEmailModal);
  const [discardModalOpen, setDiscardModalOpen] = useState(false);
  return (
    <>
      <Stack
        id="modal-template"
        direction="column"
        justifyContent="space-between"
        padding="1.5rem 0rem"
        height="90vh"
        width={{ xs: "80vw", md: "50vw" }}
        maxWidth="1024px"
      >
        <div style={{padding: "0rem 1.5rem"}}>

        
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center">
            {backButtonOnClick && (
              <IconButton
                disableRipple
                sx={{
                  mr: 1,
                  borderRadius: "0.5rem",
                  "&:hover": {
                    backgroundColor: theme.palette.grey[50],
                  },
                }}
                onClick={backButtonOnClick}
              >
                <ArrowLeft />
              </IconButton>
            )}
            <Typography
              fontSize="1.25rem"
              fontWeight={600}
              lineHeight="2rem"
              color={(theme) => theme.palette.grey[800]}
              className="modal-template-heading"
            >
              {heading}
            </Typography>
          </Stack>
          <IconButton
            disableRipple
            id="modal-template-close-button"
            sx={{
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            onClick={() => setDiscardModalOpen(true)}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        { subheading && <Typography
        fontSize="14px"
        fontWeight={400}
        lineHeight="20px"
        className="modal-template-subheading"
        color={(theme) => theme.palette.grey[500]}
      >
        {subheading}
      </Typography>}
      </div>

       
        {children}
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          padding="1.5rem 1.5rem 0rem 0rem"
        >
          <Button
            type="submit"
            buttonProps={{
              disabled: primaryButtonDisabled,
              onClick: primaryButtonOnClick,
              id: "modal-template-primary-button",
              disableRipple: true,
              sx: {
                height: "2.5rem",
                fontSize: "0.875rem",
                boxShadow: "none",
                backgroundColor: theme.palette.primary.main,
                "&:hover": {
                  backgroundColor: theme.palette.custom.purpleHover,
                  boxShadow: "none",
                },
                "&:active": {
                  backgroundColor: theme.palette.custom.purplePressed,
                  boxShadow: "none",
                },
              },
            }}
          >
            <Typography
              fontSize="0.9rem"
              fontWeight={600}
              lineHeight="1.2rem"
              padding="0.5rem 1rem"
            >
              {primaryButtonText}
            </Typography>
          </Button>
        </Grid>
      </Stack>
      <ConfirmActionModal
        open={discardModalOpen}
        primaryButtonHandler={() => onClose()}
        secondaryButtonHandler={() => setDiscardModalOpen(false)}
        primaryButtonBgColor={theme.palette.error.main}
        primaryButtonHoverBgColor={theme.palette.error.dark}
        heading={t("discard.header")}
        subHeading={t("discard.warning")}
        primaryButtonText={t("discard.button")}
        secondaryButtonText={t("cancel")}
      />
    </>
  );
}
