import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { Typography, Autocomplete, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import HelpCircleIcon from "/help-circle-secondary.svg";
import CheckIcon from "/check.svg";
import ChevronDownIcon from "/chevron-down.svg";
import Tooltip from "../theme/Tooltip";
import styled from "@emotion/styled";
import Radio from "@mui/material/Radio";
import theme from "../theme";
import Checkbox, { CheckedState } from "./Checkbox";
import { useTranslation } from "react-i18next";
import { Translation } from "../lib/constants";
import { useState } from "react";
import Input from "../theme/Input";
import MultiChipInput from "../theme/MultiChipInput";
import { FormattedText } from "../theme/FormattedText";

const StyledInput = styled("input")`
  display: block;
  width: 50%;
  border: none;
  font-size: 0.875rem;

  &:focus {
    outline: none;
  }

  &:disabled {
    color: ${theme.palette.grey[400]};
    background-color: ${theme.palette.grey[50]};
  }

  &::placeholder {
    color: ${theme.palette.grey[500]};
  }
`;

const inputProps = {
  required: true,
  inputStyleProps: {
    fontSize: "0.875rem",
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.grey[300],
    },
  },
  titleProps: { fontWeight: 500, color: theme.palette.grey[800] },
};

export const AnnotatedInput = ({
  value,
  onChange,
  annotation,
  error,
  disabled = false,
  id,
  hint,
  width = "7.875rem",
}: {
  value: string | number;
  onChange: (arg: any) => void;
  annotation: string;
  error: boolean;
  disabled?: boolean;
  id?: string;
  hint?: string;
  width?: string;
}) => {
  const { t } = useTranslation(Translation.settings);
  const [isFocused, setIsFocused] = useState(false);

  const toggleFocus = () => {
    setIsFocused((isFocused) => !isFocused);
  };

  const borderColor = () => {
    if (error) {
      return theme.palette.custom.errorBorder;
    } else if (isFocused) {
      return theme.palette.primaryColor[600];
    } else {
      return theme.palette.grey[300];
    }
  };

  return (
    <Stack id={id} gap="0.375rem" width={width}>
      <Stack
        style={{
          borderRadius: "0.5rem",
          borderWidth: "0.0625rem",
          borderStyle: "solid",
          borderColor: borderColor(),
          display: "flex",
          flexDirection: "row",
          height: "2.5rem",
          justifyContent: "space-between",
          padding: "0.625rem 0.875rem",
          backgroundColor: disabled
            ? theme.palette.grey[50]
            : theme.palette.background.default,
        }}
      >
        <StyledInput
          id="annotated-text-input"
          type="text"
          value={value}
          onChange={onChange}
          disabled={disabled}
          onFocus={toggleFocus}
          onBlur={toggleFocus}
          placeholder={hint}
        />
        <Typography
          fontSize="0.875rem"
          fontWeight={400}
          lineHeight="1.25rem"
          color={(theme) => theme.palette.grey[400]}
        >
          {annotation}
        </Typography>
      </Stack>
      <Typography
        fontSize="0.875rem"
        fontWeight={400}
        lineHeight="1.25rem"
        textAlign="right"
        color={theme.palette.errorColor[700]}
      >
        {error && t("editCriteria.numberInputError")}
      </Typography>
    </Stack>
  );
};

type RadioOptionProps<T> = {
  label: string;
  value: T;
  showTooltip: boolean;
  tooltipText?: string;
  radioButtonOnRight?: boolean;
  disabled?: boolean;
  hoverText?: string;
};

export function RadioOption<T>({
  label,
  value,
  showTooltip,
  tooltipText,
  radioButtonOnRight = true,
  disabled = false,
  hoverText,
}: RadioOptionProps<T>) {
  const RadioButtonElement = (
    <Radio
      value={value}
      size="small"
      disableRipple
      sx={{
        marginRight: radioButtonOnRight ? undefined : "0.5rem",
        marginLeft: radioButtonOnRight ? "auto" : undefined,
        color: theme.palette.grey[300],
        padding: 0,
        boxShadow: disabled ? "inset 0 0 0 2px white" : "none",
        "&:hover": {
          backgroundColor: "transparent",
        },
        "&.Mui-checked": {
          color: disabled
            ? theme.palette.grey[300]
            : theme.palette.primaryColor[600],
        },
        "&.Mui-disabled": {
          color: theme.palette.grey[300],
          backgroundColor: theme.palette.grey[100],
        },
      }}
      disabled={disabled}
    />
  );

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" alignItems="center">
        {!radioButtonOnRight &&
          (hoverText ? (
            <Tooltip
              arrow={false}
              title={hoverText}
              componentsProps={{
                tooltip: {
                  sx: {
                    padding: "0.75rem",
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5rem",
                    borderRadius: "0.5rem",
                    bgcolor: "white",
                    color: "grey.500",
                    fontSize: "0.75rem",
                    fontWeight: "500",
                    lineHeight: "1.125rem",
                    boxShadow: "0rem 0.5rem 0.5rem #00000034",
                  },
                },
              }}
            >
              <span>{RadioButtonElement}</span>
            </Tooltip>
          ) : (
            RadioButtonElement
          ))}

        <Typography
          fontSize="0.875rem"
          fontWeight={radioButtonOnRight ? 500 : 400}
          color={theme.palette.grey[800]}
          lineHeight="1.25rem"
        >
          {label}
        </Typography>
        {showTooltip && (
          <Tooltip
            arrow={false}
            title={tooltipText}
            componentsProps={{
              tooltip: {
                sx: {
                  padding: "0.75rem",
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                  borderRadius: "0.5rem",
                  bgcolor: "white",
                  color: "grey.500",
                  fontSize: "0.75rem",
                  fontWeight: "500",
                  lineHeight: "1.125rem",
                  boxShadow: "0rem 0.5rem 0.5rem #00000034",
                },
              },
            }}
          >
            <Box component="img" src={HelpCircleIcon} marginLeft="0.25rem" />
          </Tooltip>
        )}
      </Stack>

      {radioButtonOnRight && RadioButtonElement}
    </Stack>
  );
}

type CheckboxOptionProps = {
  id?: string;
  label: string;
  value: boolean;
  showTooltip: boolean;
  onCheck: (arg?: boolean) => void;
  tooltipText?: string;
  error: boolean;
};

export function CheckboxOption({
  id,
  label,
  value,
  showTooltip,
  onCheck,
  tooltipText,
  error,
}: CheckboxOptionProps) {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" alignItems="center">
        <Typography
          fontSize="0.875rem"
          fontWeight={500}
          color={theme.palette.grey[800]}
          lineHeight="1.25rem"
        >
          {label}
        </Typography>
        {showTooltip && (
          <Tooltip
            arrow={false}
            title={tooltipText}
            componentsProps={{
              tooltip: {
                sx: {
                  padding: "0.75rem",
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                  borderRadius: "0.5rem",
                  bgcolor: "white",
                  color: "grey.500",
                  fontSize: "0.75rem",
                  fontWeight: "500",
                  lineHeight: "1.125rem",
                  boxShadow: "0rem 0.5rem 0.5rem #00000034",
                },
              },
            }}
          >
            <Box component="img" src={HelpCircleIcon} marginLeft="0.25rem" />
          </Tooltip>
        )}
      </Stack>

      <Checkbox
        id={id}
        checked={value ? CheckedState.CHECKED : CheckedState.UNCHECKED}
        onCheck={onCheck}
        error={error}
      />
    </Stack>
  );
}

export const CriteriaRuleHeader = ({
  headerText,
  supportingText,
  allowReset,
  onReset,
  hideReset = false,
}: {
  headerText: string;
  supportingText: string;
  allowReset: boolean;
  onReset: () => void;
  hideReset?: boolean;
}) => {
  const { t } = useTranslation(Translation.settings);

  return (
    <Stack direction="row">
      <Box flexGrow="1">
        <Typography
          fontSize="1.125rem"
          fontWeight={600}
          lineHeight="1.75rem"
          color={(theme) => theme.palette.grey[800]}
        >
          {headerText}
        </Typography>
        <Typography
          fontSize="0.875rem"
          fontWeight={400}
          lineHeight="1.25rem"
          color={(theme) => theme.palette.grey[500]}
        >
          {supportingText}
        </Typography>
      </Box>

      {!hideReset && (
        <Button
          disableRipple
          disabled={!allowReset}
          style={{
            color: allowReset
              ? theme.palette.grey[600]
              : theme.palette.grey[400],
            fontSize: "0.875rem",
            height: "fit-content",
          }}
          onClick={onReset}
        >
          {t("editCriteria.reset")}
        </Button>
      )}
    </Stack>
  );
};

export const CriteriaInput = ({
  placeholder,
  label,
  value,
  setValue,
  tooltipText,
  error,
  id,
  onFocus,
}: {
  placeholder: string;
  label: string;
  value: string;
  setValue: (arg: string) => void;
  tooltipText?: string;
  error: string;
  id?: string;
  onFocus?: () => void;
}) => {
  return (
    <Input
      id={id}
      placeholder={placeholder}
      label={label}
      {...inputProps}
      error={error}
      inputProps={{ value: value }}
      onChange={(event) => setValue(event.target.value)}
      tooltipText={tooltipText}
      onFocus={onFocus}
    />
  );
};

export const CriteriaMultiChipInput = ({
  placeholder,
  label,
  chipValues,
  setChipValues,
  tooltipText,
  error,
  onAlreadyAdded,
  id,
  required,
}: {
  placeholder: string;
  label: string;
  chipValues: string[];
  setChipValues: (arg: string[]) => void;
  tooltipText?: string;
  error: string;
  onAlreadyAdded?: () => void;
  id?: string;
  required?: boolean;
}) => {
  return (
    <MultiChipInput
      id={id}
      inputProps={{
        ...inputProps,
        error: error,
        placeholder: placeholder,
        label: label,
        required: required,
      }}
      chipValues={chipValues}
      setChipValues={setChipValues}
      tooltipText={tooltipText}
      onAlreadyAdded={onAlreadyAdded}
    />
  );
};

export const RangedAnnotatedInput = ({
  minValue,
  maxValue,
  handleMinChange: onMinChange,
  handleMaxChange: onMaxChange,
  annotation,
  minError,
  maxError,
  rangeError,
  disabled = false,
  id,
  minHint,
  maxHint,
}: {
  label: string;
  minValue: string | number;
  maxValue: string | number;
  handleMinChange: (arg: any) => void;
  handleMaxChange: (arg: any) => void;
  annotation: string;
  minError: boolean;
  maxError: boolean;
  rangeError: boolean;
  disabled?: boolean;
  id?: string;
  minHint?: string;
  maxHint?: string;
}) => {
  const { t } = useTranslation(Translation.common);

  minHint = minHint || t("filter.fields.input.min");
  maxHint = maxHint || t("filter.fields.input.max");

  return (
    <Stack gap="0.375rem">
      <Stack direction="row" gap="0.5rem">
        <AnnotatedInput
          id={"min-" + id}
          annotation={annotation}
          hint={minHint}
          value={minValue}
          error={minError}
          onChange={onMinChange}
          disabled={disabled}
          width="100%"
        />
        <Typography
          fontSize="0.875rem"
          fontWeight={400}
          lineHeight="1.25rem"
          color={theme.palette.grey[800]}
          paddingTop="0.625rem"
        >
          -
        </Typography>
        <AnnotatedInput
          id={"max-" + id}
          annotation={annotation}
          hint={maxHint}
          value={maxValue}
          error={maxError}
          onChange={onMaxChange}
          disabled={disabled}
          width="100%"
        />
      </Stack>
      <Typography
        fontSize="0.875rem"
        fontWeight={400}
        lineHeight="1.25rem"
        color={theme.palette.errorColor[700]}
      >
        {rangeError && t("filter.error.rangeError")}
      </Typography>
    </Stack>
  );
};

export const FilterMultiSelectCheckbox = <T extends string,>({
  label,
  optionLabels,
  optionStatus,
  setFilters,
}: {
  label: string;
  optionLabels: Map<string, string>;
  optionStatus: Map<T, boolean | null>;
  setFilters: (optionKey: T, value: boolean) => void;
}) => {
  return (
    <Stack direction="column">
      <Typography
        fontSize="0.875rem"
        fontWeight={500}
        lineHeight="1.25rem"
        color={(theme) => theme.palette.grey[800]}
      >
        {label}
      </Typography>

      <Stack direction="column" gap="0.75rem" padding="0.5rem">
        {Array.from(optionStatus, ([key, value]) => (
          <Stack key={key} direction="row" alignItems="center" gap="0.5rem">
            <Checkbox
              id={`checkbox-status-${key}`}
              checked={value ? CheckedState.CHECKED : CheckedState.UNCHECKED}
              onCheck={() => setFilters(key, !value)}
            />
            <Typography
              fontSize="0.875rem"
              fontWeight={400}
              lineHeight="1.25rem"
            >
              {optionLabels.get(key)}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export type SelectOption<T> = {
  id: string;
  value: T;
  label: string;
  selectable: boolean;
};

interface SelectInputProps<T> {
  id?: string;
  options: SelectOption<T>[];
  selectedOption: SelectOption<T> | null;
  setSelectedOption: (value: SelectOption<T> | null) => void;
  placeholder?: string;
  format?: (value: string) => string;
  errorMessage?: string;
}

export default function SelectInput<T>({
  id,
  options,
  selectedOption,
  setSelectedOption,
  placeholder,
  format,
  errorMessage = "",
}: SelectInputProps<T>) {
  const [input, setInput] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const error = errorMessage != "" && input == "";

  return (
    <Stack spacing={0.5} sx={{ width: "100%" }}>
      <Autocomplete
        id={id}
        fullWidth
        popupIcon={
          <Box
            component="img"
            src={ChevronDownIcon}
            style={{ marginTop: "3px" }}
          />
        }
        selectOnFocus={false}
        options={options}
        value={selectedOption}
        inputValue={input}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onInputChange={(_event, newInputValue) => {
          setInput(newInputValue);
        }}
        open={isDropdownOpen}
        onOpen={() => setIsDropdownOpen(true)}
        onClose={() => setIsDropdownOpen(false)}
        onChange={(_event, value) => {
          setSelectedOption(value);
        }}
        getOptionDisabled={(option) => !option.selectable}
        sx={{
          ".MuiAutocomplete-inputRoot": {
            flexWrap: " nowrap !important;",
            fontSize: "0.875rem",
          },
          ".MuiAutocomplete-noOptions": {
            fontSize: "0.875rem",
            fontWeight: 1000,
            lineHeight: "1.25rem",
            color: theme.palette.grey[800],
          },
          ".MuiAutocomplete-clearIndicator": { display: "none" },
          "& .MuiOutlinedInput-root": {
            height: "2.5rem",
            borderRadius: "0.5rem",
          },
        }}
        renderOption={(_props, option) => {
          const isSelected = option.id == selectedOption?.id;
          return (
            <Box
              className="select-option"
              key={option.id}
              sx={{
                display: "flex",
                padding: "0.625rem 0.625rem 0.625rem 0.5rem",
                backgroundColor: isSelected
                  ? theme.palette.grey[50]
                  : "transparent",
                margin: "0.125rem 0.375rem",
                borderRadius: "0.375rem",
                "&:hover": {
                  backgroundColor: option.selectable
                    ? theme.palette.grey[50]
                    : undefined,
                },
              }}
              onClick={() => {
                if (option.selectable) {
                  setSelectedOption(option);
                  setIsDropdownOpen(false);
                }
              }}
            >
              <FormattedText
                props={{
                  value: option.label,
                  format: format,
                  ellipsis: false,
                }}
                style={{
                  color: option.selectable
                    ? "inherit"
                    : theme.palette.grey[400],
                }}
              />
              {isSelected && (
                <Box component="img" src={CheckIcon} marginLeft="auto" />
              )}
            </Box>
          );
        }}
        renderInput={(params) => (
          <TextField
            ref={params.InputProps.ref}
            placeholder={placeholder}
            error={error}
            rows={1}
            {...params}
            fullWidth
            sx={{
              fieldset: {
                borderColor: theme.palette.grey[300] + " !important",
              },
              "& .Mui-focused": {
                fieldset: {
                  borderColor: theme.palette.primary.dark + " !important",
                },
              },
              "& .Mui-error": {
                fieldset: {
                  borderColor: theme.palette.errorColor[300] + " !important",
                },
              },
              "& input::placeholder": {
                fontSize: "0.875rem",
              },
            }}
          />
        )}
      />
      {error && (
        <Typography
          id="select-input-error"
          fontSize="0.875rem"
          color={theme.palette.errorColor[700]}
        >
          {errorMessage}
        </Typography>
      )}
    </Stack>
  );
}
