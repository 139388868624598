import { Box, Tab, Tabs } from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "../../theme/Button";
import {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  archive,
  favorite,
  listAlumni,
  removeFavorite,
  restore,
} from "../../api/alum";
import FilterButton from "../../components/Filter/FilterButton";
import {
  FormerDepartment,
  FormerSalary,
  LastKnownLocation,
  Tenure,
  TimeSinceDeparture,
} from "../../components/Filter/FilterFields";
import FilterPanel from "../../components/Filter/FilterPanel";
import Loading from "../../components/Loading";
import Menu from "../../components/OptionsMenu";
import SearchBar from "../../components/Searchbar";
import Sort from "../../components/Sort";
import ErrorToastContainer from "../../components/Toastify/ErrorToastContainer";
import { Translation } from "../../lib/constants";
import { SearchbarContextType } from "../../models/api/common";
import theme from "../../theme";
import AlumniDatabaseTable from "./AlumniDatabaseTable";
import {
  AlumDTO,
  AlumniDbSortEnum,
  getEmploymentTypeDisplay,
} from "../../models/api/alum";
import {
  ALUMNI_DATABASE_PAGE_SIZE,
  loadAlumniDbSortFromStorage,
  MAX_SELECTED_ALUMNI,
  setAlumniDbSortInStorage,
  trackGoogleAnalyticsEvent,
} from "../../lib/utils";
import {
  AlumniDbFilterOptions,
  EmployeeOrgState,
  getAlumniDbDefaultFilters,
  WouldRehireOptions,
} from "../../models/filter";
import EllipsisText from "../../theme/EllipsisText";
import Header from "../../theme/Header";
import SuccessToastContainer from "../../components/Toastify/SuccessToastContainer";
import InfoToastContainer from "../../components/Toastify/InfoToastContainer";
import { useTableContext } from "../../components/TableView";
import SendEmailModal, { Receiver } from "../../components/SendEmailModal";
import CenterModal from "../../components/CenterModal";
import { EmailTemplateTypeEnum } from "../../models/api/resource";
import { AlumDBEvent, GAEvent } from "../../lib/eventEnums";
import { FlagsContext, UserContext } from "../../lib/context";
import { FilterMultiSelectCheckbox } from "../../components/UtilComponents.tsx";
import HeartHandIcon from "/heart-hand.svg";
import MailIcon from "/mail.svg";
import StarIcon from "/star.svg";
import MinusStarIcon from "/minus-star.svg";
import ArchiveIcon from "/archive.svg";
import RestoreIcon from "/restore.svg";
import ThinStarIcon from "/thin-star.svg";
import ThinArchiveIcon from "/thin-archive.svg";
import DotsVerticalIcon from "/dots-vertical-dark.svg";
import ConfirmActionModal from "../../components/ConfirmActionModal";
import { AlumniTab } from "./alumniTab.tsx";
import EmptyList from "../../components/EmptyList";

export interface InputError {
  former_salary: boolean;
  tenure: boolean;
  time_since_departure: boolean;
}

interface ActionPayload {
  alumniToAffect: AlumDTO[];
  isBulkAction: boolean;
}

export default function AlumniDatabase() {
  const { t } = useTranslation(Translation.alumniDatabase);
  const common = useTranslation(Translation.common)[0];
  const [flags] = useContext(FlagsContext);

  const showAlumniActions = () => {
    return flags?.includes("ALUMNI_FAVORITES") ?? false;
  };

  const navigate = useNavigate();

  const {
    searchTerm,
    setSearchTerm,
    page,
    setPage,
    alumniFilters,
    setAlumniFilters,
    editAlumniFilters,
    setEditAlumniFilters,
    activeAlumni,
    setActiveAlumni,
    favoriteAlumni,
    setFavoriteAlumni,
    archivedAlumni,
    setArchivedAlumni,
    alumniDbTab,
    setAlumniDbTab,
    totalActiveAlumni,
    setTotalActiveAlumni,
    totalFavoriteAlumni,
    setTotalFavoriteAlumni,
    totalArchivedAlumni,
    setTotalArchivedAlumni,
    firstLoadTriggered,
    setFirstLoadTriggered,
    userConfig,
    selectedAlumni,
    setSelectedAlumni,
    singleEmailReceiver,
    setSingleEmailReceiver,
  } = useTableContext();

  const [archivePayload, setArchivePayload] = useState<ActionPayload | null>(
    null,
  );
  const [restorePayload, setRestorePayload] = useState<ActionPayload | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>(
    t(loadAlumniDbSortFromStorage()),
  );
  const [sendEmailModalOpen, setSendEmailModalOpen] =
    useState<EmailTemplateTypeEnum | null>(null);

  const prevValues = useRef({
    page,
    searchTerm,
    selectedOption,
    alumniFilters,
    userConfig,
  }).current;

  const handleTabChange = (_: SyntheticEvent, newValue: AlumniTab) => {
    setAlumniDbTab(newValue);
    setPage(0);
    setSelectedAlumni(new Map());
  };

  const [user] = useContext(UserContext);
  const orgId = user?.orgId ?? "";

  const [inputError, setInputError] = useState<InputError>({
    former_salary: false,
    tenure: false,
    time_since_departure: false,
  });
  const updateInputError = (arg: keyof InputError) => (value: boolean) => {
    setInputError((prev) => ({
      ...prev,
      [arg]: value,
    }));
  };
  const toastContainerIds = {
    info: "alumni-database-filter-info",
    success: "alumni-database-filter-success",
    error: "alumni-database-filter-error",
  };

  const [filterOpen, setFilterOpen] = useState(false);

  const closeFilters = () => {
    setFilterOpen(false);
    setEditAlumniFilters(alumniFilters);
  };

  const applyFilters = () => {
    if (Object.values(inputError).some((value) => value)) {
      toast.error(common("filter.notifications.inputError"), {
        containerId: toastContainerIds.error,
        toastId: "filter-input-error",
      });
    } else {
      trackGoogleAnalyticsEvent({
        event: AlumDBEvent.ALUM_DB_FILTER,
        name: "filter",
        org: user?.orgName,
        customParameters: {
          editAlumniFilters,
        },
      });
      setPage(0);
      setAlumniFilters(editAlumniFilters);
      setFilterOpen(false);
    }
  };

  const trackAlumniDbActionEvent = (
    event: AlumDBEvent,
    affectedAlumniIds: string,
  ) => {
    trackGoogleAnalyticsEvent({
      event: event,
      org: user?.orgName,
      customParameters: {
        employee_ids: affectedAlumniIds,
      },
    });
  };

  interface MenuOption {
    className: string;
    label: string;
    handler: () => void;
    hasDividerAbove: boolean;
    icon: JSX.Element;
    disabled: boolean;
  }

  const generateBulkMenuOptions = (
    selectedAlumni: Map<string, Receiver>,
    alumni: AlumDTO[],
  ): MenuOption[] => {
    const selectedAlumniDetails = alumni.filter((alum) =>
      selectedAlumni.has(alum.id),
    );

    const options = [
      {
        disabled: selectedAlumni.size === 0,
        className: "send-referral-request-option",
        label: t("menuOptions.sendReferralRequest.label"),
        handler: () =>
          openSendEmailModal(
            AlumDBEvent.ALUM_DB_REQUEST_REFERRAL,
            EmailTemplateTypeEnum.REFERRAL,
          ),
        hasDividerAbove: false,
        icon: <Box component="img" src={HeartHandIcon} />,
      },
      {
        disabled: selectedAlumni.size === 0,
        className: "send-message-option",
        label: t("menuOptions.sendMessage.label"),
        handler: () =>
          openSendEmailModal(
            AlumDBEvent.ALUM_DB_SEND_MESSAGE,
            EmailTemplateTypeEnum.SEND_MESSAGE,
          ),
        hasDividerAbove: false,
        icon: <Box component="img" src={MailIcon} />,
      },
    ];

    // only add favorites/archive options if feature flag is enabled
    if (showAlumniActions()) {
      const hasFavoriteAlumni = selectedAlumniDetails.some(
        (alum) => alum.state === EmployeeOrgState.FAVORITE,
      );
      const hasNonFavoriteAlumni = selectedAlumniDetails.some(
        (alum) => alum.state !== EmployeeOrgState.FAVORITE,
      );
      const hasUnarchivedAlumni = selectedAlumniDetails.some(
        (alum) => alum.state !== EmployeeOrgState.ARCHIVE,
      );

      if (alumniDbTab === AlumniTab.ARCHIVE) {
        const alumniToRestore = selectedAlumniDetails.filter(
          (alum) => alum.state === EmployeeOrgState.ARCHIVE,
        );
        return [
          {
            disabled: selectedAlumni.size === 0,
            className: "restore-option",
            label: t("menuOptions.restore.label"),
            handler: () =>
              setRestorePayload({
                alumniToAffect: alumniToRestore,
                isBulkAction: true,
              }),
            hasDividerAbove: false,
            icon: <Box component="img" src={RestoreIcon} />,
          },
        ];
      }

      const actionOptions = [];

      if (hasNonFavoriteAlumni) {
        const alumniToFavorite = selectedAlumniDetails.filter(
          (alum) => alum.state !== EmployeeOrgState.FAVORITE,
        );
        actionOptions.push({
          disabled: selectedAlumni.size === 0,
          className: "favorite-option",
          label: t("menuOptions.favorite.label"),
          handler: () => handleAlumniFavorite(alumniToFavorite, true),
          hasDividerAbove: true,
          icon: <Box component="img" src={StarIcon} />,
        });
      }

      if (hasFavoriteAlumni) {
        const alumniToUnfavorite = selectedAlumniDetails.filter(
          (alum) => alum.state === EmployeeOrgState.FAVORITE,
        );
        actionOptions.push({
          disabled: selectedAlumni.size === 0,
          className: "remove-favorite-option",
          label: t("menuOptions.removeFavorite.label"),
          handler: () => handleAlumniRemoveFavorite(alumniToUnfavorite, true),
          hasDividerAbove: !hasNonFavoriteAlumni,
          icon: <Box component="img" src={MinusStarIcon} />,
        });
      }

      if (hasUnarchivedAlumni) {
        const alumniToArchive = selectedAlumniDetails.filter(
          (alum) => alum.state !== EmployeeOrgState.ARCHIVE,
        );
        actionOptions.push({
          disabled: selectedAlumni.size === 0,
          className: "archive-option",
          label: t("menuOptions.archive.label"),
          handler: () =>
            setArchivePayload({
              alumniToAffect: alumniToArchive,
              isBulkAction: true,
            }),
          hasDividerAbove: true,
          icon: <Box component="img" src={ArchiveIcon} />,
        });
      }

      return [...options, ...actionOptions];
    }

    return options;
  };

  const sortOptions = [
    t("sortOption.departureDateNewest"),
    t("sortOption.departureDateOldest"),
    t("sortOption.formerSalaryHighest"),
    t("sortOption.formerSalaryLowest"),
    t("sortOption.nameA-Z"),
    t("sortOption.nameZ-A"),
  ];

  const handleSortChange = (option: string) => {
    setSelectedOption(option);
    setPage(0);
  };

  const fetchAlumni = useCallback(
    async (
      states: AlumniTab[] = [
        AlumniTab.ACTIVE,
        AlumniTab.FAVORITES,
        AlumniTab.ARCHIVE,
      ],
      setLoading = true,
    ) => {
      const getSortOptionsDesc = (selectedOption: string) => {
        setAlumniDbSortInStorage(selectedOption);
        switch (selectedOption) {
          case t("sortOption.departureDateNewest"):
            return {
              sortOption: AlumniDbSortEnum.DEPARTURE_DATE,
              sortDesc: true,
            };
          case t("sortOption.departureDateOldest"):
            return {
              sortOption: AlumniDbSortEnum.DEPARTURE_DATE,
              sortDesc: false,
            };
          case t("sortOption.formerSalaryHighest"):
            return {
              sortOption: AlumniDbSortEnum.FORMER_SALARY,
              sortDesc: true,
            };
          case t("sortOption.formerSalaryLowest"):
            return {
              sortOption: AlumniDbSortEnum.FORMER_SALARY,
              sortDesc: false,
            };
          case t("sortOption.nameA-Z"):
            return {
              sortOption: AlumniDbSortEnum.NAME,
              sortDesc: false,
            };
          case t("sortOption.nameZ-A"):
            return {
              sortOption: AlumniDbSortEnum.NAME,
              sortDesc: true,
            };
          default:
            return {
              sortOption: AlumniDbSortEnum.DEPARTURE_DATE,
              sortDesc: true,
            };
        }
      };

      if (setLoading) {
        setIsLoading(true);
      }
      const { sortDesc, sortOption } = getSortOptionsDesc(selectedOption);

      try {
        const fetchPromises = [];

        if (states.includes(AlumniTab.ACTIVE)) {
          fetchPromises.push(
            listAlumni(
              orgId,
              page,
              ALUMNI_DATABASE_PAGE_SIZE,
              sortOption,
              sortDesc,
              searchTerm,
              {
                ...alumniFilters,
                state: null,
              },
            ).then((response) => {
              setActiveAlumni(response.alumni);
              setTotalActiveAlumni(response.total);
            }),
          );
        }

        if (states.includes(AlumniTab.FAVORITES)) {
          fetchPromises.push(
            listAlumni(
              orgId,
              page,
              ALUMNI_DATABASE_PAGE_SIZE,
              sortOption,
              sortDesc,
              searchTerm,
              {
                ...alumniFilters,
                state: EmployeeOrgState.FAVORITE,
              },
            ).then((response) => {
              setFavoriteAlumni(response.alumni);
              setTotalFavoriteAlumni(response.total);
            }),
          );
        }

        if (states.includes(AlumniTab.ARCHIVE)) {
          fetchPromises.push(
            listAlumni(
              orgId,
              page,
              ALUMNI_DATABASE_PAGE_SIZE,
              sortOption,
              sortDesc,
              searchTerm,
              {
                ...alumniFilters,
                state: EmployeeOrgState.ARCHIVE,
              },
            ).then((response) => {
              setArchivedAlumni(response.alumni);
              setTotalArchivedAlumni(response.total);
            }),
          );
        }

        await Promise.all(fetchPromises);
        if (setLoading) {
          setIsLoading(false);
        }
      } catch {
        toast.error(
          <>
            <span>{t("error.loadingTable.message")}</span>
            <div
              onClick={() => navigate(0)}
              style={{ marginTop: "5px", cursor: "pointer" }}
            >
              {t("error.loadingTable.buttonText")}
            </div>
          </>,
          { containerId: toastContainerIds.error },
        );
      }
    },
    [
      alumniFilters,
      navigate,
      orgId,
      page,
      searchTerm,
      selectedOption,
      setActiveAlumni,
      setArchivedAlumni,
      setFavoriteAlumni,
      setTotalActiveAlumni,
      setTotalArchivedAlumni,
      setTotalFavoriteAlumni,
      t,
      toastContainerIds.error,
    ],
  );

  useEffect(() => {
    const hasUserConfigChanged = prevValues?.userConfig !== userConfig;
    const notFirstLoadAndNothingChanged =
      prevValues?.page === page &&
      prevValues?.searchTerm === searchTerm &&
      prevValues?.selectedOption === selectedOption &&
      prevValues?.alumniFilters === alumniFilters &&
      !hasUserConfigChanged &&
      firstLoadTriggered;
    if (notFirstLoadAndNothingChanged) return;

    if (!firstLoadTriggered || hasUserConfigChanged) {
      prevValues.userConfig = userConfig;

      setAlumniFilters(
        getAlumniDbDefaultFilters(userConfig?.org.alumEmploymentTypes),
      );
      setEditAlumniFilters(
        getAlumniDbDefaultFilters(userConfig?.org.alumEmploymentTypes),
      );
    }

    if (!firstLoadTriggered || !hasUserConfigChanged) {
      void fetchAlumni();

      prevValues.page = page;
      prevValues.searchTerm = searchTerm;
      prevValues.selectedOption = selectedOption;
      prevValues.alumniFilters = alumniFilters;
    }

    if (!firstLoadTriggered) {
      setFirstLoadTriggered(true);
    }
  }, [
    alumniFilters,
    fetchAlumni,
    firstLoadTriggered,
    page,
    prevValues,
    searchTerm,
    selectedOption,
    setAlumniFilters,
    setEditAlumniFilters,
    setFirstLoadTriggered,
    userConfig,
  ]);

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleSearch = (searchTermValue: string) => {
    trackGoogleAnalyticsEvent({
      event: AlumDBEvent.ALUM_DB_SEARCH_QUERY,
      org: user?.orgName,
      type: searchTermValue,
    });
    setPage(0);
    setSearchTerm(searchTermValue);
  };

  const employmentTypeFilterLabel = userConfig?.org.alumEmploymentTypes
    ? new Map(
        userConfig?.org.alumEmploymentTypes.map((key) => [
          key,
          getEmploymentTypeDisplay(key, common),
        ]),
      )
    : new Map();

  const wouldRehireFilterLabel = new Map([
    ["yes", common("filter.fields.labels.wouldRehire.yes")],
    ["no", common("filter.fields.labels.wouldRehire.no")],
    ["notApplicable", common("filter.fields.labels.wouldRehire.notApplicable")],
  ])

  const handleSendEmail = () => {
    setSendEmailModalOpen(null);
    toast.success(t("toast.emailSuccess"), {
      containerId: toastContainerIds.success,
    });
  };

  const openSendEmailModal = (
    event: GAEvent,
    emailType: EmailTemplateTypeEnum,
    alumni?: AlumDTO,
  ) => {
    trackGoogleAnalyticsEvent({
      event: event,
      org: user?.orgName,
      customParameters: {
        num_selected: selectedAlumni.size,
        percent_selected: selectedAlumni.size / getCurrentTotalRows(),
      },
    });
    setSendEmailModalOpen(emailType);
    if (alumni) {
      setSingleEmailReceiver({
        id: alumni.id,
        firstName: alumni.first_name,
        lastName: alumni.last_name,
        email: alumni.email,
      });
    }
  };

  const handleCloseSendEmailModal = () => {
    setSendEmailModalOpen(null);
    setSingleEmailReceiver(null);
  };

  const handleAlumniArchive = async (archivePayload: ActionPayload | null) => {
    if (archivePayload === null) return;

    const { alumniToAffect, isBulkAction } = archivePayload;
    try {
      const alumniIds = alumniToAffect.map((a) => a.id);
      await archive(orgId, alumniIds);

      const newSelectedAlumni = new Map(selectedAlumni);
      alumniIds.forEach((id) => newSelectedAlumni.delete(id));
      setSelectedAlumni(newSelectedAlumni);

      setTotalArchivedAlumni(totalArchivedAlumni + alumniToAffect.length);
      setTotalActiveAlumni(totalActiveAlumni - alumniToAffect.length);
      setArchivePayload(null);

      await fetchAlumni(
        [AlumniTab.ACTIVE, AlumniTab.ARCHIVE, AlumniTab.FAVORITES],
        false,
      );

      if (isBulkAction) {
        toast.info(
          t("menuOptions.archive.success", { count: alumniToAffect.length }),
          {
            containerId: toastContainerIds.info,
            toastId: "archive-success",
          },
        );
      }

      trackAlumniDbActionEvent(
        AlumDBEvent.ARCHIVE,
        alumniToAffect.map((a) => a.id).join(","),
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleAlumniFavorite = async (
    alumniToFavorite: AlumDTO[],
    isBulkAction = false,
  ) => {
    try {
      await favorite(
        orgId,
        alumniToFavorite.map((a) => a.id),
      );

      setActiveAlumni(
        activeAlumni.map((a) =>
          alumniToFavorite.some((selected) => selected.id === a.id)
            ? { ...a, state: EmployeeOrgState.FAVORITE }
            : a,
        ),
      );

      setTotalFavoriteAlumni(totalFavoriteAlumni + alumniToFavorite.length);

      await fetchAlumni([AlumniTab.FAVORITES], false);

      if (isBulkAction) {
        toast.success(
          t("toast.bulkFavoriteSuccess", {
            count: alumniToFavorite.length,
          }),
          {
            containerId: toastContainerIds.success,
          },
        );
      }

      trackAlumniDbActionEvent(
        AlumDBEvent.FAVORITE,
        alumniToFavorite.map((a) => a.id).join(","),
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleAlumniRestore = async (restorePayload: ActionPayload | null) => {
    if (restorePayload === null) return;
    const { alumniToAffect, isBulkAction } = restorePayload;
    try {
      const alumniIds = alumniToAffect.map((a) => a.id);

      await restore(orgId, alumniIds);
      const newSelectedAlumni = new Map(selectedAlumni);
      alumniIds.forEach((id) => newSelectedAlumni.delete(id));
      setSelectedAlumni(newSelectedAlumni);

      setTotalActiveAlumni(totalActiveAlumni + alumniToAffect.length);
      setTotalArchivedAlumni(totalArchivedAlumni - alumniToAffect.length);
      setRestorePayload(null);

      await fetchAlumni([AlumniTab.ACTIVE, AlumniTab.ARCHIVE], false);

      if (isBulkAction) {
        toast.success(
          t("menuOptions.restore.success", { count: alumniIds.length }),
          {
            containerId: toastContainerIds.success,
            toastId: "restore-success",
          },
        );
      }

      trackAlumniDbActionEvent(
        AlumDBEvent.ARCHIVE_REMOVE,
        alumniToAffect.map((a) => a.id).join(","),
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleAlumniRemoveFavorite = async (
    alumniToUnfavorite: AlumDTO[],
    isBulkAction = false,
  ) => {
    try {
      const alumniIds = alumniToUnfavorite
        .filter((alum) => alum.state === EmployeeOrgState.FAVORITE)
        .map((a) => a.id);

      await removeFavorite(
        orgId,
        alumniToUnfavorite.map((a) => a.id),
      );

      if (alumniDbTab === AlumniTab.FAVORITES) {
        const newSelectedAlumni = new Map(selectedAlumni);
        alumniIds.forEach((id) => newSelectedAlumni.delete(id));
        setSelectedAlumni(newSelectedAlumni);
      }

      setActiveAlumni(
        activeAlumni.map((a) =>
          alumniToUnfavorite.some((selected) => selected.id === a.id)
            ? { ...a, state: null }
            : a,
        ),
      );

      setTotalFavoriteAlumni(totalFavoriteAlumni - alumniToUnfavorite.length);

      await fetchAlumni([AlumniTab.FAVORITES], false);

      if (isBulkAction) {
        toast.info(
          t("toast.bulkRemoveFavoriteSuccess", {
            count: alumniToUnfavorite.length,
          }),
          {
            containerId: toastContainerIds.info,
          },
        );
      }

      trackAlumniDbActionEvent(
        AlumDBEvent.FAVORITE_REMOVE,
        alumniToUnfavorite.map((a) => a.id).join(","),
      );
    } catch (error) {
      console.error(error);
    }
  };

  const trackSortAnalytics = (option: string) => {
    trackGoogleAnalyticsEvent({
      event: AlumDBEvent.ALUM_DB_SORT,
      org: user?.orgName,
      type: option,
    });
  };

  const getCurrentTabAlumni = (): AlumDTO[] => {
    switch (alumniDbTab) {
      case AlumniTab.ACTIVE:
        return activeAlumni;
      case AlumniTab.FAVORITES:
        return favoriteAlumni;
      case AlumniTab.ARCHIVE:
        return archivedAlumni;
    }
  };

  const getCurrentTotalRows = (): number => {
    switch (alumniDbTab) {
      case AlumniTab.ACTIVE:
        return totalActiveAlumni;
      case AlumniTab.FAVORITES:
        return totalFavoriteAlumni;
      case AlumniTab.ARCHIVE:
        return totalArchivedAlumni;
    }
  };

  const tabStyles = {
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.grey[500],
    "&.Mui-selected": {
      color: theme.palette.grey[800],
    },
  };

  const badgeStyles = {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.grey[600],
    fontSize: "0.75rem",
    borderRadius: "1rem",
    padding: "0.25rem 0.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const tabs = [
    <Tab
      key="active"
      data-testid="active-tab"
      label={
        <Stack direction="row" spacing={1} alignItems="center">
          <span>{t("tabs.active.title")}</span>
          <Box sx={badgeStyles}>{totalActiveAlumni}</Box>
        </Stack>
      }
      value={AlumniTab.ACTIVE}
      disableRipple
      sx={tabStyles}
    />,
  ];

  if (showAlumniActions()) {
    tabs.push(
      <Tab
        key="favorites"
        data-testid="favorites-tab"
        label={
          <Stack direction="row" spacing={1} alignItems="center">
            <span>{t("tabs.favorites.title")}</span>
            <Box sx={badgeStyles}>{totalFavoriteAlumni}</Box>
          </Stack>
        }
        value={AlumniTab.FAVORITES}
        disableRipple
        sx={tabStyles}
      />,
    );

    tabs.push(
      <Tab
        key="archived"
        data-testid="archived-tab"
        label={
          <Stack direction="row" spacing={1} alignItems="center">
            <span>{t("tabs.archived.title")}</span>
            <Box sx={badgeStyles}>{totalArchivedAlumni}</Box>
          </Stack>
        }
        value={AlumniTab.ARCHIVE}
        disableRipple
        sx={tabStyles}
      />,
    );
  }

  return (
    <Stack padding="2rem">
      <ErrorToastContainer containerId={toastContainerIds.error} />
      <SuccessToastContainer containerId={toastContainerIds.success} />
      <InfoToastContainer containerId={toastContainerIds.info} />
      <Header title={t("title")} showBorder={false} />
      <Stack justifyContent="space-between" direction="row">
        <Stack
          direction="row"
          margin="2rem 0 1rem"
          padding="1rem 1rem 1rem 0rem"
          gap="0.75rem"
        >
          <SearchBar
            placeholder={t("searchPlaceholder")}
            onSearch={handleSearch}
            context={SearchbarContextType.ALUMNI_DATABASE}
            searchbarWidth="21.125rem"
            searchTerm={searchTerm}
            disabled={
              getNumFiltersApplied(
                alumniFilters,
                getAlumniDbDefaultFilters(userConfig?.org.alumEmploymentTypes),
              ) > 0
            }
          />
          <FilterButton
            isDisabled={isLoading || searchTerm.length > 0}
            onClick={() => setFilterOpen(true)}
            count={getNumFiltersApplied(
              alumniFilters,
              getAlumniDbDefaultFilters(userConfig?.org.alumEmploymentTypes),
            )}
          />
          <Sort
            options={sortOptions}
            selectedOption={selectedOption}
            dropdownWidth={"16.25rem"}
            onSortChange={handleSortChange}
            trackAnalyticsEvent={trackSortAnalytics}
          />
        </Stack>
        <Stack
          direction="row"
          margin="2rem 0 1rem"
          padding="1rem 1rem 1rem 0rem"
          gap="0.625rem"
        >
          {selectedAlumni.size > 0 && (
            <EllipsisText
              className="selected-count"
              fontSize="0.875rem"
              fontWeight={400}
              lineHeight="1.25rem"
              color={(theme) => theme.palette.grey[500]}
              style={{ alignSelf: "center" }}
            >
              {selectedAlumni.size}/{MAX_SELECTED_ALUMNI} {t("selected")}
            </EllipsisText>
          )}
          <Button
            buttonProps={{
              disableRipple: true,
              sx: {
                padding: "0.625rem 1rem",
                fontFamily: "inherit",
                fontSize: "0.875rem",
                fontWeight: 600,
                lineHeight: "1.25rem",
                cursor: "pointer",
                borderRadius: "0.5rem",
              },
              disabled:
                selectedAlumni.size === 0 || alumniDbTab === AlumniTab.ARCHIVE,
              onClick: () =>
                openSendEmailModal(
                  AlumDBEvent.ALUM_DB_SEND_ROLE,
                  EmailTemplateTypeEnum.SEND_ROLE,
                ),
            }}
          >
            {t("sendRole")}
          </Button>
          <Box
            id="bulk-options-menu"
            sx={{
              border: `1px solid ${theme.palette.grey[200]}`,
              borderRadius: "0.5rem",
            }}
          >
            <Menu
              items={generateBulkMenuOptions(
                selectedAlumni,
                getCurrentTabAlumni(),
              )}
            />
          </Box>
        </Stack>
      </Stack>
      <Box
        sx={{ borderBottom: 1, borderColor: "divider", marginBottom: "1rem" }}
      >
        <Tabs
          value={alumniDbTab}
          onChange={handleTabChange}
          aria-label="alumni database tabs"
          TabIndicatorProps={{
            style: {
              backgroundColor: theme.palette.primary.main,
            },
          }}
        >
          {tabs}
        </Tabs>
      </Box>
      {isLoading ? (
        <Loading />
      ) : favoriteAlumni.length === 0 && alumniDbTab === AlumniTab.FAVORITES ? (
        <Box marginTop="1.5rem" data-testid="empty-favorites">
          <EmptyList
            icon={ThinStarIcon}
            iconAlt="thin star icon"
            title={t("tabs.favorites.emptyHeader")}
            message={
              <Trans
                ns={Translation.alumniDatabase}
                i18nKey="tabs.favorites.emptySubtitle"
                components={{
                  icon: (
                    <img
                      src={DotsVerticalIcon}
                      alt={"dots vertical icon"}
                      style={{
                        width: "16px",
                        height: "16px",
                        display: "inline",
                        verticalAlign: "middle",
                      }}
                    />
                  ),
                }}
              />
            }
          />
        </Box>
      ) : archivedAlumni.length === 0 && alumniDbTab === AlumniTab.ARCHIVE ? (
        <Box marginTop="1.5rem" data-testid="empty-archived">
          <EmptyList
            icon={ThinArchiveIcon}
            iconAlt="thin archive icon"
            title={t("tabs.archived.emptyHeader")}
            message={
              <Trans
                ns={Translation.alumniDatabase}
                i18nKey="tabs.archived.emptySubtitle"
                components={{
                  icon: (
                    <img
                      src={DotsVerticalIcon}
                      alt={"dots vertical icon"}
                      style={{
                        width: "16px",
                        height: "16px",
                        display: "inline",
                        verticalAlign: "middle",
                      }}
                    />
                  ),
                }}
              />
            }
          />
        </Box>
      ) : (
        <AlumniDatabaseTable
          alumni={getCurrentTabAlumni()}
          page={page}
          pageSize={ALUMNI_DATABASE_PAGE_SIZE}
          totalRows={getCurrentTotalRows()}
          onPageChange={handlePageChange}
          selectedAlumni={selectedAlumni}
          setSelectedAlumni={setSelectedAlumni}
          onAlumniSendRole={(alumni) =>
            openSendEmailModal(
              AlumDBEvent.ALUM_DB_SEND_ROLE,
              EmailTemplateTypeEnum.SEND_ROLE,
              alumni,
            )
          }
          onAlumniReferralRequest={(alumni) =>
            openSendEmailModal(
              AlumDBEvent.ALUM_DB_REQUEST_REFERRAL,
              EmailTemplateTypeEnum.REFERRAL,
              alumni,
            )
          }
          onAlumniSendMessage={(alumni) =>
            openSendEmailModal(
              AlumDBEvent.ALUM_DB_SEND_MESSAGE,
              EmailTemplateTypeEnum.SEND_MESSAGE,
              alumni,
            )
          }
          onAlumniArchive={(alumni) =>
            setArchivePayload({
              alumniToAffect: [alumni],
              isBulkAction: false,
            })
          }
          onAlumniFavorite={(alumni) => void handleAlumniFavorite([alumni])}
          onAlumniRestore={(alumni) =>
            setRestorePayload({
              alumniToAffect: [alumni],
              isBulkAction: false,
            })
          }
          onAlumniRemoveFavorite={(alumni) =>
            void handleAlumniRemoveFavorite([alumni])
          }
        />
      )}
      <FilterPanel
        description={t("filter.subtitle")}
        isOpen={filterOpen}
        closePanel={closeFilters}
        submitEnabled={true}
        onSubmit={applyFilters}
        onClear={() =>
          setEditAlumniFilters(
            getAlumniDbDefaultFilters(userConfig?.org.alumEmploymentTypes),
          )
        }
      >
        <Stack direction="column" gap="1.5rem">
          <LastKnownLocation
            initialSelectedLocations={Array.from(
              editAlumniFilters.last_known_locations,
            ).map((location) => {
              return { label: location, value: location, selectable: true };
            })}
            selectedLocations={editAlumniFilters.last_known_locations}
            setLocations={(arg: Set<string>) =>
              setEditAlumniFilters({
                ...editAlumniFilters,
                last_known_locations: arg,
              })
            }
          />
          <FormerDepartment
            initialSelectedDepartments={Array.from(
              editAlumniFilters.former_departments,
            ).map((department) => {
              return {
                label: department,
                value: department,
                selectable: true,
              };
            })}
            selectedDepartments={editAlumniFilters.former_departments}
            setDepartments={(arg: Set<string>) =>
              setEditAlumniFilters({
                ...editAlumniFilters,
                former_departments: arg,
              })
            }
          />
          {editAlumniFilters.former_employment_types.size > 0 && (
            <FilterMultiSelectCheckbox
              label={common("filter.fields.labels.formerEmploymentType")}
              optionStatus={editAlumniFilters.former_employment_types}
              optionLabels={employmentTypeFilterLabel}
              setFilters={(employmentType: string, value: boolean) => {
                setEditAlumniFilters({
                  ...editAlumniFilters,
                  former_employment_types: new Map(
                    editAlumniFilters.former_employment_types,
                  ).set(employmentType, value),
                });
              }}
            />
          )}
          <FormerSalary
            minSalary={editAlumniFilters.former_salary.min}
            maxSalary={editAlumniFilters.former_salary.max}
            setMinSalary={(arg: string) =>
              setEditAlumniFilters({
                ...editAlumniFilters,
                former_salary: {
                  min: arg,
                  max: editAlumniFilters.former_salary.max,
                },
              })
            }
            setMaxSalary={(arg: string) =>
              setEditAlumniFilters({
                ...editAlumniFilters,
                former_salary: {
                  max: arg,
                  min: editAlumniFilters.former_salary.min,
                },
              })
            }
            setInputError={updateInputError("former_salary")}
          />
          <Tenure
            minTenure={editAlumniFilters.tenure.min}
            maxTenure={editAlumniFilters.tenure.max}
            setMinTenure={(arg: string) =>
              setEditAlumniFilters({
                ...editAlumniFilters,
                tenure: {
                  min: arg,
                  max: editAlumniFilters.tenure.max,
                },
              })
            }
            setMaxTenure={(arg: string) =>
              setEditAlumniFilters({
                ...editAlumniFilters,
                tenure: {
                  max: arg,
                  min: editAlumniFilters.tenure.min,
                },
              })
            }
            setInputError={updateInputError("tenure")}
          />
          <TimeSinceDeparture
            timeSince={editAlumniFilters.time_since_departure}
            setTimeSince={(arg: { years: number; months: number }) =>
              setEditAlumniFilters({
                ...editAlumniFilters,
                time_since_departure: arg,
              })
            }
            setInputError={updateInputError("time_since_departure")}
          />
          <FilterMultiSelectCheckbox
            label={common("filter.fields.labels.wouldRehire.label")}
            optionStatus={editAlumniFilters.would_rehire}
            optionLabels={wouldRehireFilterLabel}
            setFilters={(rehireKey: WouldRehireOptions, value: boolean) => {
              setEditAlumniFilters({
                ...editAlumniFilters,
                would_rehire: new Map(
                  editAlumniFilters.would_rehire,
                ).set(rehireKey, value),
              });
            }}
          />
        </Stack>
      </FilterPanel>
      <CenterModal open={sendEmailModalOpen != null}>
        <SendEmailModal
          onSubmit={handleSendEmail}
          onClose={handleCloseSendEmailModal}
          initialJob={null}
          emailType={sendEmailModalOpen!}
          receivers={
            singleEmailReceiver
              ? [singleEmailReceiver]
              : [...selectedAlumni.values()]
          }
          maxSelectedReceivers={MAX_SELECTED_ALUMNI}
          searchAllAlumni={true}
        />
      </CenterModal>
      <ConfirmActionModal
        open={archivePayload !== null}
        onClose={() => setArchivePayload(null)}
        primaryButtonHandler={() => void handleAlumniArchive(archivePayload)}
        secondaryButtonHandler={() => setArchivePayload(null)}
        primaryButtonBgColor={theme.palette.error.main}
        primaryButtonHoverBgColor={theme.palette.error.dark}
        heading={t("menuOptions.archive.confirmTitle")}
        subHeading={t("menuOptions.archive.confirmText")}
        primaryButtonText={t("menuOptions.archive.confirmArchive")}
        secondaryButtonText={t("menuOptions.archive.confirmCancel")}
      />
      <ConfirmActionModal
        open={restorePayload !== null}
        onClose={() => setRestorePayload(null)}
        primaryButtonHandler={() => void handleAlumniRestore(restorePayload)}
        secondaryButtonHandler={() => setRestorePayload(null)}
        primaryButtonBgColor={theme.palette.primary.main}
        primaryButtonHoverBgColor={theme.palette.primary.dark}
        heading={t("menuOptions.restore.confirmTitle")}
        subHeading={t("menuOptions.restore.confirmText")}
        primaryButtonText={t("menuOptions.restore.confirmRestore")}
        secondaryButtonText={t("menuOptions.restore.confirmCancel")}
      />
    </Stack>
  );
}

const getNumFiltersApplied = (
  filters: AlumniDbFilterOptions,
  defaultFilters: AlumniDbFilterOptions,
): number => {
  let count = 0;

  Object.keys(filters).forEach((key) => {
    switch (key as keyof AlumniDbFilterOptions) {
      case "last_known_locations":
        count += filters.last_known_locations.size;
        break;
      case "former_departments":
        count += filters.former_departments.size;
        break;
      case "former_employment_types":
        filters.former_employment_types.forEach((isChecked) => {
          if (isChecked) count++;
        });
        break;
      case "former_salary":
        if (
          filters.former_salary.min !== defaultFilters.former_salary.min ||
          filters.former_salary.max !== defaultFilters.former_salary.max
        ) {
          count++;
        }
        break;
      case "tenure":
        if (
          filters.tenure.min !== defaultFilters.tenure.min ||
          filters.tenure.max !== defaultFilters.tenure.max
        ) {
          count++;
        }
        break;
      case "time_since_departure":
        if (
          filters.time_since_departure.years !==
            defaultFilters.time_since_departure.years ||
          filters.time_since_departure.months !==
            defaultFilters.time_since_departure.months
        ) {
          count++;
        }
        break;
      case "would_rehire":
        filters.would_rehire.forEach((isChecked) => {
          if (isChecked) count++;
        });
        break;
    }
  });

  return count;
};
