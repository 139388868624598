import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { signInWithToken } from "../../api/auth";
import { useContext, useEffect, useState } from "react";
import Loading from "../../components/Loading";
import { loadOidcClientConfigFromStorage, removeOidcClientConfigFromStorage, setUserInStorage, trackGoogleAnalyticsEvent } from "../../lib/utils";
import { LoginEvent } from "../../lib/eventEnums";
import { OidcUserContext, UserContext } from "../../lib/context";
import { useTranslation } from "react-i18next";
import { Translation } from "../../lib/constants";
import { UserManager } from "oidc-client-ts";
import { MessageType } from "../../models/auth";

enum LoadingState {
  DEV,
  GETTING_TOKEN,
  CREATING_SESSION,
}

export default function OAuthCallback() {
  const { t } = useTranslation(Translation.login);
  const navigate = useNavigate();
  const [_, setUser] = useContext(UserContext);
  const [__, setOidcUser] = useContext(OidcUserContext);
  const [loading, setLoading] = useState(LoadingState.GETTING_TOKEN);

  useEffect(() => {
    if (loading === LoadingState.DEV) return;
    getToken();
  }, []);

  const getToken = async () => {
    try {
      const OAuthUserManager = new UserManager(loadOidcClientConfigFromStorage()!);
      setOidcUser(OAuthUserManager);

      const user = await OAuthUserManager.signinCallback();
      const idToken = user?.id_token;
      const iss = user?.profile.iss;

      if (!idToken || !iss) {
        throw new Error("No id token found");
      }

      signIn(idToken, iss);
    } catch (error) {
      removeOidcClientConfigFromStorage();
      console.error("Error retreiving user token", error);
      navigate("/login", {
        state: { type: MessageType.ERROR, message: "Failed to sign in with token" },
      });
    }
  };

  const signIn = async (idToken: string, iss: string) => {
    setLoading(LoadingState.CREATING_SESSION);
    try {
      const response = await signInWithToken(iss, idToken);
      if (response.user) {
        // users logging into Boomerang app should only have one org
        const org = response.user.orgs[0] ?? {};
        const user = {
          email: response.user.email,
          firstName: response.user.first_name,
          lastName: response.user.last_name,
          orgId: org.org_id,
          orgName: org.org_name,
        };
        setUser(user);
        setUserInStorage(user);
        trackGoogleAnalyticsEvent({
          event: LoginEvent.LOGIN,
          org: user.orgName,
          name: `${user.firstName}` + `${user.lastName}`,
        });
        navigate("/");
        return;
      } else {
        throw new Error("No user found in response");
      }
    } catch (error) {
      console.log("Error signing in with token", error);
      navigate("/login", {
        state: { type: MessageType.ERROR, message: "Failed to sign in with token" },
      });
    }
  };

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      height="100vh"
      width="100vw"
      padding="10rem"
    >
      {loading === LoadingState.DEV ? (
        <div
          style={{ padding: "1rem", border: "solid black 1px" }}
          onClick={getToken}
        >
          continue login
        </div>
      ) : (
        <Stack
          width="98vw"
          padding="1rem"
          gap="32px"
          alignItems="center"
          justifyContent="end"
        >
          <Loading
            text={
              loading === LoadingState.CREATING_SESSION
                ? t("sessionLogin")
                : t("tokenLogin")
            }
          />
        </Stack>
      )}
    </Stack>
  );
}
