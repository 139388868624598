import { useTranslation } from "react-i18next";
import { ChangeEvent, SyntheticEvent, useContext, useEffect, useMemo, useState } from "react";
import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import HelpCircleIcon from "/help-circle-secondary.svg";
import ArrowRight from "@mui/icons-material/ArrowForwardIosRounded";
import MuiButton from "@mui/material/Button";
import theme from "../../theme";
import Tooltip from "../../theme/Tooltip";
import Input from "../../theme/Input";
import { JobDashboardDTO } from "../../models/api/job";
import MultiSelectComboBox, { MultiSelectOption } from "../../components/MultiSelectComboBox";
import { getEmailTemplate } from "../../api/resource";
import { WarningBox } from "../WarningBox";
import { Translation } from "../../lib/constants";
import {
  AlumniEmailContent,
  MessageEmailContent,
  ReferralEmailContent,
  renderMessageEmail,
  renderReferralEmail,
  renderSendRoleEmail,
  sendMessageEmail,
  sendReferralEmail,
  sendRoleEmail
} from "../../api/email";
import parse from "html-react-parser";
import { UserContext } from "../../lib/context";
import {
  GAEvent,
  ReferralRequestEvent,
  SendJobMatchApprovalEvent,
  SendMessageEvent,
  SendRoleEvent
} from "../../lib/eventEnums";
import { trackGoogleAnalyticsEvent } from "../../lib/utils";
import Loading from "../../components/Loading";
import Switch from "../../theme/Switch";
import { JobMatchStatus } from "../../models/job";
import ErrorToastContainer from "../../components/Toastify/ErrorToastContainer";
import { toast } from "react-toastify";
import { EmailTemplateTypeEnum } from "../../models/api/resource";
import ModalTemplate from "./ModalTemplate";
import AddRole from "./AddRole";
import ConfirmActionModal from "../ConfirmActionModal";
import JobDetailsBox from "./JobDetailsBox";
import ApiSupportedMultiSelectComboBox from "../MultiSelectComboBox/ApiSupportedMultiSelectComboBox";
import { autocompleteAlumniDatabaseSearchByName } from "../../api/alum";
import EllipsisText from "../../theme/EllipsisText";

const MAX_NUM_OF_SUGGESTIONS = 10;

export interface Receiver {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  status?: JobMatchStatus;
}

interface CommonProps {
  onSubmit: () => void;
  onClose: () => void;
  receivers: Receiver[]; // see below
  initialJob: JobDashboardDTO | null; // if null, user is able to add roles, otherwise the initial job is fixed
  emailType: EmailTemplateTypeEnum;
  maxSelectedReceivers?: number;
}

// users are able to send the email to any alumni - can search from the entire alumni database
// receivers: initially selected options
interface SearchFromAllAlumniProps {
  searchAllAlumni: true;
  selectedIds?: null;
  isReceiversReadOnly?: false;
}

// users can only send the email to the alumni passed through from the receivers field
// receivers: all possible options to select from
interface SearchFromReceiversProps {
  searchAllAlumni: false;
  selectedIds: Set<string>;
  isReceiversReadOnly?: boolean;
}

type SendEmailModalProps = (
  | SearchFromAllAlumniProps
  | SearchFromReceiversProps
) &
  CommonProps;

export default function SendEmailModal({
  onSubmit,
  onClose,
  searchAllAlumni,
  receivers,
  initialJob,
  selectedIds,
  emailType,
  isReceiversReadOnly = false,
  maxSelectedReceivers,
}: SendEmailModalProps) {
  const { t } = useTranslation(Translation.sendEmailModal);
  const [tab, setTab] = useState(0);
  const [user] = useContext(UserContext);
  const orgId = user?.orgId ?? "";

  const [jobs, setJobs] = useState<JobDashboardDTO[]>(
    initialJob ? [initialJob] : [],
  );
  const [sendModalOpen, setSendModalOpen] = useState(false);
  const [addRoleModalOpen, setAddRoleModalOpen] = useState(false);
  const [roleError, setRoleError] = useState(false);
  const [subjectText, setSubjectText] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [headlineText, setHeadlineText] = useState("");
  const [bodyText, setBodyText] = useState("");
  const [bodyError, setBodyError] = useState("");
  const [emailPreview, setEmailPreview] = useState("");
  const [defaultSubjectText, setDefaultSubjectText] = useState("");
  const [defaultHeadlineText, setDefaultHeadlineText] = useState("");
  const [defaultBodyText, setDefaultBodyText] = useState("");
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [loadingDraft, setLoadingDraft] = useState(false);
  const [reqReferrals, setReqReferrals] = useState(true);
  const [includeSign, setIncludeSign] = useState(true);
  const [loadingSendRoleButton, setLoadingSendRoleButton] = useState(false);

  const [multiSelectOptions, setMultiSelectOptions] = useState<
    MultiSelectOption[]
  >(
    receivers.map((receiver: Receiver) => {
      const hasEmail = receiver.email.length > 0;
      const isHired = receiver.status === JobMatchStatus.HIRED;

      let label = `${receiver.firstName} ${receiver.lastName}`;
      if (!hasEmail) {
        label += ` ${t("noEmailAddress")}`;
      } else if (isHired) {
        label += ` ${t("hired")}`;
      }

      return {
        value: receiver.id,
        label: label,
        selectable: hasEmail && !isHired,
        dropdownLabelDetails:
          searchAllAlumni && hasEmail ? receiver.email : undefined,
      };
    }),
  );

  const { validReceivers, invalidReceivers } = receivers.reduce(
    (acc, receiver) => {
      if (searchAllAlumni || selectedIds.has(receiver.id)) {
        receiver.email.length > 0
          ? acc.validReceivers.push(receiver)
          : acc.invalidReceivers.push(receiver);
      }
      return acc;
    },
    { validReceivers: [] as Receiver[], invalidReceivers: [] as Receiver[] },
  );

  const validSelectedIds = useMemo(
    () => new Set(validReceivers.map((receiver) => receiver.id)),
    [validReceivers],
  );

  const [newSelectedIds, setNewSelectedIds] =
    useState<Set<string>>(validSelectedIds);

  const MAX_NUM_OF_ROLES = emailType === EmailTemplateTypeEnum.REFERRAL ? 3 : 1;

  const modalHeading = (() => {
    switch (emailType) {
      case EmailTemplateTypeEnum.SEND_ROLE:
        return t("heading.sendRole");
      case EmailTemplateTypeEnum.REFERRAL:
        return t("heading.referral");
      case EmailTemplateTypeEnum.SEND_MESSAGE:
        return t("heading.sendMessage");
      default:
        return t("heading.default");
    }
  })();
  const gaWindowEvent = getGAWindowEvent(emailType);
  const gaDraftEvent = getGADraftEvent(emailType);
  const gaPreviewEvent = getGAPreviewEvent(emailType);
  const gaSelectRoleEvent = getGASelectRoleEvent(emailType);

  const isSubmissionValid = (requireJobs: boolean) => {
    let isValid = true;
    if (
      newSelectedIds.size == 0 ||
      bodyError ||
      subjectError ||
      (requireJobs && jobs.length === 0)
    ) {
      toast.error(t("toast.emptyFields"), {
        containerId: "send-email-error",
        toastId: "empty-fields",
      });
      isValid = false;
    }
    if (requireJobs && jobs.length === 0) {
      setRoleError(true);
    }
    return isValid;
  };

  const submitSendRole = async () => {
    if (!isSubmissionValid(true)) {
      setSendModalOpen(false);
      return;
    }

    setLoadingSendRoleButton(true);
    const bodyTextArray = bodyText.split(/\r?\n/).filter((n) => n);

    const emailContent: AlumniEmailContent = {
      headline: headlineText,
      bodyParagraphs: bodyTextArray,
      subject: subjectText,
      requestReferrals: reqReferrals,
      includeSignature: includeSign,
    };

    await sendRoleEmail(
      orgId,
      emailContent,
      jobs[0].id,
      Array.from(newSelectedIds),
    );

    setLoadingSendRoleButton(false);
    setSendModalOpen(false);
    onSubmit();
  };

  const submitRequestReferral = async () => {
    if (!isSubmissionValid(true)) {
      setSendModalOpen(false);
      return;
    }

    setLoadingSendRoleButton(true);
    const emailContent: ReferralEmailContent = {
      subject: subjectText,
      headline: headlineText,
      body: bodyText,
      includeSignature: includeSign,
    };

    await sendReferralEmail(
      orgId,
      emailContent,
      jobs.map((job) => job.id),
      Array.from(newSelectedIds),
    );

    setLoadingSendRoleButton(false);
    setSendModalOpen(false);
    onSubmit();
  };

  const submitSendMessage = async () => {
    if (!isSubmissionValid(false)) {
      setSendModalOpen(false);
      return;
    }

    setLoadingSendRoleButton(true);
    const bodyTextArray = bodyText.split(/\r?\n/).filter((n) => n);
    const emailContent: MessageEmailContent = {
      subject: subjectText,
      headline: headlineText,
      bodyParagraphs: bodyTextArray,
      recruiterEmail: user?.email ?? null,
    };

    await sendMessageEmail(orgId, emailContent, Array.from(newSelectedIds));

    setLoadingSendRoleButton(false);
    setSendModalOpen(false);
    onSubmit();
  };

  const handleSubmit = async () => {
    try {
      switch (emailType) {
        case EmailTemplateTypeEnum.SEND_ROLE:
          await submitSendRole();
          break;
        case EmailTemplateTypeEnum.REFERRAL:
          await submitRequestReferral();
          break;
        case EmailTemplateTypeEnum.SEND_MESSAGE:
          await submitSendMessage();
          break;
      }
      trackGoogleAnalyticsEvent({
        event: gaWindowEvent,
        type: "send",
        org: user?.orgName,
        customParameters: {
          email_type: emailType,
        }
      });
    } catch (error) {
      console.error(error);
      setSendModalOpen(false);
      setLoadingSendRoleButton(false);
      toast.error(t("toast.sendError"), {
        containerId: "send-email-error",
        toastId: "send-error",
      });
    }
  };

  const handleSendRole = () => {
    if (defaultHeadlineText != headlineText) {
      trackGoogleAnalyticsEvent({
        event: gaDraftEvent,
        type: "edit_heading",
        org: user?.orgName,
      });
    }
    if (defaultSubjectText != subjectText) {
      trackGoogleAnalyticsEvent({
        event: gaDraftEvent,
        type: "edit_subject",
        org: user?.orgName,
      });
    }
    if (defaultBodyText != bodyText) {
      trackGoogleAnalyticsEvent({
        event: gaDraftEvent,
        type: "edit_body",
        org: user?.orgName,
      });
    }
    if (!reqReferrals) {
      trackGoogleAnalyticsEvent({
        event: gaDraftEvent,
        type: "toggle_RFR_off",
        org: user?.orgName,
      });
    }
    if (isSubmissionValid(emailType !== EmailTemplateTypeEnum.SEND_MESSAGE)) {
      setSendModalOpen(true);
    }
  };

  const handleSubjectTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 0) {
      setSubjectError("");
      setSubjectText(event.target.value);
    } else {
      setSubjectError(t("error.subject"));
      setSubjectText(event.target.value);
    }
  };

  const handleBodyTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 0) {
      setBodyError("");
      setBodyText(event.target.value);
    } else {
      setBodyError(t("error.body"));
      setBodyText(event.target.value);
    }
  };

  const handleTabChange = (_event: SyntheticEvent, newValue: number) => {
    if (newValue == 1) {
      trackGoogleAnalyticsEvent({
        event: gaPreviewEvent,
        type: "preview_click",
        org: user?.orgName,
      });
    }
    setTab(newValue);
  };

  const handleClose = () => {
    trackGoogleAnalyticsEvent({
      event: gaWindowEvent,
      type: "exit",
      org: user?.orgName,
    });
    onClose();
  };

  const deleteJob = (jobId: string) => {
    setJobs(jobs.filter((displayedJob) => displayedJob.id !== jobId));
    setReqReferrals(false);
  };

  useEffect(() => {
    const fetchSendRoleEmailTemplate = async () => {
      setLoadingDraft(true);
      try {
        const response = await getEmailTemplate(orgId, emailType);
        setDefaultSubjectText(response.subject);
        setDefaultHeadlineText(response.headline);
        setDefaultBodyText(response.body);
      } catch (error) {
        if (emailType === EmailTemplateTypeEnum.SEND_ROLE) {
          if (initialJob === null || jobs.length === 0) {
            setDefaultSubjectText(
              t("backupContent.sendRole.subjectForEmptyInitialJob"),
            );
            setDefaultHeadlineText(
              t("backupContent.sendRole.headlineForEmptyInitialJob"),
            );
          } else {
            setDefaultSubjectText(
              t("backupContent.sendRole.subject") + initialJob.name,
            );
            setDefaultHeadlineText(
              t("backupContent.sendRole.headline") + initialJob.name,
            );
          }
          setDefaultBodyText(t("backupContent.sendRole.body"));
        } else if (emailType === EmailTemplateTypeEnum.REFERRAL) {
          setDefaultSubjectText(t("backupContent.referral.subject"));
          setDefaultHeadlineText(t("backupContent.referral.headline"));
          setDefaultBodyText(t("backupContent.referral.body"));
        }
      } finally {
        setLoadingDraft(false);
      }
    };

    if (emailType != EmailTemplateTypeEnum.SEND_MESSAGE) {
      void fetchSendRoleEmailTemplate();
    }
  }, [emailType, initialJob, jobs.length, orgId, t]);

  useEffect(() => {
    const fetchEmailPreview = async () => {
      setLoadingPreview(true);
      try {
        if (emailType === EmailTemplateTypeEnum.SEND_ROLE) {
          const bodyTextArray = bodyText.split(/\r?\n/).filter((n) => n);

          const emailContent: AlumniEmailContent = {
            headline: headlineText,
            bodyParagraphs: bodyTextArray,
            subject: subjectText,
            requestReferrals: reqReferrals,
            includeSignature: includeSign,
          };

          const response = await renderSendRoleEmail(
            orgId,
            emailContent,
            jobs.length === 0 ? null : jobs[0].id,
          );
          setEmailPreview(response);
        } else if (emailType === EmailTemplateTypeEnum.REFERRAL) {
          const emailContent: ReferralEmailContent = {
            subject: subjectText,
            headline: headlineText,
            body: bodyText,
            includeSignature: includeSign,
          };

          const response = await renderReferralEmail(
            orgId,
            emailContent,
            jobs.map((job) => job.id),
          );
          setEmailPreview(response);
        } else if (emailType === EmailTemplateTypeEnum.SEND_MESSAGE) {
          const bodyTextArray = bodyText.split(/\r?\n/).filter((n) => n);
          const emailContent: MessageEmailContent = {
            subject: subjectText,
            headline: headlineText,
            bodyParagraphs: bodyTextArray,
            recruiterEmail: user?.email ?? null,
          };
          const response = await renderMessageEmail(orgId, emailContent);
          setEmailPreview(response);
        } else {
          console.log(
            "Email can only be of type Send Role or Referral, but is neither",
          );
        }
      } catch (error) {
        // TODO: BOO-1036
      } finally {
        setLoadingPreview(false);
      }
    };

    if (tab == 1) {
      void fetchEmailPreview();
    }
  }, [
    bodyText,
    emailType,
    headlineText,
    includeSign,
    jobs,
    orgId,
    reqReferrals,
    subjectText,
    tab,
    user?.email,
  ]);

  useEffect(() => {
    const trackViewRoleButtonClick = () => {
      trackGoogleAnalyticsEvent({
        event: SendRoleEvent.SEND_ROLE_PREVIEW,
        type: "view_role_click",
        org: user?.orgName,
      });
    };

    const viewRoleElement = document.getElementById("view-role-button");
    if (viewRoleElement) {
      viewRoleElement.addEventListener("click", trackViewRoleButtonClick);
      return () => {
        viewRoleElement.removeEventListener("click", trackViewRoleButtonClick);
      };
    }
  }, [emailPreview, user?.orgName]);

  useEffect(() => {
    if (newSelectedIds != validSelectedIds) {
      trackGoogleAnalyticsEvent({
        event: SendRoleEvent.SEND_ROLE_DRAFT,
        type: "edit_recipient",
        org: user?.orgName,
      });
    }
  }, [newSelectedIds, user?.orgName, validSelectedIds]);

  const onAddRole = (jobs: JobDashboardDTO[]) => {
    jobs.forEach((job) => {
      trackGoogleAnalyticsEvent({
        event: gaSelectRoleEvent,
        org: user?.orgName,
        customParameters: {
          role_id: job.id,
        },
      });
    });
    setJobs((prevJobs) => prevJobs.concat(jobs));
    setAddRoleModalOpen(false);
    setRoleError(false);
  };

  useEffect(() => {
    setHeadlineText(defaultHeadlineText);
    setBodyText(defaultBodyText);
    setSubjectText(defaultSubjectText);
  }, [defaultHeadlineText, defaultBodyText, defaultSubjectText]);

  const getAlumniOptions = async (searchTerm: string) => {
    try {
      const response = await autocompleteAlumniDatabaseSearchByName(
        orgId,
        searchTerm,
        MAX_NUM_OF_SUGGESTIONS,
      );

      return response.alumni.map((alumni) => {
        const hasEmail = alumni.email.length > 0;
        return {
          value: alumni.id,
          label: `${alumni.first_name} ${alumni.last_name} ${
            hasEmail ? "" : t("noEmailAddress")
          }`,
          dropdownLabelDetails: hasEmail ? alumni.email : undefined,
          selectable: hasEmail,
        } as MultiSelectOption;
      });
    } catch (error) {
      console.error("Error fetching autocomplete suggestions:", error);
      return [];
    }
  };

  return (
    <>
      <ErrorToastContainer
        containerId="send-email-error"
        style={{ width: "652px" }}
      />
      {addRoleModalOpen ? (
        <AddRole
          onClose={handleClose}
          onAddRole={onAddRole}
          backButtonOnClick={() => setAddRoleModalOpen(false)}
          maxRolesSelected={MAX_NUM_OF_ROLES - jobs.length}
          preSelectedJobIds={new Set(jobs.map((job) => job.id))}
        />
      ) : (
        <ModalTemplate
          heading={modalHeading}
          onClose={handleClose}
          primaryButtonOnClick={handleSendRole}
          primaryButtonText={t("send")}
        >
          <>
            <Stack
              direction="column"
              justifyContent="space-between"
              padding="0rem 1.5rem 0rem"
            >
              <Box
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.palette.grey[200]}`,
                })}
              >
                <Tabs
                  textColor="inherit"
                  value={tab}
                  onChange={handleTabChange}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: theme.palette.primary.main,
                    },
                  }}
                >
                  <Tab
                    id="send-email-draft"
                    disableRipple
                    sx={{
                      minWidth: "2.5rem",
                      maxWidth: "2.5rem",
                      fontSize: "0.9rem",
                      fontWeight: "500",
                    }}
                    label={t("draft")}
                  />
                  <Tab
                    id="send-email-preview"
                    disableRipple
                    sx={{
                      marginLeft: "1rem",
                      minWidth: "3.5rem",
                      maxWidth: "3.5rem",
                      fontSize: "0.9rem",
                      fontWeight: "500",
                    }}
                    label={t("preview")}
                  />
                </Tabs>
              </Box>
            </Stack>
            <Box
              sx={(theme) => ({
                borderBottom: `1px solid ${theme.palette.grey[200]}`,
                overflow: "auto",
              })}
            >
              {tab == 0 && (
                <Stack direction="column" padding="1.5rem 2rem 2rem 2rem">
                  {loadingDraft ? (
                    <Loading />
                  ) : (
                    <>
                      {invalidReceivers.length > 0 && (
                        <WarningBox
                          alumsWithoutEmail={invalidReceivers}
                          warningTextUnderline={t(
                            "missingEmailWarningUnderlined",
                          )}
                          warningTextRemaining={t("missingEmailWarning")}
                        />
                      )}
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        paddingBottom="0.375rem"
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          className="to-section"
                        >
                          <Typography
                            fontSize="0.875rem"
                            fontWeight={500}
                            color={theme.palette.grey[800]}
                            lineHeight="1.25rem"
                          >
                            {t("toTooltip.label")}
                          </Typography>
                          {!isReceiversReadOnly && (
                            <Typography
                              fontSize="0.875rem"
                              fontWeight={500}
                              color={theme.palette.grey[500]}
                              lineHeight="1.25rem"
                            >
                              *
                            </Typography>
                          )}
                          <Tooltip
                            arrow={false}
                            title={t("toTooltip.content")}
                            className="tooltip"
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  padding: "0.75rem",
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.5rem",
                                  borderRadius: "0.5rem",
                                  bgcolor: "white",
                                  color: "grey.500",
                                  fontSize: "0.75rem",
                                  fontWeight: "500",
                                  lineHeight: "1.125rem",
                                  boxShadow: "0px 0.5rem 0.5rem #00000034",
                                },
                              },
                            }}
                          >
                            <Box
                              component="img"
                              src={HelpCircleIcon}
                              paddingLeft="0.25rem"
                            />
                          </Tooltip>
                        </Stack>
                        {maxSelectedReceivers && (
                          <EllipsisText
                            className="selected-count"
                            fontSize="0.875rem"
                            fontWeight={400}
                            lineHeight="1.25rem"
                            color={(theme) => theme.palette.grey[500]}
                            style={{ alignSelf: "center" }}
                          >
                            {newSelectedIds.size}/{maxSelectedReceivers}{" "}
                            {t("selected")}
                          </EllipsisText>
                        )}
                      </Stack>
                      {searchAllAlumni ? (
                        <ApiSupportedMultiSelectComboBox
                          suggestions={multiSelectOptions}
                          setSuggestions={setMultiSelectOptions}
                          selectedValues={newSelectedIds}
                          setSelectedValues={setNewSelectedIds}
                          inputErrorMessage={t("error.recipients")}
                          getOptions={getAlumniOptions}
                          maxSelectedValues={maxSelectedReceivers}
                        />
                      ) : (
                        <MultiSelectComboBox
                          options={multiSelectOptions}
                          selectedValues={newSelectedIds}
                          setSelectedValues={setNewSelectedIds}
                          selectAllLabel={t("selectAll")}
                          inputErrorMessage={t("error.recipients")}
                          showChevron={!isReceiversReadOnly}
                          readOnly={isReceiversReadOnly}
                          matchFromStartFilter
                          maxSelectedValues={maxSelectedReceivers}
                        />
                      )}
                      {emailType != EmailTemplateTypeEnum.SEND_MESSAGE && (
                        <Stack
                          direction="row"
                          paddingTop="1.125rem"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          className="role-section"
                        >
                          <Box>
                            <Typography
                              display="inline"
                              fontSize="0.875rem"
                              fontWeight={500}
                              color={theme.palette.grey[800]}
                              lineHeight="1.25rem"
                            >
                              {t("role")}
                            </Typography>
                            {initialJob === null && (
                              <Typography
                                display="inline"
                                fontSize="0.875rem"
                                fontWeight={500}
                                color={theme.palette.grey[500]}
                                lineHeight="1.25rem"
                                paddingRight="0.25rem"
                              >
                                *
                              </Typography>
                            )}
                            {jobs.length === 0 && (
                              <>
                                <Typography
                                  fontSize="0.875rem"
                                  color={theme.palette.grey[500]}
                                  pt="0.375rem"
                                  lineHeight="1.25rem"
                                >
                                  {t("selectRole")}
                                </Typography>
                                {roleError && (
                                  <Typography
                                    fontSize="0.875rem"
                                    lineHeight="1.25rem"
                                    pt="0.375rem"
                                    color={theme.palette.error.dark}
                                  >
                                    {t("error.role")}
                                  </Typography>
                                )}
                              </>
                            )}
                          </Box>
                          {initialJob === null && (
                            <MuiButton
                              endIcon={<ArrowRight sx={{ width: "1rem" }} />}
                              onClick={() => {
                                setAddRoleModalOpen(true);
                                setSendModalOpen(false);
                              }}
                              disabled={jobs.length === MAX_NUM_OF_ROLES}
                              disableRipple
                              sx={{
                                borderRadius: "0.5rem",
                                fontSize: "0.875rem",
                                padding: 0,
                                color: theme.palette.primary.main,
                                "&:hover": {
                                  bgcolor: "transparent",
                                  color: theme.palette.custom.purpleHover,
                                },
                                "&:disabled": {
                                  color: theme.palette.grey[400],
                                },
                              }}
                            >
                              {t("addRole.label")}
                            </MuiButton>
                          )}
                        </Stack>
                      )}
                      <Stack spacing="0.5rem">
                        {jobs.map((job) => (
                          <Box key={job.id}>
                            <JobDetailsBox
                              job={job}
                              onDelete={
                                initialJob ? undefined : () => deleteJob(job.id)
                              }
                            />
                            {emailType === EmailTemplateTypeEnum.SEND_ROLE && (
                              <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                paddingTop="1.125rem"
                              >
                                <Switch
                                  checked={reqReferrals}
                                  switchsize="large"
                                  onChange={() => {
                                    setReqReferrals(!reqReferrals);
                                  }}
                                />
                                <Typography
                                  fontSize="0.875rem"
                                  fontWeight={600}
                                  lineHeight="1.25rem"
                                  paddingLeft="12px"
                                  paddingRight="6px"
                                >
                                  {t("referSwitchTooltip.label")}
                                </Typography>
                                <Tooltip
                                  arrow={false}
                                  title={t("referSwitchTooltip.content")}
                                  componentsProps={{
                                    tooltip: {
                                      sx: {
                                        padding: "0.75rem",
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "0.5rem",
                                        borderRadius: "0.5rem",
                                        bgcolor: "white",
                                        color: "grey.500",
                                        fontSize: "0.75rem",
                                        fontWeight: "500",
                                        lineHeight: "1.125rem",
                                        boxShadow:
                                          "0px 0.5rem 0.5rem #00000034",
                                      },
                                    },
                                  }}
                                >
                                  <Box component="img" src={HelpCircleIcon} />
                                </Tooltip>
                              </Stack>
                            )}
                          </Box>
                        ))}
                      </Stack>
                      <Input
                        id="subject-section"
                        label={t("subject")}
                        placeholder=""
                        required={true}
                        error={subjectError}
                        onChange={handleSubjectTextChange}
                        stackProps={{
                          paddingTop: "1.125rem",
                        }}
                        inputProps={{
                          name: "subject",
                          value: subjectText,
                          sx: {
                            borderRadius: "0.5rem",
                            "& .MuiOutlinedInput-input": {
                              fontSize: "0.875rem",
                              padding: "10px 14px",
                              lineHeight: "1.5rem",
                              fontWeight: "400",
                              color: "grey.800",
                            },
                            fieldset: {
                              borderColor:
                                theme.palette.grey[300] + " !important",
                            },
                            "&.Mui-focused": {
                              fieldset: {
                                borderColor:
                                  theme.palette.primary.dark + " !important",
                              },
                            },
                            "&.Mui-error": {
                              fieldset: {
                                borderColor:
                                  theme.palette.custom.errorBorder +
                                  " !important",
                              },
                            },
                          },
                        }}
                      />
                      <Input
                        id="headline-section"
                        label={t("headline")}
                        placeholder=""
                        error=""
                        onChange={(event) =>
                          setHeadlineText(event.target.value)
                        }
                        stackProps={{ paddingTop: "1.125rem" }}
                        inputProps={{
                          name: "headline",
                          value: headlineText,
                          sx: {
                            borderRadius: "0.5rem",
                            "& .MuiOutlinedInput-input": {
                              fontSize: "0.875rem",
                              padding: "10px 14px",
                              lineHeight: "1.5rem",
                              fontWeight: "400",
                              color: "grey.800",
                            },
                            fieldset: {
                              borderColor:
                                theme.palette.grey[300] + " !important",
                            },
                            "&.Mui-focused": {
                              fieldset: {
                                borderColor:
                                  theme.palette.primary.dark + " !important",
                              },
                            },
                            "&.Mui-error": {
                              fieldset: {
                                borderColor:
                                  theme.palette.custom.errorBorder +
                                  " !important",
                              },
                            },
                          },
                        }}
                      />
                      <Input
                        id="body-section"
                        label={t("body")}
                        required={true}
                        placeholder=""
                        onChange={handleBodyTextChange}
                        error={bodyError}
                        stackProps={{ paddingTop: "1.125rem" }}
                        inputProps={{
                          id: "body-section-text",
                          name: "body",
                          multiline: true,
                          value: bodyText,
                          rows: 10.75,
                          sx: {
                            borderRadius: "0.5rem",
                            "& .MuiOutlinedInput-input": {
                              fontSize: "0.875rem",
                              lineHeight: "1.5rem",
                              fontWeight: "400",
                              color: "grey.800",
                            },
                            fieldset: {
                              borderColor:
                                theme.palette.grey[300] + " !important",
                            },
                            "&.Mui-focused": {
                              fieldset: {
                                borderColor:
                                  theme.palette.primary.dark + " !important",
                              },
                            },
                            "&.Mui-error": {
                              fieldset: {
                                borderColor:
                                  theme.palette.custom.errorBorder +
                                  " !important",
                              },
                            },
                          },
                        }}
                      />
                      {emailType != EmailTemplateTypeEnum.SEND_MESSAGE && (
                        <Stack
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          paddingTop="1.125rem"
                        >
                          <Switch
                            className="include-sig-switch"
                            switchsize="large"
                            checked={includeSign}
                            onChange={() => {
                              setIncludeSign(!includeSign);
                            }}
                          />
                          <Typography
                            fontSize="0.875rem"
                            fontWeight={600}
                            lineHeight="1.25rem"
                            paddingLeft="12px"
                          >
                            {t("includeSignature")}
                          </Typography>
                        </Stack>
                      )}
                    </>
                  )}
                </Stack>
              )}
              {tab == 1 && (
                <Stack
                  id="email-preview"
                  sx={{
                    minHeight: "500px",
                    padding: "0px 2px",
                    "& .alumni-email-body": {
                      maxWidth: "100% !important",
                    },
                  }}
                >
                  {loadingPreview ? <Loading /> : parse(emailPreview)}
                </Stack>
              )}
            </Box>
          </>
        </ModalTemplate>
      )}
      <ConfirmActionModal
        open={sendModalOpen}
        primaryButtonHandler={() => void handleSubmit()}
        secondaryButtonHandler={() => setSendModalOpen(false)}
        primaryButtonBgColor={theme.palette.primary.main}
        primaryButtonHoverBgColor={theme.palette.custom.purpleHover}
        heading={t("readyToSend.header")}
        subHeading={t("readyToSend.warning")}
        primaryButtonText={t("readyToSend.button")}
        secondaryButtonText={t("cancel")}
        isLoading={loadingSendRoleButton}
      />
    </>
  );
}

const getGAWindowEvent = (emailType: EmailTemplateTypeEnum): GAEvent => {
  switch (emailType) {
    case EmailTemplateTypeEnum.SEND_ROLE:
      return SendRoleEvent.SEND_ROLE_WINDOW;
    case EmailTemplateTypeEnum.REFERRAL:
      return ReferralRequestEvent.REFERRAL_REQUEST_WINDOW;
    case EmailTemplateTypeEnum.SEND_MESSAGE:
      return SendMessageEvent.SEND_MESSAGE_WINDOW;
    case EmailTemplateTypeEnum.JOB_MATCH_APPROVAL:
      return SendJobMatchApprovalEvent.SEND_JOB_MATCH_APPROVAL_WINDOW;
  }
};

const getGADraftEvent = (emailType: EmailTemplateTypeEnum): GAEvent => {
  switch (emailType) {
    case EmailTemplateTypeEnum.SEND_ROLE:
      return SendRoleEvent.SEND_ROLE_DRAFT;
    case EmailTemplateTypeEnum.REFERRAL:
      return ReferralRequestEvent.REFERRAL_REQUEST_DRAFT;
    case EmailTemplateTypeEnum.SEND_MESSAGE:
      return SendMessageEvent.SEND_MESSAGE_DRAFT;
    case EmailTemplateTypeEnum.JOB_MATCH_APPROVAL:
      return SendJobMatchApprovalEvent.SEND_JOB_MATCH_APPROVAL_DRAFT;
    default:
      return SendRoleEvent.SEND_ROLE_DRAFT;
  }
};

const getGAPreviewEvent = (emailType: EmailTemplateTypeEnum): GAEvent => {
  switch (emailType) {
    case EmailTemplateTypeEnum.SEND_ROLE:
      return SendRoleEvent.SEND_ROLE_PREVIEW;
    case EmailTemplateTypeEnum.REFERRAL:
      return ReferralRequestEvent.REFERRAL_REQUEST_PREVIEW;
    case EmailTemplateTypeEnum.SEND_MESSAGE:
      return SendMessageEvent.SEND_MESSAGE_PREVIEW;
    case EmailTemplateTypeEnum.JOB_MATCH_APPROVAL:
      return SendJobMatchApprovalEvent.SEND_JOB_MATCH_APPROVAL_PREVIEW;
    default:
      return SendRoleEvent.SEND_ROLE_PREVIEW;
  }
};

const getGASelectRoleEvent = (emailType: EmailTemplateTypeEnum): GAEvent => {
  switch (emailType) {
    case EmailTemplateTypeEnum.SEND_ROLE:
      return SendRoleEvent.SEND_ROLE_SELECT_ROLE;
    case EmailTemplateTypeEnum.REFERRAL:
      return ReferralRequestEvent.REFERRAL_REQUEST_SELECT_ROLE;
    default:
      return SendRoleEvent.SEND_ROLE_SELECT_ROLE;
  }
};
