export type EmailTemplateResponse = {
  email_templates: EmailTemplateDTO[];
  total: number;
};

export type EmailTemplateDTO = {
  id: string;
  name: string;
  subject: string;
  body: string;
  headline: string;
  icon_logo_url: string;
  long_logo_url: string;
  type: EmailTemplateTypeEnum;
};

export enum EmailTemplateTypeEnum {
  SEND_ROLE = "SEND_ROLE",
  REFERRAL = "REFERRAL",
  SEND_MESSAGE = "SEND_MESSAGE",
  JOB_MATCH_APPROVAL = "JOB_MATCH_APPROVAL"
}
